import React from "react";
import { useDispatch } from "react-redux";

import {
  DISPLAY_NOTIFICATION,
  DISPLAY_ERROR,
} from "core/redux/reducer/main.reducer";
import { useMainState } from "hooks";
import { Notification } from "components";

const DisplayNotification: React.FC = () => {
  const dispatch = useDispatch();
  const { notification, error } = useMainState();

  if (notification) {
    return (
      <Notification
        type={notification.type || "primary"}
        onHide={() => {
          dispatch({ type: DISPLAY_NOTIFICATION, payload: undefined });
        }}
      >
        {notification.message}
      </Notification>
    );
  }

  if (error) {
    return (
      <Notification
        type="danger"
        onHide={() => {
          dispatch({ type: DISPLAY_ERROR, payload: undefined });
        }}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: error.message.message
              ? error.message.message.replace("Error: ", "")
              : error.message.replace("Error: ", ""),
          }}
        ></span>
      </Notification>
    );
  }

  return null;
};

export default DisplayNotification;
