import store from "core/redux";
import MasterService from "services/master.service";
import { RenewalFullData } from "utils/renewal/default.data";
import { cast } from "utils/poona/poona.utils";
import { Membership, InitPayment } from "services/poona/poona.interfaces";

class RenewalService {
  private master: MasterService;

  constructor() {
    this.master = new MasterService();
  }

  get defaultHeaders(): RequestInit {
    const { currentPersonId, data } = store.getState();
    return {
      headers: {
        apiSeasonId: data?.step1?.licenceType?.seasonId,
        currentPersonId,
        "Content-Type": "application/json",
      },
    } as RequestInit;
  }

  // https://poona-recette.ffbad.org/public/api/index.html#members-members-post
  sendAllRenewalData = (
    data: RenewalFullData,
    instanceId: string
  ): { promise: Promise<Membership>; cancel: () => void } => {
    const rawDataForPoona = cast(data);

    return this.master.post<Membership>(
      `poona/registration/submit/${instanceId}`,
      rawDataForPoona,
      this.defaultHeaders
    );
  };

  initPayment = (
    args: { instanceId: string; membershipId: string },
    body: { urlCallback: string }
  ): { promise: Promise<InitPayment>; cancel: () => void } => {
    const { instanceId, membershipId } = args;

    return this.master.post<InitPayment>(
      `poona/registration/init/${instanceId}/${membershipId}`,
      body,
      this.defaultHeaders
    );
  };
}

export default RenewalService;
