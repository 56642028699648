import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import { DISPLAY_ERROR, SET_CONSENTS } from "core/redux/reducer/main.reducer";
import PoonaService from "services/poona/poona.service";
import { useInstance } from "hooks";

const service = new PoonaService();

const useFetchConsents = (): void => {
  const dispatch = useDispatch();
  const instance = useInstance();

  const displayError = useCallback(() => {
    dispatch({
      type: DISPLAY_ERROR,
      payload: {
        message: "Impossible de récupérer vos consentements",
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (!instance) {
      return;
    }

    const { promise, cancel } = service.consents(instance.instanceId);

    promise
      .then((response) => {
        dispatch({ type: SET_CONSENTS, payload: response });
      })
      .catch(displayError);
    return () => cancel();
    // eslint-disable-next-line
  }, [instance, service, dispatch, displayError]);
};

export default useFetchConsents;
