import React from "react";

import "./index.scss";

type Props = {
  content?: string;
};

const FormError: React.FC<Props> = (props) => {
  const { content } = props;

  if (!content) {
    return null;
  }

  return <div className="form-error">{content}</div>;
};

export default FormError;
