import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { CheckFieldError } from "utils/renewal/renewal.utils";
import {
  Step02Data,
  ContactsData,
} from "pages/LicenceRenewal/renewal.interfaces";

import Footer from "../../Footer";
import Contacts from "./components/Contacts";
import { renewalStep02IsValid } from "utils/renewal/step02.utils";
import {
  DISPLAY_ERROR,
  SET_FORM_DATA_FOR_STEP,
} from "core/redux/reducer/main.reducer";
import useDataForStep from "hooks/useDataForStep";

type Props = {
  onNext: () => void;
  onPrevious: () => void;
};

const KEYS = {
  CONTACTS: "contacts",
};

const Step02: React.FC<Props> = (props) => {
  const [fieldErrors, setFieldErrors] = useState<CheckFieldError[][]>([]);
  const { onNext, onPrevious } = props;
  const dispatch = useDispatch();
  const defaultData = useDataForStep<Step02Data>(2);

  const handleOnChange = (key: string, data: any) => {
    const newFormData = { ...defaultData } as any;
    newFormData[key] = data;

    dispatch({
      type: SET_FORM_DATA_FOR_STEP,
      payload: { step: 2, data: newFormData },
    });
  };

  const handleOnNext = () => {
    setFieldErrors([]);
    const validationErrors = renewalStep02IsValid(defaultData);
    const countAllErrors = validationErrors.filter((p) => p && p.length > 0)
      .length;

    if (countAllErrors) {
      setFieldErrors(validationErrors);
      const [[firstValidationError]] = validationErrors.filter(
        (p) => p && p.length > 0
      );

      dispatch({
        type: DISPLAY_ERROR,
        payload: {
          message: `${firstValidationError.comment} (${firstValidationError.label})`,
        },
      });
      return;
    }

    onNext();
  };

  const handleOnPrevious = () => {
    onPrevious();
  };

  return (
    <>
      <Contacts
        defaultData={defaultData && defaultData.contacts}
        fieldErrors={fieldErrors}
        onChange={(data: ContactsData) => handleOnChange(KEYS.CONTACTS, data)}
      />
      <Footer onNext={handleOnNext} onPrevious={handleOnPrevious} />
    </>
  );
};

export default Step02;
