import React, { ReactNode } from "react";
import InputMask from "react-input-mask";
import { useDispatch } from "react-redux";

import { DISPLAY_ERROR } from "core/redux/reducer/main.reducer";
import { CheckFieldError } from "utils/renewal/renewal.utils";
import { randomId } from "utils/string/string.utils";
import { buildClassName } from "utils/dom/dom.utils";
import Label from "../Label";
import { FormError } from "..";

import "./index.scss";

type Props = {
  id?: string;
  label?: string;
  subLabel?: string;
  type?: "text" | "date" | "number" | "email" | "tel" | "file";
  defaultValue?: string;
  onChange: (value: any) => void;
  fullwidth?: boolean;
  error?: CheckFieldError;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  maxLength?: number;
  mask?: string;
  maskChar?: string;
  fileMimeTypeAllowed?: string[];
  fileSizeMaxAllowed?: number;
  extra?: ReactNode;
  lockPaste?: boolean;
};

const Input: React.FC<Props> = (props) => {
  const {
    id,
    label,
    subLabel,
    type = "text",
    defaultValue = "",
    onChange,
    fullwidth = false,
    error,
    placeholder,
    required = false,
    disabled = false,
    maxLength,
    className: otherClassname = "",
    mask = "",
    maskChar = "",
    fileMimeTypeAllowed = [],
    fileSizeMaxAllowed,
    extra,
    lockPaste = false,
  } = props;
  const dispatch = useDispatch();
  const labelId = randomId();
  const className = buildClassName(otherClassname, [
    [fullwidth, "input-fullwidth"],
    [(error && error.comment) || false, "input-error"],
  ]);
  const isFileInput = type === "file";
  const isFileInputWithDefaultValue = isFileInput && !!defaultValue;

  return (
    <span className="form-input">
      {label && (
        <Label id={labelId} subLabel={subLabel} required={required}>
          {label}

          {isFileInput && <span>&nbsp;(max. {fileSizeMaxAllowed}mo)</span>}
        </Label>
      )}

      {extra}

      <InputMask
        id={id}
        type={type}
        placeholder={placeholder}
        defaultValue={isFileInput ? undefined : defaultValue}
        onChange={(e) => {
          if (isFileInput) {
            const fileValidity = fileIsValid(
              e.currentTarget.files,
              fileMimeTypeAllowed,
              fileSizeMaxAllowed
            );

            if (fileValidity.error) {
              onChange(null);
              e.target.value = "";
              dispatch({
                type: DISPLAY_ERROR,
                payload: { message: fileValidity.error },
              });
              return;
            }

            onChange(e.currentTarget.files);
          } else {
            onChange(e.currentTarget.value);
          }
        }}
        className={className}
        aria-labelledby={labelId}
        aria-required={required}
        aria-disabled={disabled}
        required={required}
        disabled={disabled || isFileInputWithDefaultValue}
        mask={mask}
        maskChar={maskChar}
        maxLength={!mask && !maskChar ? undefined : maxLength}
        // Why I put "disabled" instead of "off" => https://stackoverflow.com/a/38961567
        autoComplete="disabled"
        onPaste={(e) => lockPaste && e.preventDefault()}
      />

      {isFileInputWithDefaultValue && <p>{defaultValue}</p>}
      {error && <FormError content={error.comment} />}
    </span>
  );
};

export default Input;

const fileIsValid = (
  files: FileList | null,
  fileMimeTypeAllowed: string[] = [],
  fileSizeMaxAllowed?: number
): { error?: string } => {
  if (!files || !files.length) {
    return {};
  }

  const file = files[0];
  const [extension] = file.name
    .toLowerCase()
    .split(".")
    .reverse();

  if (!fileMimeTypeAllowed.includes(file.type)) {
    return {
      error: `Le type de document '${extension}' n'est pas valide. Types de document valides : ${fileMimeTypeAllowed.join(
        ", "
      )}`,
    };
  }

  const currentSize = file.size / 1e6;
  if (fileSizeMaxAllowed && currentSize > fileSizeMaxAllowed) {
    return {
      error: `Le fichier est trop lourd (taille maximale : ${fileSizeMaxAllowed}mo)`,
    };
  }

  return {};
};
