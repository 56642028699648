import React from "react";

import { Pricing } from "services/poona/poona.interfaces";
import Reductions from "../Reductions";
import Options from "../Options";
import { Switch } from "components";
import Quota from "components/DisplayRenewalPricing/Quota";

type Props = {
  price: Pricing;
  onChange: () => void;
  onChooseDiscount: (selected: boolean, id: string) => void;
  onChooseOption: (selected: boolean, id: string) => void;
  disabled: boolean;
  selected?: boolean;
  selectedDiscountIds: string[];
  selectedOptionIds: string[];
};

const PriceItem: React.FC<Props> = (props) => {
  const {
    price,
    onChange,
    onChooseDiscount,
    onChooseOption,
    disabled,
    selected = false,
    selectedDiscountIds,
    selectedOptionIds,
  } = props;

  return (
    <div className="display-pricing--price-item">
      <div className="flex-container">
        <div>
          {price.name}

          <div className="display-pricing--price-item-description">
            {price.description && (
              <div
                dangerouslySetInnerHTML={{
                  __html: formatDescription(price.description),
                }}
              />
            )}

            <Quota stock={price.stock} quota={price.quota} />
          </div>
        </div>
        <div>
          <Switch
            key={selected.toString()}
            onChange={onChange}
            disabled={!price.isBuyable || (!selected && disabled)}
            on={price.isBuyable ? selected : false}
          />
        </div>
        <div>{price.price}&nbsp;&euro;</div>
      </div>

      {(price.options.length !== 0 || price.discounts.length !== 0) && (
        <div className="reductions-options-container" data-visible={selected}>
          <div className="content">
            <Reductions
              price={price}
              onChooseDiscount={onChooseDiscount}
              selectedIds={selectedDiscountIds}
            />
            <Options
              price={price}
              onChooseOption={onChooseOption}
              selectedIds={selectedOptionIds}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const formatDescription = (description: string): string => {
  return description
    .replace(/<\/p><p>/gi, "<br />")
    .replace(/<p>/gi, "")
    .replace(/<\/p>/gi, "");
};

export default PriceItem;
