export enum ConsentTypes {
  RGPD = 1,
  ASSURANCE = 2,
  ABONNEMENT = 3,
  DROIT_IMAGE_FFBAD = 4,
  DROIT_IMAGE_ASSOCIATION = 5,
  CLUB = 6,
  // 7 doesn't exist.
  PLAYER = 8,
}
