import { Step06Data } from "pages/LicenceRenewal/renewal.interfaces";
import { CheckFieldError, Reasons } from "./renewal.utils";

export const renewalStep06IsValid = (data?: Step06Data): CheckFieldError[] => {
  const errors: CheckFieldError[] = [];
  if (!data || !data.modalites || !data.payment_methods) {
    return [
      {
        key: "",
        reason: Reasons.NotProvided,
        comment: "Les données sont incomplètes",
      },
    ];
  }

  if (!checkBillingMode(data)) {
    errors.push({
      key: "payment_methods",
      reason: Reasons.NotProvided,
      comment: "Veuillez choisir une méthode de paiement",
    });
  }

  return errors;
};

const checkBillingMode = (data: Step06Data) => {
  const { payment_methods } = data;
  return (
    payment_methods &&
    payment_methods.paymentMethodId &&
    payment_methods.paymentMethodId > 0
  );
};
