import React from "react";

// Don't use NODE_ENV, because react-script override it to "production" for every build.
const NODE_ENV = process.env.REACT_APP_NODE_ENV;
const PRODUCTION_KEY = "prod";
const isProductionStage = () => NODE_ENV === PRODUCTION_KEY;

const Config: React.FC = () => {
  if (isProductionStage()) {
    return <code>{JSON.stringify({ NODE_ENV })}</code>;
  }

  return <code>{JSON.stringify(process.env)}</code>;
};

export default Config;
