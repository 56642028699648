import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { KeyCodes } from "core/enums/keycodes";
import { DISPLAY_ERROR } from "core/redux/reducer/main.reducer";
import { MAX_ALLOWED_FILE_SIZE } from "core/constants";
import { Button } from "components";

import "./index.scss";

type Props = {
  defaultImage?: string;
  onChange?: (file: any) => void;
  licence?: string;
};

const DEFAULT_AVATAR_URI = "/assets/images/blank_avatar.png";

const Avatar: React.FC<Props> = (props) => {
  const { defaultImage, onChange, licence } = props;
  const [currentImage, setCurrentImage] = useState<string>(DEFAULT_AVATAR_URI);
  const fileInputRef = useRef<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!defaultImage) {
      return;
    }

    setCurrentImage(defaultImage);
  }, [defaultImage]);

  const triggerOpenFileSystem = () => {
    if (!fileInputRef) {
      return;
    }

    fileInputRef.current.click();
  };

  const handleOnUploadFile = (e: any) => {
    const { files = [] } = e.target;

    if (!files.length) {
      setCurrentImage(defaultImage || DEFAULT_AVATAR_URI);
      return;
    }

    const [aFile] = files;
    const currentSize = aFile.size / 1e6;
    if (currentSize > MAX_ALLOWED_FILE_SIZE) {
      dispatch({
        type: DISPLAY_ERROR,
        payload: {
          message: `Le fichier est trop volumineux (${MAX_ALLOWED_FILE_SIZE}Mo maximum)`,
        },
      });
      return;
    }

    const url = URL.createObjectURL(aFile);

    setCurrentImage(url);

    if (onChange) {
      onChange(aFile);
    }
  };

  return (
    <div className="avatar-container">
      <div className="content">
        <div
          className="avatar-container--picture"
          onClick={triggerOpenFileSystem}
          title="Modifier l'avatar"
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.keyCode === KeyCodes.ENTER || e.keyCode === KeyCodes.SPACE) {
              e.preventDefault();
              triggerOpenFileSystem();
            }
          }}
          style={{ backgroundImage: `url("${currentImage}")` }}
        />

        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          onChange={handleOnUploadFile}
        />

        <span className="column-container">
          <span className="title">
            Modifier son avatar (facultatif, max. {MAX_ALLOWED_FILE_SIZE}Mo)
          </span>
          <Button onClick={triggerOpenFileSystem}>Modifier</Button>
        </span>

        {licence && (
          <span className="column-container">
            <span className="title">N° licence</span>
            <span className="licence">{licence}</span>
          </span>
        )}
      </div>
    </div>
  );
};

export default Avatar;
