import React from "react";
import { Modal } from "ffbad-components";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useToggle } from "hooks-me";

import { useInstance } from "hooks";

import "./index.scss";

const WelcomeModal: React.FC = () => {
  const instance = useInstance();
  const [visible, setVisible] = useToggle(true);
  const handleOnClose = () => {
    setVisible(false);
    localStorage.setItem("mustShowRenewalModal", "0");
  };

  const modalEvents: any = {
    onCancel: handleOnClose,
    onClose: handleOnClose,
    onSuccess: handleOnClose,
  };

  if (localStorage.getItem("mustShowRenewalModal") === "0") {
    return null;
  }

  return (
    <Modal
      title="Formulaire d'adhésion"
      titleIcon={faUser}
      visible={visible}
      {...modalEvents}
    >
      <span>Bonjour,</span>
      <p>
        Bienvenue sur le formulaire d'adhésion en ligne du&nbsp;
        {instance && (
          <span className="u-bold">
            {instance.name} ({instance.initials})
          </span>
        )}
        .
      </p>

      <p>
        Attention si vous êtes actuellement licencié(e) à la FFBaD, vous pouvez
        soit renouveler votre adhésion à {instance?.initials} soit effectuer le
        transfert de club vers {instance?.initials} à partir de votre{" "}
        <a href="https://myffbad.fr" target="_blank" rel="noopener noreferrer">
          Espace Licencié
        </a>
        .
      </p>

      <p>
        Si lors de la saison précédente, vous n'étiez ni adhérent à{" "}
        {instance?.initials} ni dans un autre club de la FFBaD alors continuez
        sur le formulaire suivant.
      </p>

      <p>
        Bien à vous,
        <br />
        La Fédération Française de Badminton.
      </p>
    </Modal>
  );
};

export default WelcomeModal;
