import React, { useCallback, Fragment } from "react";

import { StepComponent } from "pages/LicenceRenewal/renewal.interfaces";
import { CheckFieldError } from "utils/renewal/renewal.utils";

import { useConsent } from "hooks";
import { Card } from "components";
import { ConsentTypes } from "core/enums/consentTypes";
import ImposedChoice from "./components/ImposedChoice";
import Question from "./components/Question";
import SimpleText from "./components/SimpleText";

type Props = StepComponent & {
  defaultData?: { [x: number]: boolean };
  fieldErrors: CheckFieldError[];
  type: ConsentTypes;
};

const Acceptation: React.FC<Props> = (props) => {
  const { onChange, type, fieldErrors, defaultData = {} } = props;
  const consentsData = useConsent(type);

  const handleOnChange = useCallback(
    (index: number, checked: boolean) => {
      const newFormData: any = { ...defaultData, [index]: checked };
      onChange(newFormData);
    },
    [defaultData, onChange]
  );

  if (!consentsData || !consentsData.consents.length) {
    return null;
  }

  return (
    <Card title={consentsData.categoryName}>
      <div className="acceptation-container">
        {consentsData.consents.map((aConsent) => {
          const uniqueKey = aConsent.consentId;
          const checked = defaultData[aConsent.consentId];

          if (aConsent.imposedChoice) {
            return (
              <ImposedChoice
                key={uniqueKey}
                aConsent={aConsent}
                handleOnChange={handleOnChange}
                fieldErrors={fieldErrors}
                defaultChecked={checked}
              />
            );
          }

          if (aConsent.isQuestion) {
            return (
              <Question
                key={uniqueKey}
                aConsent={aConsent}
                handleOnChange={handleOnChange}
                fieldErrors={fieldErrors}
                defaultChecked={checked}
              />
            );
          }

          return (
            <Fragment key={uniqueKey}>
              <br />
              <SimpleText aConsent={aConsent} />
            </Fragment>
          );
        })}
      </div>
    </Card>
  );
};

export default Acceptation;
