import moment from "moment";

const YEARS_TO_BE_MAJOR = 18;

export const randomId = (placeholder: string = "xxxxx-xxxxx") => {
  return placeholder.replace(/[xy]/g, (c) => {
    // tslint:disable-next-line: no-bitwise
    const r = (Math.random() * 16) | 0;
    // tslint:disable-next-line: no-bitwise
    const v = c === "x" ? r : (r & 0x3) | 0x8;

    return v.toString(16);
  });
};

export const isMinor = (birthdate: string | undefined): boolean => {
  if (!birthdate || birthdate.length === 0) {
    return false;
  }
  const yearsBetweenDates = moment().diff(birthdate, "years");
  return yearsBetweenDates < YEARS_TO_BE_MAJOR;
};

export const isMajor = (birthdate: string | undefined): boolean =>
  !isMinor(birthdate);

export const stripHtml = (html: string | null): string => {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = html || "";
  return tmp.textContent || tmp.innerText || "";
};

export const cutString = (
  str: string,
  len: number,
  append: string = "..."
): string => (str.length > len ? str.substr(0, len) + append : str);
