import { Adherent } from "core/interfaces.config";
import {
  Step01Data,
  Step02Data,
  Step03Data,
  Step04Data,
  Step05Data,
  Step06Data,
} from "pages/LicenceRenewal/renewal.interfaces";
import { EmailTypes, PhoneTypes } from "utils/poona/poona.utils";

export type RenewalFullData = {
  step1: Step01Data;
  step2: Step02Data;
  step3: Step03Data;
  step4: Step04Data;
  step5: Step05Data;
  step6: Step06Data;
};

export const buildDefaultDataForAdherent = (adherent?: Adherent): any => {
  let defaultData = {
    step1: {
      licenceType: undefined as any,
      // licenceType: {
      //   id: PRESTATION_TYPES.NEW_LICENCE,
      //   label: "",
      //   description: "",
      //   seasonId: "",
      //   type: "prestation",
      //   isExternal: false,
      //   prestationId: "",
      // },
      informations: {
        nationalityId: 82,
        nativeCountryId: 83,
      },
      coords: {
        floor: undefined,
        building: undefined,
        address: undefined,
        locality: undefined,
        // Let following value to an empty string and not "undefined".
        postalCode: "",
        city: undefined,
        countryId: "83",
        fixPhone: "",
        mobilePhone: "",
        email: undefined,
      },
    },
    step2: {
      contacts: [
        {
          firstName: "",
          lastName: "",
          contactTypeId: "",
          email: "",
          isEmergency: true,
          isLegalGuardian: false,
          isAccompanying: false,
        },
      ],
    },
    step3: {
      certificate: {},
      qssport: {
        questionData: [],
      },
      assurance: {},
      player: {},
    },
    step4: {
      acceptation: {},
      authorisations: {},
      attestation: "",
      signature: "",
    },
    step5: {
      recap: {
        selectedDiscountIds: [],
        selectedOptionsIds: [],
        totalPrice: 0,
        files: [],
      },
    },
    step6: {
      payment_methods: { paymentMethodId: -1 },
      modalites: { modaliteId: -1 },
    },
  } as RenewalFullData;

  if (adherent) {
    const email = adherent.emails.find(
      (anEmail) => +anEmail.typeId === EmailTypes.Perso
    );
    const mobilePhone = adherent.phones.find(
      (aPhone) => +aPhone.typeId === PhoneTypes.PhoneMobile
    );
    const fixPhone = adherent.phones.find(
      (aPhone) => +aPhone.typeId === PhoneTypes.PhonePerso
    );

    defaultData = {
      ...defaultData,
      step1: {
        licenceType: {
          // id: PRESTATION_TYPES.NEW_LICENCE,
          // label: "",
          // description: "",
          // seasonId: "",
          // type: "prestation",
          // isExternal: false,
          // prestationId: "",
        } as any,
        informations: {
          personId: adherent.personId,
          licence: adherent.licence,
          gender: adherent.sex,
          lastName: adherent.lastName,
          firstName: adherent.firstName,
          nationalityId: Number(adherent.nationalityId),
          nativeCountryId: Number(adherent.nativeCountryId),
          birthDate: adherent.birthday,
          avatarUrl: adherent.photo,
          birthCityInsee: adherent.birthCityInsee,
        },
        coords: {
          postalCode: adherent?.address?.postalCode,
          address: adherent?.address?.address,
          floor: adherent?.address?.floor,
          building: adherent?.address?.building,
          locality: adherent?.address?.locality,
          city: adherent?.address.city,
          countryId: "83",
          mobilePhone: mobilePhone?.value,
          fixPhone: fixPhone?.value,
          email: email?.value,
          emailConfirmation: email?.value,
        },
      },
      step2: {
        contacts: [],
      },
      step4: { acceptation: {}, authorisations: {} },
    };

    if (adherent.sex === "2") {
      if (adherent.maidenName) {
        defaultData.step1.informations.maidenName = adherent.maidenName;
      } else {
        defaultData.step1.informations.maidenName = adherent.lastName;
      }
    }

    if (adherent.birthPlace) {
      defaultData.step1.informations.birthPlace = `${adherent.birthPlace}`;
      if (adherent.birthPlaceDepartment) {
        if (
          !adherent.birthPlace.includes(` (${adherent.birthPlaceDepartment})`)
        ) {
          defaultData.step1.informations.birthPlace += ` (${adherent.birthPlaceDepartment})`;
        }
        defaultData.step1.informations.birthPlaceDepartment =
          adherent.birthPlaceDepartment;
      }
    }

    defaultData.step4.acceptation[1] = adherent.isDataPublic === "1";
    defaultData.step4.acceptation[2] = adherent.isProspectingFFBaD === "1";
    defaultData.step4.acceptation[3] = adherent.isProspectingThirdPart === "1";
    defaultData.step4.acceptation[28] = adherent.isInfoFFBaD === "1";
  }

  return defaultData;
};
