import React, { useEffect, useState, useCallback, useMemo } from "react";

import PoonaService from "services/poona/poona.service";
import { Pricing } from "services/poona/poona.interfaces";
import { RecapProof } from "pages/LicenceRenewal/renewal.interfaces";
import { RenewalFullData } from "utils/renewal/default.data";
import { delayedCallback } from "utils/dom/dom.utils";
import { Card } from "components";
import PriceItem from "./components/PriceItem";
import DisplayProofs from "./components/DisplayProofs";

import "./index.scss";
import { useDispatch } from "react-redux";
import { DISPLAY_ERROR } from "core/redux/reducer/main.reducer";

type Props = {
  ownerId: string;
  fullRenewalData: RenewalFullData;
  onChange: (
    price: Pricing,
    selectedDiscounts: string[],
    selectedOptions: string[],
    files: RecapProof[]
  ) => void;
};

const DisplayRenewalPricing: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const {
    ownerId,
    onChange,
    fullRenewalData: { step1 },
  } = props;
  const poonaService = useMemo(() => new PoonaService(), []);
  const [prices, setPrices] = useState<Pricing[]>([]);
  const [selectedDiscountIds, setSelectedDiscountIds] = useState<string[]>([]);
  const [selectedOptionIds, setSelectedOptionIds] = useState<string[]>([]);
  const [files, setFiles] = useState<RecapProof[]>([]);
  const [selectedPriceId, setSelectedPriceId] = useState(-1);

  useEffect(() => {
    const { promise, cancel } = poonaService.prices(ownerId, {
      licence: step1.informations.licence,
      sex: step1.informations.gender as any,
      birthday: step1.informations.birthDate,
      customSeasonLicence: step1.licenceType,
    });
    promise
      .then((prices) => {
        setPrices(prices);

        if (prices.length === 1) {
          handleOnSelectPrice(prices[0]);
        }
      })
      .catch(() => {
        dispatch({
          type: DISPLAY_ERROR,
          payload: {
            message: "Impossible de récupérer tarifs pour ce club",
          },
        });
      });
    return () => cancel();

    // Mocked.
    // setPrices([
    //   {
    //     seasonId: "20",
    //     priceId: 2259,
    //     name: "Renouvellement Adultes",
    //     description: "<p>Renouvellement cotisation adulte 2022-2023</p>",
    //     price: "90.00",
    //     min: "0",
    //     max: "1",
    //     quota: 0,
    //     stock: -1,
    //     isBuyable: true,
    //     isProof: false,
    //     openingDate: "2022-06-01 00:00:00",
    //     closingDate: "2023-08-01 00:00:00",
    //     options: [],
    //     discounts: [],
    //     proofs: [],
    //   },
    // ]);
    // eslint-disable-next-line
  }, [ownerId, step1]);

  const handleOnSelectPrice = (price: Pricing) => {
    setSelectedPriceId(selectedPriceId === price.priceId ? -1 : price.priceId);
    setSelectedDiscountIds([]);
    setSelectedOptionIds([]);
    setFiles([]);
  };

  const refreshTotalPrice = useCallback(() => {
    const price = prices.find(
      ({ priceId }) => priceId === selectedPriceId
    ) as Pricing;

    // Avoid multiple call & data conflicts.
    delayedCallback(() =>
      onChange(price, selectedDiscountIds, selectedOptionIds, files)
    );
  }, [
    onChange,
    prices,
    selectedPriceId,
    selectedDiscountIds,
    selectedOptionIds,
    files,
  ]);

  const handleOnChooseDiscount = (selected: boolean, discountId: string) => {
    let temp = [...selectedDiscountIds];
    const isIncluded = temp.includes(discountId);

    if (selected && !isIncluded) {
      temp.push(discountId);
    } else if (!selected && isIncluded) {
      temp = temp.filter((discount) => discount !== discountId);
    }

    setSelectedDiscountIds(temp);
  };

  const handleOnChooseOption = (selected: boolean, option: string) => {
    let temp = [...selectedOptionIds];
    const isIncluded = temp.includes(option);

    if (selected && !isIncluded) {
      temp.push(option);
    } else if (!selected && isIncluded) {
      temp = temp.filter((discount) => discount !== option);
    }

    setSelectedOptionIds(temp);
  };

  useEffect(() => {
    refreshTotalPrice();
    // eslint-disable-next-line
  }, [selectedPriceId, selectedOptionIds, selectedDiscountIds, files]);

  return (
    <>
      <Card
        title="Récapitulatif"
        subtitle="Vérifiez le montant de votre adhésion"
      >
        {(!prices || prices.length === 0) && (
          <div>Il n'y a pas de tarif pour ce club.</div>
        )}

        {prices?.length !== 0 && (
          <div className="display-pricing">
            <div className="display-pricing__listing">
              {prices.map((price) => (
                <PriceItem
                  key={price.priceId}
                  price={price}
                  selectedDiscountIds={
                    selectedPriceId === price.priceId ? selectedDiscountIds : []
                  }
                  selectedOptionIds={
                    selectedPriceId === price.priceId ? selectedOptionIds : []
                  }
                  disabled={selectedPriceId > 0}
                  selected={selectedPriceId === price.priceId}
                  onChange={() => handleOnSelectPrice(price)}
                  onChooseDiscount={handleOnChooseDiscount}
                  onChooseOption={handleOnChooseOption}
                />
              ))}
            </div>
          </div>
        )}
      </Card>

      <DisplayProofs
        price={prices.find((price) => price.priceId === selectedPriceId)}
        selectedDiscountIds={selectedDiscountIds}
        selectedOptionIds={selectedOptionIds}
        onChange={setFiles}
      />
    </>
  );
};

export default DisplayRenewalPricing;
