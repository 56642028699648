import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";

import PoonaService from "services/poona/poona.service";
import { Pricing, PricingProof } from "services/poona/poona.interfaces";
import { DISPLAY_ERROR } from "core/redux/reducer/main.reducer";
import { RecapProof } from "pages/LicenceRenewal/renewal.interfaces";
import { extractProofs, hasAtLeastOneProof } from "utils/renewal/proofs.utils";
import { cutString } from "utils/string/string.utils";
import { Card } from "components";
import UploadProof from "../UploadProof";
import useRegistrationKey from "hooks/useRegistrationKey";
import { isFirefox } from "utils/dom/dom.utils";

type Props = {
  price?: Pricing;
  selectedDiscountIds: string[];
  selectedOptionIds: string[];
  onChange: (files: RecapProof[]) => void;
};

const MAX_FILENAME_LENGTH = 20;
const poonaService = new PoonaService();

const DisplayProofs: React.FC<Props> = (props) => {
  const {
    price,
    selectedDiscountIds = [],
    selectedOptionIds = [],
    onChange,
  } = props;
  const [proofs, setProofs] = useState<PricingProof[]>([]);
  const [files, setFiles] = useState<RecapProof[]>([]);
  const [openFileExplorerId, setOpenFileExplorerId] = useState<string>();
  const registrationKey = useRegistrationKey();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!price) {
      return;
    }

    const tempProofs = extractProofs(
      price,
      selectedDiscountIds,
      selectedOptionIds
    );

    setProofs(tempProofs);
  }, [price, selectedDiscountIds, selectedOptionIds]);

  useEffect(() => onChange(files), [onChange, files]);

  if (
    hasAtLeastOneProof(price, selectedDiscountIds, selectedOptionIds, proofs)
  ) {
    return null;
  }

  const handleOnChangeFile = (
    proofId: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const potentialFiles = e.currentTarget.files;
    let tempFiles = [...files];

    if (!potentialFiles || !potentialFiles.length) {
      tempFiles = tempFiles.filter((file) => file.proofId !== proofId);
      setFiles(tempFiles);
      setOpenFileExplorerId(undefined);
      return;
    }

    const relatedProof = proofs.find(
      (p) => p.id === openFileExplorerId
    ) as PricingProof;

    const isValid = fileIsValid(relatedProof, potentialFiles[0]);

    if (openFileExplorerId && isValid && isValid.error) {
      dispatch({ type: DISPLAY_ERROR, payload: { message: isValid.error } });
      setOpenFileExplorerId(undefined);
      return;
    }

    poonaService
      .uploadTempFile(registrationKey, `proof_${proofId}`, potentialFiles[0])
      .then(({ key }) => {
        tempFiles.push({
          proofId,
          file: key,
          filename: potentialFiles[0].name,
        });

        setFiles(tempFiles);
      })
      .catch(() => {
        alert("Impossible de télécharger votre fichier.");
      })
      .finally(() => {
        setOpenFileExplorerId(undefined);
      });
  };

  const handleOnRemoveFile = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    proofId: string
  ) => {
    e.preventDefault();
    e.stopPropagation();
    const tempFiles = [...files].filter((file) => file.proofId !== proofId);
    setFiles(tempFiles);
    setOpenFileExplorerId(undefined);
  };

  return (
    <Card
      title="Justificatif(s)"
      subtitle="Veuillez fournir le(s) justificatif(s) associé(s) à votre tarif"
    >
      <div className="display-proofs">
        {proofs.map((proof) => {
          const relatedFile = files.find((file) => file.proofId === proof.id);

          return (
            <div
              key={proof.name}
              className="display-proofs__item"
              data-has-file={relatedFile !== undefined}
              onClick={() => setOpenFileExplorerId(proof.id)}
              title={
                relatedFile !== undefined
                  ? "Cliquez pour ajouter un justificatif"
                  : undefined
              }
            >
              <h4 className="display-proofs--name">
                {proof.name} {proof.required && <small>(obligatoire)</small>}
              </h4>
              <span
                className="display-proofs--description"
                dangerouslySetInnerHTML={{
                  __html:
                    (proof.description || "") +
                      `<span>Poids maximum autorisé: ${proof.fileSizeMaxAllowed} Mo</span>` ||
                    "",
                }}
              />

              {!relatedFile && (
                <NoFile
                  open={proof.id === openFileExplorerId}
                  handleOnChangeFile={handleOnChangeFile}
                  proof={proof}
                />
              )}

              {relatedFile && relatedFile.file && (
                <HasFile
                  proof={relatedFile}
                  handleOnRemoveFile={(
                    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
                  ) => handleOnRemoveFile(e, proof.id)}
                />
              )}
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default DisplayProofs;

const NoFile = (props: any) => {
  const { handleOnChangeFile, proof, open = false } = props;

  return (
    <>
      <UploadProof
        handleOnChangeFile={(e) => handleOnChangeFile(proof.id, e)}
        open={open}
      />

      {!isFirefox() && (
        <div className="display-proofs--add-button">
          <FontAwesomeIcon icon={faPlus as any} />
        </div>
      )}
    </>
  );
};

const HasFile = (props: any) => {
  const { proof, handleOnRemoveFile } = props;

  return (
    <div className="display-proofs--uploaded-file">
      <span>{cutString(proof.filename, MAX_FILENAME_LENGTH)}</span>
      <span
        className="display-proofs--remove-button"
        onClick={handleOnRemoveFile}
        title="Cliquez pour supprimer le justificatif"
      >
        <FontAwesomeIcon icon={faTimes as any} />
      </span>
    </div>
  );
};

const fileIsValid = (proof: PricingProof, file: File): { error?: string } => {
  const { fileMimeTypeAllowed = [], fileSizeMaxAllowed } = proof;
  const [extension] = file.name
    .toLowerCase()
    .split(".")
    .reverse();

  if (!fileMimeTypeAllowed.includes(file.type)) {
    return {
      error: `Le type de document '${extension}' n'est pas valide. Types de document valides : ${fileMimeTypeAllowed.join(
        ", "
      )}`,
    };
  }

  const currentSize = file.size / 1e6;
  if (currentSize > fileSizeMaxAllowed) {
    return {
      error: `Le fichier est trop lourd (taille maximale : ${fileMimeTypeAllowed}mb)`,
    };
  }

  return {};
};
