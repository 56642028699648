import React, { useMemo } from "react";
import IntlTelInput from "react-intl-tel-input";
import { buildClassName } from "utils/dom/dom.utils";

import { CheckFieldError } from "utils/renewal/renewal.utils";
import { randomId } from "utils/string/string.utils";
import FormError from "../FormError";
import Label from "../Label";

const PREFERRED_COUNTRIES = [
  "fr",
  "gp",
  "gf",
  "mq",
  "yt",
  "nc",
  "pf",
  "re",
  "pm",
  "wf",
];

type Props = {
  label?: string;
  required?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  onChange: (value: any) => void;
  error?: CheckFieldError;
};

const PhoneInput: React.FC<Props> = (props) => {
  const { label, required, defaultValue, disabled, onChange, error } = props;
  const labelId = useMemo(() => randomId(), []);
  const className = buildClassName("intl-tel-input", [
    [(error && error.comment) || false, "input-error"],
  ]);

  return (
    <>
      <span className="form-input">
        {label && (
          <Label id={labelId} required={required}>
            {label}
          </Label>
        )}
        <IntlTelInput
          containerClassName={className}
          inputClassName="form-control"
          preferredCountries={PREFERRED_COUNTRIES}
          defaultValue={defaultValue}
          disabled={disabled}
          format
          formatOnInit
          onSelectFlag={(
            currentNumber,
            selectedCountryData,
            fullNumber,
            isValid
          ) => {
            onChange(fullNumber);
          }}
          onPhoneNumberChange={(
            isValid,
            newNumber,
            countryData,
            fullNumber
          ) => {
            onChange(fullNumber);
          }}
        />

        {error && <FormError content={error.comment} />}
      </span>
    </>
  );
};

export default PhoneInput;
