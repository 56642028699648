import { Step04Data } from "pages/LicenceRenewal/renewal.interfaces";
import { CheckFieldError, Reasons } from "./renewal.utils";
import { Consent } from "services/poona/poona.interfaces";
import { ConsentTypes } from "core/enums/consentTypes";

export const renewalStep04IsValid = (
  data?: Step04Data,
  shouldDisplayRepresentativeBlock: boolean = false,
  consents: Consent[] = [],
  consentTypes: ConsentTypes[] = []
): CheckFieldError[] => {
  const errors: CheckFieldError[] = consentsIsValid(
    data?.acceptation,
    consents,
    consentTypes
  );

  return errors;
};

const consentsIsValid = (
  data: any = {},
  consents: Consent[] = [],
  consentTypes: ConsentTypes[] = []
): CheckFieldError[] => {
  const errors: CheckFieldError[] = [];
  const categories = consents.filter((p) =>
    consentTypes.includes(p.categoryId)
  );

  for (const category of categories) {
    const requiredConsents = category.consents.filter(
      (consent) => consent.isRequired || consent.imposedChoice
    );

    for (const aConsent of requiredConsents) {
      const { imposedChoice } = aConsent;
      const isUndefined = data[aConsent.consentId] === undefined;
      const isChecked = data[aConsent.consentId] === true;

      if ((imposedChoice && !isChecked) || isUndefined) {
        errors.push({
          key: "acceptation",
          reason: Reasons.NotProvided,
          comment: aConsent.label
            ? `Le consentement "${aConsent.label}" est obligatoire`
            : `Veuillez vérifier les consentements dans "${category.categoryName}"`,
          other: aConsent.consentId,
        });
      }
    }
  }

  return errors;
};
