import React, { useMemo } from "react";

import {
  CustomField,
  CustomFieldAsArray,
} from "pages/LicenceRenewal/renewal.interfaces";
import CustomFields from "../../../Step01/components/CustomFields";
import { getCustomFieldsAsArray } from "utils/poona/poona.utils";

type Props = {
  priceId: number;
  onChange: (data: any) => void;
  rawCustomFields: CustomField;
};

const CustomFieldsPrice: React.FC<Props> = (props) => {
  const { priceId, onChange, rawCustomFields } = props;
  const customFields = useMemo<CustomFieldAsArray | undefined>(
    () => getCustomFieldsAsArray(rawCustomFields, priceId),
    [rawCustomFields, priceId]
  );

  if (!customFields || !customFields.fields.length) {
    return null;
  }

  return (
    <CustomFields
      defaultData={{}}
      customFields={customFields}
      onChange={onChange}
    />
  );
};

export default CustomFieldsPrice;
