import React from "react";

import { Pricing, PricingOption } from "services/poona/poona.interfaces";
import { Switch } from "components";
import Quota from "components/DisplayRenewalPricing/Quota";

type Props = {
  price: Pricing;
  selectedIds: string[];
  onChooseOption: (selected: boolean, optionId: string) => void;
};

const Options: React.FC<Props> = (props) => {
  const {
    price: { options = [] },
    selectedIds,
    onChooseOption,
  } = props;

  const handleOnSelect = (selected: boolean, optionId: string) => {
    onChooseOption(selected, optionId);
  };

  if (!options.length) {
    return null;
  }

  return (
    <div>
      <h4>Choix de vos options</h4>
      <h5>Pour certaines options, il vous faudra fournir un justificatif</h5>

      {options.map((option) => {
        const selected = selectedIds.includes(option.optionId);

        return (
          <div key={option.name} className="reductions-options-container--item">
            <div>
              •&nbsp;{option.name} {displayValue(option)}
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: option.description || "",
                }}
              />
              <Quota
                className="stock"
                stock={option.stock}
                quota={option.quota}
              />
            </div>
            <div>
              <Switch
                key={Math.random()}
                on={selected}
                onChange={(checked) => handleOnSelect(checked, option.optionId)}
                disabled={!option.isBuyable}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Options;

const displayValue = (option: PricingOption): string => {
  return `(+${Number(option.price)}€)`;
};
