import React, { ReactNode, useEffect } from "react";

import Checkbox from "../Checkbox";

import "./index.scss";

type Props = {
  checked?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
  children: string | ReactNode;
  color?: "default" | "danger";
  align?: "center" | "top";
};

const CheckboxLabelled: React.FC<Props> = (props) => {
  const {
    children,
    color = "default",
    align = "center",
    onChange,
    ...otherProps
  } = props;

  useEffect(() => {
    if (!onChange || props.checked === undefined || props.checked === null) {
      return;
    }

    onChange(props.checked);
    // eslint-disable-next-line
  }, [props.checked]);

  return (
    <div
      className="checkbox-label-container"
      data-color={color}
      data-align={align}
      onClick={() => {
        if (!onChange) {
          return;
        }

        onChange(!props.checked);
      }}
    >
      <Checkbox {...otherProps} />
      <label>{children}</label>
    </div>
  );
};

export default CheckboxLabelled;
