import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { RouteArgs } from "core/interfaces.config";
import routes from "./routes.config";

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        {routes.map((route: RouteArgs) => (
          <Route {...route} />
        ))}
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
