import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faCheckSquare } from "@fortawesome/free-regular-svg-icons";

import { KeyCodes } from "core/enums/keycodes";

import "./index.scss";

type Props = {
  checked?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
  ariaLabelledby?: string;
};

const Checkbox: React.FC<Props> = (props) => {
  const { checked = false, disabled = false, onChange, ariaLabelledby } = props;
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const icon: any = isChecked ? faCheckSquare : faSquare;

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleOnClick = () => {
    if (disabled) {
      return;
    }

    setIsChecked(!isChecked);

    if (onChange) {
      onChange(!isChecked);
    }
  };

  const handleOnKeyDown = (e: any) => {
    if (e.keyCode === KeyCodes.SPACE) {
      e.preventDefault();
      handleOnClick();
    }
  };

  return (
    <div
      role="checkbox"
      aria-checked={isChecked}
      aria-labelledby={ariaLabelledby}
      className={`checkbox-container${disabled ? " disabled" : ""}`}
    >
      <FontAwesomeIcon
        tabIndex={0}
        onClick={handleOnClick}
        onKeyDown={handleOnKeyDown}
        icon={icon}
      />
    </div>
  );
};

export default Checkbox;
