import { Pricing, PricingProof } from "services/poona/poona.interfaces";

export const extractProofs = (
  price: Pricing,
  selectedDiscountIds: string[],
  selectedOptionIds: string[]
): PricingProof[] => {
  const discountProofs = price.discounts
    .filter((discount) => selectedDiscountIds.includes(discount.discountId))
    .map((discount) => discount.proofs);

  const optionProofs = price.options
    .filter((option) => selectedOptionIds.includes(option.optionId))
    .map((option) => option.proofs);

  return [...discountProofs, ...optionProofs, price.proofs]
    .flat()
    .reduce(
      (prev, curr) =>
        prev.find((p) => p.id === curr.id) ? prev : [...prev, curr],
      [] as PricingProof[]
    );
};

export const hasAtLeastOneProof = (
  price: Pricing | undefined,
  selectedDiscountIds: string[],
  selectedOptionIds: string[],
  proofs: PricingProof[]
) => {
  return (
    !price ||
    !proofs.length ||
    (!selectedDiscountIds.length &&
      !selectedOptionIds.length &&
      !price.proofs.length)
  );
};
