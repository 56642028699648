import React from "react";
import { useInstance } from "hooks";
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.scss";

const PreFooter = () => {
  const instance = useInstance();

  if (!instance) return null;

  return (
    <section className="pre-footer">
      <div className="pre-footer-wrapper">
        <div className="pre-footer-item">
          <FontAwesomeIcon
            icon={faMapMarkerAlt as IconProp}
            className="icon"
            aria-label="Icône de localisation pour l'adresse"
          />
          <div className="pre-footer-content-wrapper">
            <div className="pre-footer-title">
              <p>Adresse</p>
            </div>
            <ul className="pre-footer-content">
              <li>{instance.address1}</li>
              <li>
                {instance.zipcode} {instance.town}
              </li>
            </ul>
          </div>
        </div>
        <div className="pre-footer-item">
          <FontAwesomeIcon
            icon={faPhone as IconProp}
            className="icon"
            aria-label="Icône de téléphone pour les numéros de téléphone"
          />
          <div className="pre-footer-content-wrapper">
            <div className="pre-footer-title">
              <p>Tel</p>
            </div>
            <ul className="pre-footer-content">
              <li>{instance.phone}</li>
              <li>{instance.cell}</li>
            </ul>
          </div>
        </div>
        <div className="pre-footer-item">
          <FontAwesomeIcon
            icon={faEnvelope as IconProp}
            className="icon"
            aria-label="Icône d'enveloppe pour l'email"
          />
          <div className="pre-footer-content-wrapper">
            <div className="pre-footer-title">
              <p>Email</p>
            </div>
            <ul className="pre-footer-content">
              <li>
                <a href={`mailto:${instance.mail}`}>{instance.mail}</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="pre-footer-item">
          <FontAwesomeIcon
            icon={faGlobe as IconProp}
            className="icon"
            aria-label="Icône de globe pour les liens de site web"
          />
          <div className="pre-footer-content-wrapper">
            <div className="pre-footer-title">
              <p>Site web</p>
            </div>
            <ul className="pre-footer-content">
              <li>
                <a
                  href={instance.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {instance.website}
                </a>
              </li>
              <li>
                <a
                  href={`https://myffbad.fr/club/${instance.instanceId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >{`https://myffbad.fr/club/${instance.instanceId}`}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PreFooter;
