import React from "react";

import { buildClassName } from "utils/dom/dom.utils";

import "./index.scss";

type Props = {
  children: any;
  active?: boolean;
  onClick?: () => void;
  canClick: boolean;
};

const Title: React.FC<Props> = (props) => {
  const { children, active = false, onClick, canClick } = props;
  const className = buildClassName("wizard-title", [[active, "active"]]);

  return (
    <div
      className={className}
      data-clickable={canClick}
      onClick={canClick ? onClick : () => {}}
    >
      {children}
    </div>
  );
};

export default Title;
