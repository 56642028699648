import React, { useState, useEffect } from "react";

import { InputRadio, Label } from "..";
import { CheckFieldError } from "utils/renewal/renewal.utils";
import FormError from "../FormError";
import { KeyValue } from "pages/LicenceRenewal/renewal.interfaces";

import "./index.scss";

type Props = {
  label?: string;
  subLabel?: string;
  items: KeyValue[];
  keyOfCheckedItem?: string | string[];
  onChange: (key: string[]) => void;
  error?: CheckFieldError;
  disabled?: boolean;
  isMulti?: boolean;
  hasHtml?: boolean;
  color?: "default" | "danger";
  required?: boolean;
};

const InputRadioGroup: React.FC<Props> = (props) => {
  const {
    items = [],
    keyOfCheckedItem,
    onChange,
    label,
    subLabel,
    error,
    disabled = false,
    isMulti = false,
    hasHtml = false,
    color = "default",
    required = false,
  } = props;
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  useEffect(() => {
    if (keyOfCheckedItem === undefined) {
      return;
    }

    if (typeof keyOfCheckedItem === "string") {
      setSelectedKeys([keyOfCheckedItem]);
    } else if (typeof keyOfCheckedItem === "boolean") {
      setSelectedKeys([(keyOfCheckedItem as boolean).toString()]);
    } else if (typeof keyOfCheckedItem === "number") {
      setSelectedKeys([(keyOfCheckedItem as number).toString()]);
    } else {
      setSelectedKeys([...keyOfCheckedItem]);
    }
  }, [keyOfCheckedItem]);

  const handleOnChange = (checked: boolean, key: string) => {
    let tempArray = [...selectedKeys];

    if (!isMulti) {
      setSelectedKeys([key]);
      onChange([key]);
      return;
    }

    if (!checked) {
      tempArray = tempArray.filter((aKey) => aKey !== key);
    } else {
      tempArray.push(key);
    }

    const toSave = Array.from(new Set(tempArray));
    setSelectedKeys(toSave);
    onChange(toSave);
  };

  return (
    <>
      {label && (
        <Label hasHtml={hasHtml} subLabel={subLabel} required={required}>
          {label}
        </Label>
      )}

      <div className="input-radio-group-container" data-color={color}>
        {items.map((item) => (
          <InputRadio
            key={item.key}
            label={item.value}
            checked={selectedKeys.includes(item.key)}
            onChange={(checked) => handleOnChange(checked, item.key)}
            hasError={error && error.comment !== undefined}
            disabled={disabled}
            isMulti={isMulti}
          />
        ))}
      </div>

      {error && <FormError content={error.comment} />}
    </>
  );
};

export default InputRadioGroup;
