import { DISPLAY_ERROR } from "core/redux/reducer/main.reducer";
import { useInstance } from "hooks";
import { CustomField } from "pages/LicenceRenewal/renewal.interfaces";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import PoonaService from "services/poona/poona.service";

const useCustomFieldsPrice = () => {
  const instance = useInstance();
  const dispatch = useDispatch();
  const service = useMemo(() => new PoonaService(), []);
  const [rawCustomFields, setRawCustomFields] = useState<CustomField>();

  useEffect(() => {
    if (!instance) {
      return;
    }

    const { promise, cancel } = service.customFieldsForPrices(
      instance?.instanceId
    );
    promise.then(setRawCustomFields).catch(() => {
      dispatch({
        type: DISPLAY_ERROR,
        payload: {
          message: "Impossible de récupérer le formulaire pour les prix",
        },
      });
    });
    return () => cancel();

    // Mocked.
    // setRawCustomFields({
    //   title: "Formulaire d'adhérent pour Madrie Badminton",
    //   fields: [
    //     {
    //       key: "Obligatoire_parles_parles",
    //       label: "Obligatoire parles parles",
    //       type: "text",
    //       description: "Tu ne dois pas passer si y a rien",
    //       required: true,
    //       visibility: { price: [2259, 2902, 2903, 2904, 2905, 2906, 2910] },
    //     },
    //     {
    //       key: "Est_ce_que_tout_va_bien_",
    //       label: "Est ce que tout va bien ?",
    //       type: "boolean",
    //       description:
    //         "La question de la mort qui tue <a href='https://google.com' target='blank'>ICI</a>",
    //       required: true,
    //       selectItems: [
    //         { key: 1, value: "Oui" },
    //         { key: 0, value: "Non" },
    //       ],
    //       visibility: { price: [2259, 2902, 2903, 2904, 2905, 2906, 2910] },
    //     },
    //     {
    //       key: "fake",
    //       label: "fake",
    //       type: "boolean",
    //       description: "fake",
    //       required: true,
    //       selectItems: [
    //         { key: 1, value: "Oui" },
    //         { key: 0, value: "Non" },
    //       ],
    //       visibility: { price: [545456464] },
    //     },
    //   ],
    // } as any);
  }, [service, instance, dispatch]);

  return rawCustomFields;
};

export default useCustomFieldsPrice;
