import { CustomSeasonLicence, Pricing } from "services/poona/poona.interfaces";

/*
 * Step 01.
 */
export type InformationData = {
  personId?: string;
  licence?: string;
  gender?: string;
  lastName?: string;
  firstName?: string;
  maidenName?: string;
  nationalityId?: number;
  nativeCountryId?: number;
  birthDate?: string;
  birthPlace?: string;
  birthPlaceDepartment?: string;
  avatarUrl?: string;
  newAvatarFile?: {
    file?: File;
    poonaFilename: string;
  };
  birthCityInsee?: string | null;
};

export type CoordsData = {
  // Full address.
  address?: string;
  floor?: string;
  building?: string;
  locality?: string;

  // old fields.
  postalCode?: string;
  city?: string;
  countryId?: string;
  fixPhone?: string;
  mobilePhone?: string;
  email?: string;
  emailConfirmation?: string;
};

export type Step01Data = {
  informations: InformationData;
  coords: CoordsData;
  licenceType: CustomSeasonLicence;
  customFields?: Record<string, any>;
};

/*
 * Step 02.
 */
export type ContactsData = {
  contactTypeId?: string;
  contactAssoId?: string;
  localUniqueKey?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  isEmergency?: boolean;
  isLegalGuardian?: boolean;
  isAccompanying?: boolean;
};

export type Step02Data = {
  contacts: ContactsData[];
};

/*
 * Step 03.
 */
export type CertificateData = {
  newCertificateFile?: string;
  certificateDate?: string;
};

export type QSSportData = {
  questionData?: { key: string; value: QUESTION_STATES }[];
};

export type Step03Data = {
  assurance: any;
  player: any;
  certificate: CertificateData;
  qssport: QSSportData;
};

/*
 * Step 04.
 */
export type Step04Data = {
  acceptation: any;
  authorisations: any;
};

/*
 * Step 05.
 */
export type Step05Data = {
  recap: Recap;
  customFields?: Record<string, any>;
};

export type Recap = {
  price?: Pricing;
  selectedDiscountIds: string[];
  selectedOptionsIds: string[];
  totalPrice: number;
  files: RecapProof[];
};

export type RecapProof = {
  proofId?: string;
  file?: string;
  filename?: string;
};

/*
 * Step 06.
 */
export type Step06Data = {
  payment_methods: PaymentMethod;
  modalites: { modaliteId?: number };
};

export type PaymentMethod = {
  paymentMethodId: number;
};

/*
 * Others.
 */
export type KeyValue = {
  key: string;
  value: any;
};

export type StepComponent = {
  onChange: (data: any) => void;
};

export enum QUESTION_STATES {
  UNDEFINED,
  YES,
  NO,
}

export enum PRESTATION_TYPES {
  NEW_LICENCE = "NEW_LICENCE",
  ALREADY_LICENCE = "ALREADY_LICENCE",
  NO_LICENCE = "NO_LICENCE",
  PRE_RENTREE = "PRE_RENTREE",
  GLOBALE = "GLOBALE",
  ESTIVALE = "ESTIVALE",

  // licence type ID
  PLAYER = "JOUEUR",
  NON_PLAYER = "NON_JOUEUR",
}

export type CustomFieldAsArray = {
  title: string;
  fields: CustomFieldItem[];
};

export type CustomField = {
  title: string;
  fields: {
    [key: string]: CustomFieldPriceItem;
  };
};

export type CustomFieldItem = {
  key: string;
  label: string;
  type: CustomFieldTypes;
  description: string;
  required: boolean;
  selectItems?: {
    key: string | number;
    value: string;
  }[];
  fileMimeTypeAllowed?: string[];
  fileSizeMaxAllowed?: number;
  visibility?: {
    price: number[];
  };
};

export type CustomFieldPriceItem = CustomFieldItem & {
  visibility: {
    price: number[];
  };
};

export type CustomFieldTypes =
  | "boolean"
  | "checkbox"
  | "date"
  | "multiSelect"
  | "text"
  | "select"
  | "number"
  | "radio"
  | "file";
