import React from "react";

import { ConsentData } from "services/poona/poona.interfaces";
import { CheckboxLabelled } from "components/form";
import DisplayFile from "../DisplayFile";
import { CheckFieldError } from "utils/renewal/renewal.utils";

type Props = {
  aConsent: ConsentData;
  handleOnChange: (consentId: number, checked: boolean) => void;
  fieldErrors: CheckFieldError[];
  defaultChecked?: boolean;
};

const ImposedChoice: React.FC<Props> = (props) => {
  const { aConsent, handleOnChange, fieldErrors, defaultChecked } = props;
  const error = fieldErrors.find(
    (err) => err.key === "acceptation" && err.other === aConsent.consentId
  );
  let isChecked: boolean = aConsent.isDefaultChecked || false;

  if (defaultChecked !== undefined && defaultChecked !== null) {
    isChecked = defaultChecked;
  }

  return (
    <>
      {(aConsent.label || aConsent.content) && (
        <CheckboxLabelled
          key={aConsent.consentId}
          onChange={(checked) => handleOnChange(aConsent.consentId, checked)}
          checked={isChecked}
          color={error ? "danger" : "default"}
          align="top"
        >
          <span
            dangerouslySetInnerHTML={{
              __html: extractText(aConsent),
            }}
          />
        </CheckboxLabelled>
      )}

      {aConsent.url && (
        <>
          <DisplayFile url={aConsent.url} />
          <br />
        </>
      )}
    </>
  );
};

export default ImposedChoice;

const extractText = (aConsent: ConsentData) => {
  if (aConsent.label && aConsent.content) {
    return `${aConsent.label}<br />${aConsent.content}`;
  }

  if (aConsent.label) {
    return aConsent.label;
  }

  if (aConsent.content) {
    return aConsent.content;
  }

  return "";
};
