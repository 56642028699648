import React from "react";

import "./index.scss";

type Props = {
  children: any;
  id?: string;
  hasHtml?: boolean;
  subLabel?: string;
  required?: boolean;
};

const Label: React.FC<Props> = (props) => {
  const { children, id, hasHtml = false, subLabel, required = false } = props;

  if (hasHtml) {
    return (
      <>
        <label
          id={id}
          className="input-label"
          dangerouslySetInnerHTML={{ __html: children }}
        />
        {subLabel && !subLabel.includes("</") && (
          <span className="input-label--sub-label">{subLabel}</span>
        )}
        {subLabel && subLabel.includes("</") && (
          <span
            className="input-label--sub-label"
            dangerouslySetInnerHTML={{ __html: subLabel }}
          />
        )}
      </>
    );
  }

  return (
    <>
      <label id={id} className="input-label">
        {children}
        {required && <Required />}
      </label>
      {subLabel && !subLabel.includes("</") && (
        <span className="input-label--sub-label">{subLabel}</span>
      )}
      {subLabel && subLabel.includes("</") && (
        <span
          className="input-label--sub-label"
          dangerouslySetInnerHTML={{ __html: subLabel }}
        />
      )}
    </>
  );
};

export default Label;

const Required = () => {
  return <span className="text-error">&nbsp;*</span>;
};
