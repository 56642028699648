import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

import "./index.scss";

type Props = {
  url: string | null;
};

const DisplayFile: React.FC<Props> = (props) => {
  const { url } = props;

  if (!url) {
    return null;
  }

  return (
    <div className="display-file">
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        title="Ouvrir le document"
      >
        <FontAwesomeIcon icon={faFilePdf as any} />
        &nbsp;Voir le document
      </a>
    </div>
  );
};

export default DisplayFile;
