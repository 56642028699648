import React from "react";
import SocialPictogram from "../SocialPictogram";
import PreFooter from "./PreFooter";
import "./styles.scss";

const links = [
  { href: "https://myffbad.fr/mentions-legales", text: "MENTIONS LÉGALES" },
  { href: "https://myffbad.fr/plan-du-site", text: "PLAN DU SITE" },
  { href: "https://myffbad.fr/credits", text: "CRÉDITS" },
  { href: "https://myffbad.fr/liens-utiles", text: "LIENS UTILES" },
  { href: "https://myffbad.fr/faq", text: "FOIRE AUX QUESTIONS" },
  {
    href: "https://www.ffbad.org/lafederation-partenaires",
    text: "PARTENAIRES",
  },
];

const MainFooter = () => (
  <>
    <PreFooter />

    <footer className="footer-background">
      <section className="footer-wrapper">
        <ul className="footer-link">
          {links.map(({ href, text }) => (
            <li className="footer-link-item" key={text}>
              <a href={href} target="_blank" rel="noopener noreferrer">
                {text}
              </a>
            </li>
          ))}
        </ul>
        <div className="brand-footer-logo">
          <img
            src="/assets/images/ffbad-footer.png"
            alt="Fédération Francaise de Badminton"
          />
        </div>
        <p className="footer-address">
          <span>9-11 Avenue Michelet - 93583 SAINT-OUEN CEDEX</span>
          <br />
          <a
            href="https://support.ffbad.org/open.php"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-phone-number"
          >
            Contacter la fédération
          </a>
        </p>
        <div className="social-link">
          <SocialPictogram />
        </div>
      </section>
    </footer>
  </>
);

export default MainFooter;
