import React from "react";
import { Modal } from "ffbad-components";
import { faUser } from "@fortawesome/free-solid-svg-icons";

type Props = {
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

const Confirmation: React.FC<Props> = (props) => {
  const { visible, onConfirm, onCancel } = props;

  return (
    <Modal
      title="Vous allez soumettre votre dossier"
      titleIcon={faUser}
      visible={visible}
      onSuccess={onConfirm}
      onCancel={onCancel}
      onClose={onCancel}
      labels={{ successButtonLabel: "Je soumets mon dossier" }}
    >
      En cliquant sur le bouton ci-dessous, vous confirmez que les informations
      fournies sont bien valides.
    </Modal>
  );
};

export default Confirmation;
