import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faDotCircle,
  faSquare,
  faCheckSquare,
} from "@fortawesome/free-regular-svg-icons";

import { KeyCodes } from "core/enums/keycodes";
import { buildClassName } from "utils/dom/dom.utils";

import "./index.scss";

type Props = {
  label: string;
  checked?: boolean;
  hasError?: boolean;
  disabled?: boolean;
  isMulti?: boolean;
  onChange: (checked: boolean) => void;
};

const InputRadio: React.FC<Props> = (props) => {
  const {
    label,
    checked = false,
    hasError = false,
    disabled = false,
    isMulti = false,
    onChange,
  } = props;
  const className = buildClassName("input-radio", [
    [checked, "checked"],
    [hasError, "input-error"],
    [disabled, "disabled"],
  ]);
  const handleOnChange = () => {
    if (disabled) {
      return;
    }

    onChange(!checked);
  };

  return (
    <div
      className={className}
      onClick={handleOnChange}
      role="button"
      tabIndex={disabled ? undefined : 0}
      onKeyDown={(e) => {
        if (e.keyCode === KeyCodes.SPACE || e.keyCode === KeyCodes.ENTER) {
          e.preventDefault();
          handleOnChange();
        }
      }}
    >
      <span className="icon">
        <FontAwesomeIcon icon={getIcon(checked, isMulti)} />
      </span>
      <span className="label">{label}</span>
    </div>
  );
};

export default InputRadio;

const getIcon = (checked: boolean, isMulti: boolean): any => {
  if (isMulti) {
    return checked ? faCheckSquare : faSquare;
  } else {
    return checked ? faDotCircle : faCircle;
  }
};
