import React from "react";
import { useDispatch } from "react-redux";
import { useEffectOnce, useToggle } from "hooks-me";

import {
  DISPLAY_ERROR,
  SET_FORM_DATA_FOR_STEP,
} from "core/redux/reducer/main.reducer";
import { POONA_PAYMENT_METHODS } from "core/enums/paymentMethods";
import { renewalStep06IsValid } from "utils/renewal/step06.utils";
import {
  Step01Data,
  Step05Data,
  Step06Data,
} from "pages/LicenceRenewal/renewal.interfaces";
import useDataForStep from "hooks/useDataForStep";
import Modalite from "./components/Modalite";
import PaymentMethods from "./components/PaymentMethods";
import Confirmation from "./components/Confirmation";
import Footer from "../../Footer";

import "./index.scss";

type Props = {
  onPrevious: () => void;
  onSubmit: () => void;
};

const KEYS = {
  PAYMENT_METHOD: "payment_methods",
  MODALITES: "modalites",
};

const Step06: React.FC<Props> = (props) => {
  const { onPrevious, onSubmit } = props;
  const [isVisible, setIsVisible] = useToggle(false);
  const dispatch = useDispatch();
  const step1Data = useDataForStep<Step01Data>(1);
  const step5Data = useDataForStep<Step05Data>(5);
  const step6Data = useDataForStep<Step06Data>(6);

  const birthday = step1Data.informations.birthDate || "";
  const totalPrice = step5Data.recap?.totalPrice || 0;
  const selectedPriceId = step5Data?.recap?.price?.priceId || -1;

  const handleOnChange = (key: string, data: any) => {
    const newFormData = { ...step6Data } as any;
    newFormData[key] = data;

    dispatch({
      type: SET_FORM_DATA_FOR_STEP,
      payload: { step: 6, data: newFormData },
    });
  };

  const handleOnSubmit = () => {
    setIsVisible(false);
    const validationErrors = renewalStep06IsValid(step6Data);

    if (validationErrors.length) {
      const [firstValidationError] = validationErrors;
      dispatch({
        type: DISPLAY_ERROR,
        payload: {
          message: `${firstValidationError.comment}`,
        },
      });
      return;
    }

    onSubmit();
  };

  useEffectOnce(() => {
    if (totalPrice === 0) {
      handleOnChange(KEYS.PAYMENT_METHOD, { paymentMethodId: 6 });
    }
  });

  return (
    <div className="">
      <PaymentMethods
        defaultData={step6Data && step6Data.payment_methods}
        onChange={(data: any) => handleOnChange(KEYS.PAYMENT_METHOD, data)}
        price={totalPrice}
      />

      {step6Data &&
        step6Data.payment_methods.paymentMethodId ===
          POONA_PAYMENT_METHODS.cb && (
          <Modalite
            defaultData={step6Data && step6Data.modalites}
            onChange={(data: any) => handleOnChange(KEYS.MODALITES, data)}
            price={totalPrice}
            birthday={birthday}
            selectedPriceId={selectedPriceId}
          />
        )}

      <p className="total-price">MONTANT : {totalPrice.toFixed(2)} €</p>

      <Footer
        onNext={() => setIsVisible(true)}
        onPrevious={onPrevious}
        nextLabel={`Soumettre mon dossier* (${totalPrice.toFixed(2)}€)`}
      />

      <div className="under-footer-text">
        *Le paiement ne sera effectif que lorsque mon dossier sera accepté et
        validé.
      </div>

      <Confirmation
        visible={isVisible}
        onConfirm={handleOnSubmit}
        onCancel={() => setIsVisible(false)}
      />
    </div>
  );
};

export default Step06;
