import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDotCircle, faCircle } from "@fortawesome/free-regular-svg-icons";

import { buildClassName } from "utils/dom/dom.utils";

import "./index.scss";

type Props = {
  active?: boolean;
  isHalf?: boolean;
  isLast?: boolean;
  onClick?: () => void;
  canClick: boolean;
};

const Dot: React.FC<Props> = (props) => {
  const {
    active = false,
    isHalf = false,
    isLast = false,
    onClick,
    canClick,
  } = props;
  const icon = active ? faDotCircle : faCircle;
  const className = buildClassName("wizard-dot", [[active, "active"]]);

  return (
    <div
      className={className}
      onClick={canClick ? onClick : () => {}}
      data-active={active}
      data-clickable={canClick}
    >
      <FontAwesomeIcon icon={icon as any} />

      {!isLast && <div className={`wizard-line${isHalf ? " half" : ""}`} />}
    </div>
  );
};

export default Dot;
