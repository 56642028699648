export enum CIVILITE_INDEXES {
  Male = "1",
  Female = "2",
}

export const CIVILITES = [
  {
    sexe: "Monsieur",
    prefixe: "M.",
    id: CIVILITE_INDEXES.Male,
  },
  {
    sexe: "Madame",
    prefixe: "Mme",
    id: CIVILITE_INDEXES.Female,
  },
];
