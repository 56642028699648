import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffectOnce } from "hooks-me";

import {
  DISPLAY_ERROR,
  SET_FORM_DATA_FOR_STEP,
} from "core/redux/reducer/main.reducer";
import PoonaService from "services/poona/poona.service";
import {
  InformationData,
  CoordsData,
  Step01Data,
  KeyValue,
  PRESTATION_TYPES,
  CustomField,
  CustomFieldAsArray,
} from "pages/LicenceRenewal/renewal.interfaces";
import { useInstance } from "hooks";
import { renewalStep01IsValid } from "utils/renewal/step01.utils";
import { CheckFieldError, Reasons } from "utils/renewal/renewal.utils";
import { getCustomFieldsAsArray } from "utils/poona/poona.utils";
import useDataForStep from "hooks/useDataForStep";

import LicenceTypeChooser from "./components/LicenceTypeChooser";
import Informations from "./components/Informations";
import Coords from "./components/Coords";
import CustomFields from "./components/CustomFields";
import WelcomeModal from "./components/WelcomeModal";
import Footer from "../../Footer";

import "../index.scss";

const poonaService = new PoonaService();

type Props = {
  onNext: () => void;
};

const KEYS = {
  INFORMATIONS: "informations",
  COORDS: "coords",
  LICENCE_TYPE: "licenceType",
  CUSTOM_FIELDS: "customFields",
};

const Step01: React.FC<Props> = (props) => {
  const [countries, setCountries] = useState<KeyValue[]>([]);
  const [nationalities, setNationalities] = useState<KeyValue[]>([]);
  const [customFields, setCustomFields] = useState<CustomFieldAsArray>();
  const [fieldErrors, setFieldErrors] = useState<CheckFieldError[]>([]);
  const [licenceTypeWasChoosen, setLicenceTypeWasChoosen] = useState(false);
  const { onNext } = props;
  const { key } = useParams<{ key?: string }>();
  const isRenewal = key != null;
  const dispatch = useDispatch();
  const instance = useInstance();
  const step1Data = useDataForStep<Step01Data>(1);

  const handleOnChange = (key: string, data: any) => {
    const newFormData = { ...step1Data } as any;
    newFormData[key] = data;

    dispatch({
      type: SET_FORM_DATA_FOR_STEP,
      payload: { step: 1, data: newFormData },
    });
  };

  const handleOnNext = () => {
    if (!instance) {
      return;
    }

    if (
      !licenceTypeWasChoosen ||
      !step1Data.licenceType ||
      !step1Data.licenceType.seasonId ||
      !step1Data.licenceType.prestationId
    ) {
      const error = {
        key: "licenceType",
        label: "Type de licence",
        reason: Reasons.MissingRequiredField,
        comment: `Le champ est obligatoire`,
      };
      setFieldErrors([error]);
      dispatch({
        type: DISPLAY_ERROR,
        payload: {
          message: `${error.comment} (${error.label})`,
        },
      });
      return;
    }

    setFieldErrors([]);
    const validationErrors = renewalStep01IsValid(
      step1Data,
      isRenewal,
      customFields
    );

    if (validationErrors.length) {
      setFieldErrors(validationErrors);
      const [firstValidationError] = validationErrors;
      dispatch({
        type: DISPLAY_ERROR,
        payload: {
          message: `${firstValidationError.comment} (${firstValidationError.label})`,
        },
      });
      return;
    }

    const { promise } = poonaService.prices(instance?.instanceId, {
      licence: step1Data.informations.licence,
      sex: step1Data.informations.gender as any,
      birthday: step1Data.informations.birthDate,
      customSeasonLicence: step1Data.licenceType,
    });

    promise
      .then((prices) => {
        if (!prices.length) {
          dispatch({
            type: DISPLAY_ERROR,
            payload: {
              message:
                "Il n'y a pas de tarif configuré pour cette catégorie de personne, contactez le club.",
            },
          });
          return;
        }

        onNext();
      })
      .catch((err) => {
        dispatch({ type: DISPLAY_ERROR, payload: { message: err } });
        return;
      });
  };

  useEffectOnce(() => {
    const { promise, cancel } = poonaService.countries();
    promise
      .then((response) => {
        if (typeof response === "string") {
          return;
        }

        const tempCountries = response.map((country) => ({
          key: country.ID,
          value: country.Name,
        }));

        const tempNationalities = response.map((country) => ({
          key: country.Nationality_ID,
          value: country.Nationality_Name,
        }));

        setCountries(tempCountries);
        setNationalities(tempNationalities);
      })
      .catch(() => {
        dispatch({
          type: DISPLAY_ERROR,
          payload: {
            message: "Impossible de récupérer pays",
          },
        });
      });

    return () => cancel();
  });

  useEffect(() => {
    if (!instance) {
      return;
    }

    const { promise, cancel } = poonaService.customFields(instance.instanceId);
    promise
      .then((response: CustomField | undefined) => {
        const output = getCustomFieldsAsArray(response);
        setCustomFields(output);
      })
      .catch(() => {
        dispatch({
          type: DISPLAY_ERROR,
          payload: {
            message: "Impossible de récupérer le formulaire pour ce club",
          },
        });
      });
    return () => cancel();
  }, [instance, dispatch]);

  return (
    <>
      <WelcomeModal />
      <LicenceTypeChooser
        licenceType={step1Data?.licenceType?.id}
        onChange={(licenceType: PRESTATION_TYPES) => {
          handleOnChange(KEYS.LICENCE_TYPE, licenceType);
          setLicenceTypeWasChoosen(true);
        }}
      />

      <Informations
        defaultData={step1Data?.informations}
        countries={countries}
        nationalities={nationalities}
        fieldErrors={fieldErrors}
        onChange={(data: InformationData) =>
          handleOnChange(KEYS.INFORMATIONS, data)
        }
      />

      <Coords
        defaultData={step1Data?.coords}
        countries={countries}
        fieldErrors={fieldErrors}
        onChange={(data: CoordsData) => handleOnChange(KEYS.COORDS, data)}
      />

      {customFields && Object.keys(customFields.fields).length !== 0 && (
        <CustomFields
          defaultData={step1Data?.customFields}
          customFields={customFields}
          onChange={(data: any) => handleOnChange(KEYS.CUSTOM_FIELDS, data)}
        />
      )}

      <Footer isFirstStep onNext={handleOnNext} />
    </>
  );
};

export default Step01;
