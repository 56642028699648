import React, { useRef, useEffect } from "react";
import { isFirefox } from "utils/dom/dom.utils";

type Props = {
  handleOnChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  open: boolean;
};

const UploadProof: React.FC<Props> = (props) => {
  const { handleOnChangeFile, open } = props;
  const fileInputRef = useRef<any>();

  useEffect(() => {
    if (!open || !fileInputRef || !fileInputRef.current) {
      return;
    }

    fileInputRef.current.click();
  }, [open]);

  return (
    <input
      ref={fileInputRef}
      className={isFirefox() ? "" : "display-proofs--file"}
      type="file"
      onChange={handleOnChangeFile}
    />
  );
};

export default UploadProof;
