import {
  Step03Data,
  QUESTION_STATES,
  QSSportData,
  PRESTATION_TYPES,
} from "pages/LicenceRenewal/renewal.interfaces";
import { CheckFieldError, Reasons } from "./renewal.utils";
import moment from "moment";

const KEY_OF_INSURANCE_CONSENT = 7;

export const PRESTA_NO_DOCUMENTS: PRESTATION_TYPES[] = [
  PRESTATION_TYPES.NON_PLAYER,
  PRESTATION_TYPES.NO_LICENCE,
  PRESTATION_TYPES.ALREADY_LICENCE,
];

export const renewalStep03IsValid = (args: {
  data?: Step03Data;
  displayCertificate: boolean;
  displayQSSport: boolean;
  birthDate: string;
  seasonId: number;
  isNonPlayer: boolean;
}): CheckFieldError[] => {
  const {
    data,
    displayCertificate = false,
    displayQSSport = false,
    birthDate,
    seasonId,
    isNonPlayer,
  } = args;

  let errors: CheckFieldError[] = [];

  if (
    displayCertificate &&
    (!data || !data.certificate || !data.certificate.newCertificateFile)
  ) {
    errors.push({
      key: "certificate",
      reason: Reasons.MissingRequiredField,
      comment: "Vous devez fournir le certificat médical.",
    });
  }

  const otherErrorForDate = cmDateIsValid({
    displayCertificate,
    birthDate,
    data,
    seasonId,
    hasOnePositiveAnswer: data
      ? questionDataHasOneOfState(data.qssport, [QUESTION_STATES.YES])
      : false,
  });
  errors = errors.concat(otherErrorForDate);

  if (data && questionDataHasOneOfState(data.qssport, [QUESTION_STATES.YES])) {
    // nothing to do.
  } else {
    if (!data || qssportHasMissingAnswer(displayQSSport, data)) {
      errors.push({
        key: "qssport",
        reason: Reasons.MissingRequiredField,
        comment:
          "Vous devez répondre à toutes les questions du formulaire de santé.",
      });
    }
  }

  if (!data || !insuranceConsentIsValid(data.assurance)) {
    errors.push({
      key: "assurance",
      reason: Reasons.MissingRequiredField,
      comment: "Merci de valider les conditions de garanties",
    });
  }

  if (!isNonPlayer && (!data || !data.player[31])) {
    errors.push({
      key: "player",
      reason: Reasons.MissingRequiredField,
      comment:
        "Merci de valider les engagements du joueur pour le certificat médical",
    });
  }

  return errors;
};

const qssportHasMissingAnswer = (displayQSSport: boolean, data: Step03Data) => {
  if (!displayQSSport) {
    return false;
  }

  if (
    !data ||
    !data.qssport ||
    !data.qssport.questionData ||
    !data.qssport.questionData.length
  ) {
    return true;
  }

  return (
    displayQSSport &&
    questionDataHasOneOfState(data.qssport, [QUESTION_STATES.UNDEFINED])
  );
};

export const questionDataAreOfState = (
  data?: QSSportData,
  wantedStates: QUESTION_STATES[] = []
): boolean => {
  if (!data || !data.questionData) {
    return false;
  }

  const predicate = (q: any) => wantedStates.includes(q.value);
  const items = data.questionData.filter(predicate);
  return items.length === data.questionData.length;
};

export const questionDataHasOneOfState = (
  data?: QSSportData,
  wantedStates: QUESTION_STATES[] = []
): boolean => {
  if (!data || !data.questionData) {
    return false;
  }

  const predicate = (q: any) => wantedStates.includes(q.value);
  const items = data.questionData.filter(predicate);
  return items.length !== 0;
};

export const insuranceConsentIsValid = (assurance: any): boolean => {
  return (
    assurance &&
    assurance[KEY_OF_INSURANCE_CONSENT] &&
    assurance[KEY_OF_INSURANCE_CONSENT] === true
  );
};

export const cmDateIsValid = (args: {
  displayCertificate: boolean;
  birthDate: string;
  data?: Step03Data;
  hasOnePositiveAnswer?: boolean;
  seasonId: number;
}): CheckFieldError[] => {
  if (!args) {
    return [];
  }

  const {
    displayCertificate,
    birthDate,
    data,
    hasOnePositiveAnswer,
    seasonId,
  } = args;

  const cmIsNeeded = displayCertificate || hasOnePositiveAnswer;

  if (!cmIsNeeded) {
    return [];
  }

  // no date
  if (!data?.certificate?.certificateDate) {
    return [
      {
        key: "certificateDate",
        reason: Reasons.NotProvided,
        comment: "La date du certificat médical est obligatoire",
      },
    ];
  }

  const now = moment();
  let CURRENT_SEASON_DAY = moment(`${2000 + (seasonId + 2)}-09-01`);
  if (now.isAfter(CURRENT_SEASON_DAY)) {
    CURRENT_SEASON_DAY = now;
  }

  const birthday = moment(birthDate);
  const isMinor = CURRENT_SEASON_DAY.diff(birthday, "years") < 18;
  const MIN_CM_DATE = isMinor
    ? moment(CURRENT_SEASON_DAY).add(-6, "months")
    : moment(CURRENT_SEASON_DAY).add(-1, "year");

  if (!cmIsNeeded) {
    return [];
  }

  let cmDate = moment(data?.certificate.certificateDate, "DD/MM/YYYY");
  if (cmDate.format("DD/MM/YYYY") === "Invalid date") {
    cmDate = moment(data?.certificate.certificateDate);
  }

  // Date in future.
  if (cmDate.isAfter(now)) {
    return [
      {
        key: "certificateDate",
        reason: Reasons.NotProvided,
        comment: "La date du certificat médical ne doit pas être dans le futur",
      },
    ];
  }

  if (cmDate.isBefore(MIN_CM_DATE, "day")) {
    return [
      {
        key: "certificateDate",
        reason: Reasons.NotProvided,
        comment: `La date du certificat doit être supérieure ou égale au ${MIN_CM_DATE.format(
          "DD/MM/YYYY"
        )}`,
      },
    ];
  }

  return [];
};

export const calculateAge = (birthday: string) => {
  const ageDifMs = Date.now() - new Date(birthday).getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const shouldShowQSCM = (args: {
  cmIsNeededFromPoona: boolean; // From Poona.
  choosenLicenceType?: PRESTATION_TYPES;
  qsSportData: QSSportData;
}): { qs: boolean; cm: boolean } => {
  const { cmIsNeededFromPoona, choosenLicenceType, qsSportData } = args;

  const qsSportHasOneYes =
    qsSportData?.questionData?.length &&
    questionDataHasOneOfState(qsSportData, [QUESTION_STATES.YES]);

  if (cmIsNeededFromPoona) {
    return { qs: false, cm: true };
  }

  // [FFBAD-404] Fonctional rule: If one "YES" or "UNDEFINED" was provided => medical certificate is required.
  if (qsSportHasOneYes) {
    return { qs: true, cm: qsSportHasOneYes };
  }

  if (PRESTA_NO_DOCUMENTS.includes(choosenLicenceType as any)) {
    return { qs: false, cm: false };
  }

  return { qs: true, cm: false };
};
