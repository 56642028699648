import useMainState from "./useMainState";
import useNotification from "./useNotification";
import useInstance from "./useInstance";
import useRegistrationKeyGeneration from "./useRegistrationKeyGeneration";
import useFetchConsents from "./useFetchConsents";
import useConsent from "./useConsent";
import useRenewalLicence from "./useRenewalLicence";
import useFetchPrestations from "./useFetchPrestations";
import useCustomFieldsPrice from "./useCustomFieldsPrice";

export {
  useMainState,
  useNotification,
  useInstance,
  useRegistrationKeyGeneration,
  useFetchConsents,
  useConsent,
  useRenewalLicence,
  useFetchPrestations,
  useCustomFieldsPrice,
};
