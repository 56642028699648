import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useEffectOnce } from "hooks-me";

import { DISPLAY_ERROR } from "core/redux/reducer/main.reducer";
import generateComponentByType from "./custom.config";
import { CustomFieldAsArray } from "pages/LicenceRenewal/renewal.interfaces";
import PoonaService from "services/poona/poona.service";
import useRegistrationKey from "hooks/useRegistrationKey";
import { Card } from "components";

const service = new PoonaService();

type Props = {
  customFields: CustomFieldAsArray;
  defaultData?: Record<string, any>;
  onChange: (data: any) => void;
};

const ERROR_MESSAGE_UPLOAD_FILE = "Impossible de télécharger votre document.";

const CustomFields: React.FC<Props> = (props) => {
  const { customFields, onChange, defaultData = {} } = props;
  const [data, setData] = useState<any>(defaultData);
  const registrationKey = useRegistrationKey();
  const dispatch = useDispatch();

  useEffectOnce(() => {
    const temp = { ...data, ...defaultData };
    setData(temp);
    onChange(temp);
  });

  const handleOnChange = (key: string, value: any) => {
    const newData = { ...data, [key]: value };
    setData(newData);
    onChange(newData);
  };

  const handleOnUploadFile = (key: string, files: FileList) => {
    const newData = {
      ...data,
      [key]: undefined,
      [`${key}_filename`]: undefined,
    };

    if (!files || !files.length) {
      setData(newData);
      onChange(newData);
      return;
    }

    const aFile = files[0];
    service
      .uploadTempFile(registrationKey, `customfield_${key}`, aFile)
      .then((response) => {
        newData[key] = response.key;
        newData[`${key}_filename`] = aFile.name;

        setData(newData);
        onChange(newData);
      })
      .catch((_) => {
        dispatch({
          type: DISPLAY_ERROR,
          payload: { message: ERROR_MESSAGE_UPLOAD_FILE },
        });
      });
  };

  if (!customFields || !customFields.fields) {
    return null;
  }

  return (
    <Card title={customFields.title} collapsible isOpen={true}>
      {customFields.fields.map((field) =>
        generateComponentByType(
          field,
          handleOnChange,
          handleOnUploadFile,
          field.type === "file"
            ? defaultData[`${field.key}_filename`]
            : defaultData[field.key] || data[field.key]
        )
      )}
    </Card>
  );
};

export default CustomFields;
