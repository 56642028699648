import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useToggle } from "hooks-me";

import PoonaService from "services/poona/poona.service";
import { SET_INSTANCE } from "core/redux/reducer/main.reducer";
import { Loading } from "components";

const withLayout = (Component: React.FC<any>) => () => {
  const dispatch = useDispatch();
  const { sigle } = useParams<{ sigle?: string }>();
  const [loading, setLoading] = useToggle(true);
  const poonaService = useMemo(() => new PoonaService(), []);

  const redirectToMainSite = useCallback(() => {
    if (process.env.REACT_APP_NODE_ENV === "dev") {
      console.log("About to redirect on myffbad.fr");
    }

    window.location.href = "https://myffbad.fr";
  }, []);

  useEffect(() => {
    if (!sigle) {
      if (process.env.REACT_APP_NODE_ENV === "dev") {
        window.location.href = `http://localhost:3001/madbad27`;
        return;
      }

      redirectToMainSite();
      return;
    }

    const { promise, cancel } = poonaService.instanceBySigle(sigle);

    promise
      .then((payload) => {
        dispatch({ type: SET_INSTANCE, payload });
        setLoading(false);
      })
      .catch(redirectToMainSite);
    return () => cancel();
  }, [dispatch, sigle, redirectToMainSite, poonaService, setLoading]);

  if (loading) {
    return <Loading fullscreen />;
  }

  return <Component />;
};

export default withLayout;
