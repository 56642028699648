import React from "react";

import { ConsentData } from "services/poona/poona.interfaces";
import { KeyValue } from "pages/LicenceRenewal/renewal.interfaces";
import { InputRadioGroup } from "components/form";
import DisplayFile from "../DisplayFile";
import { CheckFieldError } from "utils/renewal/renewal.utils";

type Props = {
  aConsent: ConsentData;
  handleOnChange: (consentId: number, checked: boolean) => void;
  fieldErrors: CheckFieldError[];
  defaultChecked?: boolean;
};

const YES_NO_ITEMS: KeyValue[] = [
  {
    key: "true",
    value: "Oui",
  },
  {
    key: "false",
    value: "Non",
  },
];

const Question: React.FC<Props> = (props) => {
  const { aConsent, handleOnChange, fieldErrors, defaultChecked } = props;
  const error = fieldErrors.find(
    (err) => err.key === "acceptation" && err.other === aConsent.consentId
  );

  return (
    <>
      {(aConsent.label || aConsent.content) && (
        <InputRadioGroup
          label={extractText(aConsent)}
          items={YES_NO_ITEMS}
          onChange={([checked]) =>
            handleOnChange(aConsent.consentId, checked === "true")
          }
          color={error ? "danger" : "default"}
          hasHtml
          keyOfCheckedItem={
            (defaultChecked !== undefined && defaultChecked.toString()) ||
            undefined
          }
        />
      )}
      {aConsent.url && (
        <>
          <DisplayFile url={aConsent.url} />
          <br />
        </>
      )}
    </>
  );
};

export default Question;

const extractText = (aConsent: ConsentData) => {
  if (aConsent.label && aConsent.content) {
    return `${aConsent.label}<br />${aConsent.content}`;
  }

  if (aConsent.label) {
    return aConsent.label;
  }

  if (aConsent.content) {
    return aConsent.content;
  }

  return "";
};
