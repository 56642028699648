import React, { useEffect } from "react";
import { useToggle } from "hooks-me";

import CardHeader from "./components/CardHeader";

import "./styles.scss";

type Props = {
  id?: string;
  children: any;
  title: string;
  subtitle?: string;
  collapsible?: boolean;
  isOpen?: boolean;
};

const Card: React.FC<Props> = (props) => {
  const [localIsOpen, setLocalIsOpen] = useToggle(false);
  const {
    id,
    children,
    title,
    subtitle,
    collapsible = false,
    isOpen = false,
  } = props;

  useEffect(() => {
    setLocalIsOpen(isOpen);
  }, [isOpen, setLocalIsOpen]);

  const handleOnToggle = (value: boolean) => {
    setLocalIsOpen(value);
  };

  return (
    <section
      id={id}
      className="card-container"
      aria-expanded={localIsOpen}
      aria-disabled="false"
    >
      <CardHeader
        title={title}
        subtitle={subtitle}
        collapsible={collapsible}
        isOpen={localIsOpen}
        onToggle={handleOnToggle}
      />

      <div
        role="region"
        aria-disabled="true"
        className={`step-content ${
          !collapsible || localIsOpen ? "visible" : ""
        }`}
      >
        {children}
      </div>
    </section>
  );
};

export default Card;
