import React from "react";

import { Pricing, PricingDiscount } from "services/poona/poona.interfaces";
import { Switch } from "components";

type Props = {
  price: Pricing;
  selectedIds: string[];
  onChooseDiscount: (selected: boolean, id: string) => void;
};

const Reductions: React.FC<Props> = (props) => {
  const {
    price: { discounts = [] },
    selectedIds,
    onChooseDiscount,
  } = props;

  const handleOnSelect = (selected: boolean, discountId: string) => {
    onChooseDiscount(selected, discountId);
  };

  if (!discounts.length) {
    return null;
  }

  return (
    <div>
      <h4>Choix de vos réductions</h4>
      <h5>Pour certaines réductions, il vous faudra fournir un justificatif</h5>

      {discounts.map((discount) => {
        const selected = selectedIds.includes(discount.discountId);

        return (
          <div
            key={discount.name}
            className="reductions-options-container--item"
          >
            <div>
              •&nbsp;{discount.name} {displayValue(discount)}
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: discount.description || "",
                }}
              />
            </div>

            <div>
              <Switch
                // https://supralog.myjetbrains.com/youtrack/issue/FFBAD-819
                key={Math.random()}
                on={selected}
                onChange={(checked) =>
                  handleOnSelect(checked, discount.discountId)
                }
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Reductions;

const displayValue = (discount: PricingDiscount): string => {
  const { discountAmount, discountPercent } = discount;
  const str = discountAmount ? `${discountAmount}€` : `${discountPercent}%`;
  return `(-${str})`;
};
