import React, { useState, useEffect, useCallback } from "react";
import ReactAutocomplete from "react-autocomplete"; // https://github.com/reactjs/react-autocomplete
import { useToggle } from "hooks-me";

import PoonaService from "services/poona/poona.service";
import { delayedCallback } from "utils/dom/dom.utils";
import { Label, FormError } from "components/form";
import { CheckFieldError } from "utils/renewal/renewal.utils";
import { useDispatch } from "react-redux";
import { DISPLAY_ERROR } from "core/redux/reducer/main.reducer";

type Props = {
  label: string;
  defaultValue?: string;
  required?: boolean;
  error?: CheckFieldError;
  birthday?: string;
  onChange: (town: any) => void;
};

const service = new PoonaService();

const Autocomplete: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { label, defaultValue, required, onChange, error, birthday } = props;
  const [searchValue, setSearchValue] = useState<string>();
  const [value, setValue] = useState<string>();
  const [departement, setDepartement] = useState<string>();
  const [towns, setTowns] = useState<any[]>([]);
  const [selected, setSelected] = useToggle(false);
  const [init, setInit] = useToggle(true);

  const fetchTowns = useCallback(
    async (callback?: () => void) => {
      setValue(undefined);

      if (!searchValue) {
        setTowns([]);
        return;
      }
      let townName = searchValue;
      if (townName.includes("(")) {
        townName = searchValue.split(" (")[0];
      }

      const { promise, cancel } = service.townsByName(townName, birthday);
      promise
        .then((response) => {
          setTowns(response);
          if (callback) {
            callback();
          }
        })
        .catch(() => {
          dispatch({
            type: DISPLAY_ERROR,
            payload: {
              message: "Impossible de récupérer la ville",
            },
          });
        });

      return () => cancel();
    },
    [searchValue, birthday, dispatch]
  );

  useEffect(() => {
    delayedCallback(fetchTowns, 200);
    // eslint-disable-next-line
  }, [searchValue]);

  useEffect(() => {
    if (typeof towns === "string") {
      return;
    }

    const town = (towns || []).find((town) => {
      return town.nom === value && town.codeDepartement === departement;
    });

    if (!town) {
      return;
    }

    onChange(town);
    // eslint-disable-next-line
  }, [value, towns]);

  useEffect(() => {
    if (towns.length !== 1) {
      return;
    }
    const [town] = towns;
    setValue(town.nom);
    setDepartement(town.codeDepartement);
    setSelected(true);
    setSearchValue(`${town.nom} (${town.codeDepartement})`);
  }, [setSelected, towns]);

  useEffect(() => {
    if (!defaultValue || !init) {
      return;
    }

    setSearchValue(defaultValue);
    fetchTowns(() => {
      setValue(defaultValue);
      setSelected(true);
      setInit(false);
    });
  }, [defaultValue, fetchTowns, init, setInit, setSelected]);

  const getTownLabel = (town: any) => {
    let label = town.nom;
    if (town.codeDepartement) {
      label += ` (${town.codeDepartement})`;
    }
    return label;
  };

  return (
    <div className="form-input">
      {label && <Label required={required}>{label}</Label>}
      <ReactAutocomplete
        inputProps={{ placeholder: "Saisissez votre lieu de naissance" }}
        getItemValue={(item: any) => `${item.nom} (${item.codeDepartement})`}
        items={towns}
        renderItem={(item: any, isHighlighted: boolean) => (
          <div
            key={item.code}
            style={{ background: isHighlighted ? "lightgray" : "white" }}
          >
            {getTownLabel(item)}
          </div>
        )}
        value={searchValue}
        onChange={(e: any) => {
          setSearchValue(e.target.value);
          setSelected(false);
        }}
        onSelect={(val: string) => {
          const name = val.split(" (")[0];
          const codeDepartement = val.split(" (")[1].replace(")", "");
          setValue(name);
          setDepartement(codeDepartement);
          setSearchValue(val);
          setSelected(true);
        }}
        open={!selected && towns.length !== 0}
        menuStyle={{
          zIndex: 1000,
          maxHeight: 200,
          overflow: "auto",
          border: "1px solid lightgrey",
        }}
      />

      {error && <FormError content={error.comment} />}
    </div>
  );
};

export default Autocomplete;
