export const COUNTRY_FRANCE_ID = 83;
export const DEFAULT_DEPARTMENT = 99;
export const KEYS = {
  FIRSTNAME: "firstName",
  LASTNAME: "lastName",
  MAIDENNAME: "maidenName",
  GENDER: "gender",
  BIRTHDATE: "birthDate",
  BIRTH_PLACE: "birthPlace",
  BIRTH_DEPARTMENT: "birthPlaceDepartment",
  NATIONALITY_ID: "nationalityId",
  NATIVE_COUNTRY_ID: "nativeCountryId",
  AVATAR_URL: "avatarUrl",
  NEW_AVATAR_FILE: "newAvatarFile",
  LICENCE: "licence",
  //
  BIRTH_CITY_INSEE: "birthCityInsee",
};
