import React from "react";
import { useEffectOnce } from "hooks-me";

import { CheckFieldError } from "utils/renewal/renewal.utils";
import { randomId } from "utils/string/string.utils";

import { Label, FormError } from "..";
import { KeyValue } from "pages/LicenceRenewal/renewal.interfaces";

import "./index.scss";

type Props = {
  items?: KeyValue[];
  defaultValue?: string;
  label?: string;
  subLabel?: string;
  onChange: (value: string) => void;
  error?: CheckFieldError;
  firstIsBlank?: boolean;
  disabled?: boolean;
  required?: boolean;
};

const DEFAULT_EMPTY_VALUE_KEY = "-1";

const BlankItem = () => {
  return (
    <option value="-1" disabled>
      Choisir une valeur
    </option>
  );
};

const Select: React.FC<Props> = (props) => {
  const {
    items = [],
    defaultValue,
    label,
    subLabel,
    onChange,
    error,
    firstIsBlank = false,
    disabled = false,
    required = false,
  } = props;

  useEffectOnce(() => {
    if (firstIsBlank || items.length !== 1) {
      return;
    }

    const [item] = items;
    setTimeout(() => onChange(item.value), 200);
  });

  const labelId = randomId();
  let selectDefaultValue = defaultValue;

  if (firstIsBlank && !defaultValue) {
    selectDefaultValue = DEFAULT_EMPTY_VALUE_KEY;
  }

  return (
    <span className="form-select">
      {label && (
        <Label id={labelId} subLabel={subLabel} required={required}>
          {label}
        </Label>
      )}

      <select
        key={selectDefaultValue}
        className={error ? "input-error" : ""}
        value={selectDefaultValue}
        onChange={(e) => onChange(e.currentTarget.value)}
        aria-labelledby={labelId}
        aria-disabled={disabled}
        disabled={disabled}
      >
        {firstIsBlank && <BlankItem />}

        {items.map((item, index) => (
          <option key={`${index}${item.key}${item.value}`} value={item.key}>
            {item.value}
          </option>
        ))}
      </select>

      {error && <FormError content={error.comment} />}
    </span>
  );
};

export default Select;
