import React from "react";

import {
  StepComponent,
  CertificateData,
  InformationData,
  ContactsData,
} from "pages/LicenceRenewal/renewal.interfaces";
import { CheckFieldError } from "utils/renewal/renewal.utils";

import { Card } from "components";

type Props = StepComponent & {
  defaultData?: CertificateData;
  fieldErrors: CheckFieldError[];
  mainResponsable: ContactsData;
  personData: InformationData;
};

const Attestation: React.FC<Props> = (props) => {
  const {
    mainResponsable: { firstName = "", lastName = "" } = {},
    personData: {
      firstName: personFirstName = "",
      lastName: personLastName = "",
    } = {},
    fieldErrors = [],
  } = props;

  return (
    <Card title="Attestation">
      <div className="acceptation-container">
        <p>
          <u>Pour les mineurs :</u>
        </p>
        <p>
          Je soussigné(e),&nbsp;
          <b>
            {firstName} {lastName}
          </b>
          &nbsp;en ma qualité de&nbsp;
          <b>représentant légal principal</b> de&nbsp;
          <b>
            {personFirstName} {personLastName}
          </b>
          &nbsp;atteste avoir :
        </p>
        <ul>
          <li>
            soit déposé un certificat médical conforme au attente de la
            Fédération française de Badminton
          </li>
          <li>
            soit renseigné le questionnaire de santé QS-SPORT et avoir répondu
            par la négative à l'ensemble des questions
          </li>
        </ul>

        {fieldErrors.length !== 0 && (
          <p className="text-error">{fieldErrors[0].comment}</p>
        )}
      </div>
    </Card>
  );
};

export default Attestation;
