import React from "react";
import { Link } from "react-router-dom";

import { ErrorTypeLabels, ErrorTypes } from "core/enums/errorTypes";
import { ROUTE_PATHS, ROUTE_KEYS } from "utils/routes/routes.utils";

import "./styles.scss";

type Props = {
  type: ErrorTypes;
  hideGoToHomeLink?: boolean;
};

const DEFAULT_ERROR = "Une erreur est survenue";
const DEFAULT_ERROR_KEY = ErrorTypes.Unknown;

const FullscreenError: React.FC<Props> = (props) => {
  const { type = DEFAULT_ERROR_KEY, hideGoToHomeLink = false } = props;

  return (
    <div className="error-page">
      <div className="error-page__content">
        <img
          src="/assets/images/ffbad.jpg"
          className="error-page--logo"
          alt="logo ffbad"
        />

        <h3 className="error-page--title">
          {ErrorTypeLabels[type] || DEFAULT_ERROR}
        </h3>
        {!hideGoToHomeLink && (
          <div className="error-page--text">
            <Link to={ROUTE_PATHS[ROUTE_KEYS.HOME]}>
              Retourner à la page d'accueil
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default FullscreenError;
