import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useArray, useToggle } from "hooks-me";

import { DISPLAY_ERROR } from "core/redux/reducer/main.reducer";
import PoonaService from "services/poona/poona.service";
import {
  CoordsData,
  KeyValue,
  StepComponent,
} from "pages/LicenceRenewal/renewal.interfaces";
import {
  CheckFieldError,
  extractErrorByKey,
} from "utils/renewal/renewal.utils";
import { delayedCallback } from "utils/dom/dom.utils";
import { AFNOR_MAX_LENGTH } from "utils/renewal/step01.utils";
import { Input, Select } from "components/form";
import { Card } from "components";
import { Town } from "services/poona/poona.interfaces";
import PhoneInput from "components/form/PhoneInput";

import "./styles.scss";

type Props = StepComponent & {
  defaultData?: CoordsData;
  countries: KeyValue[];
  fieldErrors: CheckFieldError[];
};

const service = new PoonaService();
const KEYS = {
  POSTAL_CODE: "postalCode",
  CITY: "city",
  ADDRESS: "address",
  FLOOR: "floor",
  BUILDING: "building",
  LOCALITY: "locality",
  COUNTRY_ID: "countryId",
  PHONE_MOBILE: "mobilePhone",
  PHONE_FIX: "fixPhone",
  EMAIL: "email",
  EMAIL_CONFIRMATION: "emailConfirmation",
};
const FRANCE_COUNTRY_ID = "83";

const Coords: React.FC<Props> = (props) => {
  const { onChange, defaultData, countries = [], fieldErrors = [] } = props;
  const [formData, setFormData] = useState<CoordsData>({});
  const { value: towns, set: setTowns } = useArray<Town>([]);
  const [unknownTown, setUnknownTown] = useToggle(false);
  const dispatch = useDispatch();
  const isFrance = useMemo(() => formData.countryId === FRANCE_COUNTRY_ID, [
    formData.countryId,
  ]);

  useEffect(() => {
    if (!defaultData) {
      return;
    }
    setFormData(defaultData);
  }, [defaultData]);

  const handleOnChange = (key: string, value: any) => {
    const newFormData: any = { ...formData };
    newFormData[key] = value;
    onChange(newFormData);
  };

  const handleOnChangeMultiple = (
    data: { key: string; value: number | null }[]
  ) => {
    const newFormData: any = { ...formData };
    for (const temp of data) {
      newFormData[temp.key] = temp.value;
    }

    onChange(newFormData);
  };

  useEffect(() => {
    if (!isFrance) {
      return;
    }

    if (!formData.postalCode || formData.postalCode.length !== 5) {
      setTowns([]);
      return;
    }

    setUnknownTown(false);

    if (formData.postalCode === "98000") {
      setTowns([{ code: "99138", nom: "Monaco" }]);
    } else {
      const { promise, cancel } = service.towns(formData.postalCode);
      promise
        .then((towns) => {
          towns.unshift({ code: "0", nom: "Selectionner" });
          setTowns(towns);

          if (towns.length) {
            if (formData.city) {
              const potentialTown = towns.find(
                (aTown) => aTown.nom === formData.city
              );

              if (potentialTown) {
                handleOnChange(KEYS.CITY, potentialTown.nom);
              } else {
                handleOnChange(KEYS.CITY, towns[0].nom);
              }

              return;
            }

            handleOnChange(KEYS.CITY, towns[0].nom);
            return;
          }

          dispatch({
            type: DISPLAY_ERROR,
            payload: { message: "Le code postal n'est pas valide" },
          });
          handleOnChange(KEYS.POSTAL_CODE, null);
        })
        .catch(() => {
          dispatch({
            type: DISPLAY_ERROR,
            payload: {
              message: `Impossible de récupérer la ville avec le code postal ${formData.postalCode}`,
            },
          });
        });
      return () => cancel();
    }
    // eslint-disable-next-line
  }, [formData.postalCode, dispatch]);

  return (
    <Card
      title="Vos coordonnées et contacts"
      subtitle="Saisissez vos coordonnées et informations de contact"
      collapsible
      isOpen
    >
      {/* Address > */}
      <DisplayAddressInputs
        formData={formData}
        onChange={handleOnChange}
        fieldErrors={fieldErrors}
      />

      <div className="form-flex-container">
        <div className="form-flex-item">
          <Select
            label="Pays"
            items={countries}
            defaultValue={(formData as any)[KEYS.COUNTRY_ID]}
            onChange={(value) => handleOnChange(KEYS.COUNTRY_ID, value)}
            error={extractErrorByKey(KEYS.COUNTRY_ID, fieldErrors)}
            required
          />
        </div>

        <div className="form-flex-item">
          <Input
            label="Code postal"
            placeholder="Saisissez votre code postal (ex: 75000)"
            defaultValue={(formData as any)[KEYS.POSTAL_CODE]}
            onChange={(value) =>
              delayedCallback(() => {
                handleOnChangeMultiple([
                  {
                    key: KEYS.POSTAL_CODE,
                    value,
                  },
                  { key: KEYS.CITY, value: undefined },
                ]);
              })
            }
            error={extractErrorByKey(KEYS.POSTAL_CODE, fieldErrors)}
            required
            maxLength={5}
          />
        </div>

        <div className="form-flex-item">
          {!isFrance && (
            <Input
              label="Ville"
              placeholder="Saisissez votre ville"
              defaultValue={(formData as any)[KEYS.CITY]}
              error={extractErrorByKey(KEYS.CITY, fieldErrors)}
              onChange={(value) => handleOnChange(KEYS.CITY, value)}
              required
            />
          )}

          {isFrance && (
            <>
              {unknownTown && (
                <Select
                  label="Ville"
                  items={[{ key: formData.city + "", value: formData.city }]}
                  defaultValue={(formData as any)[KEYS.CITY]}
                  onChange={() => {}}
                  error={extractErrorByKey(KEYS.CITY, fieldErrors)}
                  required
                />
              )}

              {!unknownTown && towns.length !== 0 && (
                <Select
                  label="Ville"
                  items={towns.map((p) => ({ key: p.nom, value: p.nom }))}
                  defaultValue={(formData as any)[KEYS.CITY]}
                  onChange={(value) => handleOnChange(KEYS.CITY, value)}
                  error={extractErrorByKey(KEYS.CITY, fieldErrors)}
                  required
                />
              )}
            </>
          )}
        </div>
      </div>
      {/* < Address */}

      <hr />

      {/* Contacts > */}
      <div className="form-flex-container">
        <div className="form-flex-item">
          <PhoneInput
            label="Téléphone portable"
            onChange={(value) => {
              delayedCallback(() => {
                handleOnChange(KEYS.PHONE_MOBILE, value);
              });
            }}
            defaultValue={(formData as any)[KEYS.PHONE_MOBILE]}
            error={extractErrorByKey(KEYS.PHONE_MOBILE, fieldErrors)}
            required
          />
        </div>

        <div className="form-flex-item">
          <PhoneInput
            label="Téléphone fixe (facultatif)"
            onChange={(value) => {
              delayedCallback(() => {
                handleOnChange(KEYS.PHONE_FIX, value);
              });
            }}
            defaultValue={(formData as any)[KEYS.PHONE_FIX]}
            error={extractErrorByKey(KEYS.PHONE_FIX, fieldErrors)}
          />
        </div>
      </div>

      <div className="form-flex-container">
        <div className="form-flex-item">
          <Input
            type="email"
            placeholder="Saisissez votre adresse e-mail"
            label="Adresse e-mail"
            defaultValue={(formData as any)[KEYS.EMAIL]}
            onChange={(value) =>
              delayedCallback(() => handleOnChange(KEYS.EMAIL, value))
            }
            error={extractErrorByKey(KEYS.EMAIL, fieldErrors)}
            required
          />
        </div>
        <div className="form-flex-item">
          <Input
            type="email"
            placeholder="Saisissez votre adresse e-mail"
            label="Confirmation de l'adresse e-mail"
            defaultValue={(formData as any)[KEYS.EMAIL_CONFIRMATION]}
            onChange={(value) =>
              delayedCallback(() =>
                handleOnChange(KEYS.EMAIL_CONFIRMATION, value)
              )
            }
            error={extractErrorByKey(KEYS.EMAIL_CONFIRMATION, fieldErrors)}
            required
            lockPaste
          />
        </div>
      </div>
      {/* < Contacts */}
    </Card>
  );
};

export default Coords;

const DisplayAddressInputs = (props: any) => {
  const { formData, onChange, fieldErrors } = props;

  return (
    <>
      <div className="form-flex-container">
        <div className="form-flex-item">
          <Input
            label="Appartement - Etage"
            placeholder="Saisissez votre appartement - étage"
            defaultValue={(formData as any)[KEYS.FLOOR]}
            onChange={(value) =>
              delayedCallback(() => onChange(KEYS.FLOOR, value))
            }
            error={extractErrorByKey(KEYS.FLOOR, fieldErrors)}
            maxLength={AFNOR_MAX_LENGTH}
          />
        </div>
        <div className="form-flex-item">
          <Input
            label="Bâtiment - Résidence"
            placeholder="Saisissez votre bâtiment - résidence"
            defaultValue={(formData as any)[KEYS.BUILDING]}
            onChange={(value) =>
              delayedCallback(() => onChange(KEYS.BUILDING, value))
            }
            error={extractErrorByKey(KEYS.BUILDING, fieldErrors)}
            maxLength={AFNOR_MAX_LENGTH}
          />
        </div>
      </div>

      <div className="form-flex-container">
        <div className="form-flex-item form-flex-item--flex-1">
          <Input
            label="N° et libellé de voie"
            placeholder="Saisissez votre n° et libellé de voie"
            defaultValue={(formData as any)[KEYS.ADDRESS]}
            onChange={(value) =>
              delayedCallback(() => onChange(KEYS.ADDRESS, value))
            }
            error={extractErrorByKey(KEYS.ADDRESS, fieldErrors)}
            required
            maxLength={AFNOR_MAX_LENGTH}
          />
        </div>
        <div className="form-flex-item form-flex-item--flex-1">
          <Input
            label="Lieu-dit ou Boîte Postale"
            placeholder="Saisissez votre lieu-dit ou boîte postale"
            defaultValue={(formData as any)[KEYS.LOCALITY]}
            onChange={(value) =>
              delayedCallback(() => onChange(KEYS.LOCALITY, value))
            }
            fullwidth
            error={extractErrorByKey(KEYS.LOCALITY, fieldErrors)}
            maxLength={AFNOR_MAX_LENGTH}
          />
        </div>
      </div>
    </>
  );
};
