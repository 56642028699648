import moment from "moment";

export type FieldDefinition = {
  key: string;
  type:
    | "string"
    | "email"
    | "tel"
    | "postalCode"
    | "postalCodeNotFrance"
    | "birthDate"
    | "number";
  label?: string;
  isRequired: boolean;
  minLength?: number;
  maxLength?: number;
  length?: number;
};

export type CheckFieldError = {
  key: string;
  label?: string;
  reason: Reasons;
  comment?: string;
  other?: string | number;
};

export enum Reasons {
  None = -1,
  NotProvided = 0,
  MissingRequiredField = 1,
  TypeMistmatch = 2,
  MinLength = 3,
  Length = 4,
  MaxLength = 5,
}

export const checkFields = (
  data: any,
  fieldsDefinitions: FieldDefinition[]
): CheckFieldError[] => {
  const errors: CheckFieldError[] = [];

  for (const field of fieldsDefinitions) {
    const dataAsAny = (data as any) || {};
    const value = dataAsAny[field.key];

    if (field.key === "city" && value === "Selectionner") {
      errors.push({
        key: field.key,
        label: "Ville",
        reason: Reasons.MissingRequiredField,
        comment: `Le champ est obligatoire`,
      });
      continue;
    }

    if (!isRequired(field, value)) {
      errors.push({
        key: field.key,
        label: field.label,
        reason: Reasons.MissingRequiredField,
        comment: `Le champ est obligatoire`,
      });
      continue;
    }

    if (!typeIsValid(value, field)) {
      errors.push({
        key: field.key,
        label: field.label,
        reason: Reasons.TypeMistmatch,
        comment: `Le champ n'est pas valide`,
      });
      continue;
    }

    if (!minLengthIsValid(field, value)) {
      errors.push({
        key: field.key,
        label: field.label,
        reason: Reasons.MinLength,
        comment: `Le champ doit être composé de ${field.minLength} caractères minimum`,
      });
      continue;
    }

    if (!lengthIsValid(field, value)) {
      errors.push({
        key: field.key,
        label: field.label,
        reason: Reasons.Length,
        comment: `Le champ doit être composé de ${field.length} caractères`,
      });
      continue;
    }

    if (!maxLengthIsValid(field, value)) {
      errors.push({
        key: field.key,
        label: field.label,
        reason: Reasons.MaxLength,
        comment: `Le champ doit être composé de ${field.maxLength} caractères maximum`,
      });
      continue;
    }
  }

  return errors;
};

export const extractErrorByKey = (
  key: string,
  errors: CheckFieldError[]
): CheckFieldError | undefined => errors.find((error) => error.key === key);

const isRequired = (field: FieldDefinition, value: any) => {
  return !field.isRequired || (field.isRequired && value);
};

const typeIsValid = (value: any, field: FieldDefinition): boolean => {
  if (valueIsNullButNotRequired(value, field)) {
    return true;
  }

  switch (field.type) {
    case "tel":
      return phoneIsValid(value);
    case "email":
      return emailIsValid(value);
    case "postalCode":
      return postalCodeIsValid(value);
    case "postalCodeNotFrance":
      return true;
    case "birthDate":
      return birthDateIsValid(value);
    default:
      return typeof value === field.type;
  }
};

const minLengthIsValid = (field: FieldDefinition, value: any) => {
  if (valueIsNullButNotRequired(value, field)) {
    return true;
  }

  return !field.minLength || value.length >= field.minLength;
};

const maxLengthIsValid = (field: FieldDefinition, value: any) => {
  if (valueIsNullButNotRequired(value, field)) {
    return true;
  }

  return !field.maxLength || value.length <= field.maxLength;
};

const lengthIsValid = (field: FieldDefinition, value: any) => {
  if (valueIsNullButNotRequired(value, field)) {
    return true;
  }

  return !field.length || value.length === field.length;
};

const emailIsValid = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const phoneIsValid = (phone: string) => {
  const re = /(([+0][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[\s]?[(]?[0-9]{1,3}[)]?([\s]?[0-9]{3})([\s]?[0-9]{3,4})/;
  const reNewCall = /^(\+687\s?[2-9]\d{5}|[2-9]\d{5})$/;
  const phoneWithoutSpaces = phone.replace(/\s/g, "").replaceAll(/\./g, "");

  return (
    re.test(String(phoneWithoutSpaces)) ||
    // https://ffbadminton.myjetbrains.com/youtrack/agiles/120-24/121-32?issue=MFFBD-158
    reNewCall.test(String(phoneWithoutSpaces))
  );
};

const postalCodeIsValid = (postalCode: string) => {
  const re = /^\d{5}$/;
  return re.test(String(postalCode));
};

const valueIsNullButNotRequired = (value: any, field: FieldDefinition) => {
  return !value && !field.isRequired;
};

const birthDateIsValid = (dateAsString: string): boolean => {
  let date = moment(dateAsString, "DD/MM/YYYY");

  if (date.format("DD/MM/YYYY") === "Invalid date") {
    date = moment(dateAsString);
  }

  const difference = moment().diff(date, "years");
  return difference < 100 && difference >= 3;
};
