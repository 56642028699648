import React from "react";

import Dot from "./components/Dot";
import Title from "./components/Title";
import { isActive, isCurrentIndex } from "./wizard.utils";

import "./index.scss";

export type WizardElement = {
  title: string;
  content: React.ReactNode;
};

type Props = {
  steps: WizardElement[];
  index: number;
  onIndexChange?: (index: number) => void;
  lockedSteps?: number[];
};

const Wizard: React.FC<Props> = (props) => {
  const { steps = [], index = 0, onIndexChange, lockedSteps = [] } = props;

  const dots = steps.map((_, key) => (
    <Dot
      key={key}
      active={isActive(key, index)}
      isLast={key + 1 === steps.length}
      onClick={() => onIndexChange && onIndexChange(key)}
      canClick={!lockedSteps.includes(key) && key <= index}
    />
  ));

  const titleToDisplay = steps.map(({ title }, key) => (
    <Title
      key={key}
      active={isCurrentIndex(key, index)}
      onClick={() => onIndexChange && onIndexChange(key)}
      canClick={!lockedSteps.includes(key) && key <= index}
    >
      {title}
    </Title>
  ));

  return (
    <section id="wizard" role="navigation">
      <div className="wizard-container">{dots}</div>
      <div className="wizard-container margin">{titleToDisplay}</div>

      {index < steps.length && steps[index].content}
    </section>
  );
};

export default Wizard;
