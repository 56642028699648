import {
  Step05Data,
  RecapProof,
  CustomField,
} from "pages/LicenceRenewal/renewal.interfaces";
import { CheckFieldError, Reasons } from "./renewal.utils";
import { PricingProof } from "services/poona/poona.interfaces";

export const renewalStep05IsValid = (
  selectedPriceId: number,
  data?: Step05Data,
  rawCustomFields?: CustomField
): CheckFieldError[] => {
  const errors: CheckFieldError[] = [];

  if (!data || !data.recap) {
    return [
      {
        key: "",
        reason: Reasons.NotProvided,
        comment: "Les données sont incomplètes",
      },
    ];
  }

  const proofsAreValid = checkPricing(data);
  if (proofsAreValid.length) {
    proofsAreValid.forEach((proof) =>
      errors.push({
        key: "recap",
        reason: Reasons.MissingRequiredField,
        comment: `Le fichier suivant est obligatoire : ${proof.name}`,
      })
    );
  }

  if (!data.recap.price) {
    errors.push({
      key: "modalites",
      reason: Reasons.NotProvided,
      comment: "Veuillez choisir un tarif",
    });
  }

  const questionsAreValid = checkRequiredQuestions(
    data?.customFields || {},
    selectedPriceId,
    rawCustomFields
  );
  if (!questionsAreValid) {
    errors.push({
      key: "customFields",
      reason: Reasons.NotProvided,
      comment: "Veuillez répondre aux questions",
    });
  }

  return errors;
};

const checkPricing = (data: Step05Data): PricingProof[] => {
  const missingProofsForDiscounts = checkRequiredDiscountProofs(data);
  const missingProofsForOptions = checkRequiredOptionProofs(data);
  return [...missingProofsForDiscounts, ...missingProofsForOptions];
};

const checkRequiredDiscountProofs = (data: Step05Data): PricingProof[] => {
  const {
    recap: { price, selectedDiscountIds = [], files = [] },
  } = data;

  if (!price) {
    return [];
  }

  const requiredProofs: PricingProof[][] = price.discounts
    .map((discount) => ({
      id: discount.discountId,
      proofs: discount.proofs.filter((aProof) => aProof.required),
    }))
    .filter(
      (discount) =>
        selectedDiscountIds.includes(discount.id) && discount.proofs.length
    )
    .map((option) => option.proofs);

  return findMissingRequiredProofs(requiredProofs, files);
};

const checkRequiredOptionProofs = (data: Step05Data): PricingProof[] => {
  const {
    recap: { price, selectedOptionsIds = [], files = [] },
  } = data;

  if (!price) {
    return [];
  }

  const requiredProofs: any = price.options
    .map((option) => ({
      id: option.optionId,
      proofs: option.proofs.filter((aProof) => aProof.required),
    }))
    .filter(
      (option) => selectedOptionsIds.includes(option.id) && option.proofs.length
    )
    .map((option) => option.proofs);

  return findMissingRequiredProofs(requiredProofs, files);
};

const findMissingRequiredProofs = (
  requiredProofs: PricingProof[][],
  files: RecapProof[]
): PricingProof[] => {
  const flattenRequiredProofs = [].concat.apply([], requiredProofs as any);
  const providedProofIds = files
    .filter((file) => file.file)
    .map((file) => file.proofId);

  const notValidProofs = flattenRequiredProofs.reduce(
    (prev: PricingProof[], curr: PricingProof) =>
      providedProofIds.includes(curr.id) ? prev : [...prev, curr],
    []
  );

  return notValidProofs;
};

const checkRequiredQuestions = (
  values: Record<string, any>,
  selectedPriceId: number,
  rawCustomFields?: CustomField
): boolean => {
  if (!rawCustomFields) {
    return true;
  }

  const relatedQuestions = (
    (rawCustomFields.fields as any) || []
  ).filter((p: any) => p?.visibility?.price?.includes(selectedPriceId));

  const isValid = relatedQuestions
    .map((p: any) => {
      if (!p.required) {
        // Assuming it's valid.
        return true;
      }

      return values[p.key] !== null && values[p.key] !== undefined;
    })
    .every((p: boolean) => p);

  return isValid;
};
