import React from "react";

import { Input, InputRadioGroup, Select } from "components/form";
import {
  extractErrorByKey,
  CheckFieldError,
} from "utils/renewal/renewal.utils";
import { CONTACT_ROLES } from "core/enums/contacts";
import { ContactsData } from "pages/LicenceRenewal/renewal.interfaces";
import { delayedCallback } from "utils/dom/dom.utils";
import PhoneInput from "components/form/PhoneInput";
import { ContactType } from "services/poona/poona.interfaces";

type Props = {
  formData: ContactsData;
  fieldErrors: CheckFieldError[];
  onChange: (index: number, key: string, value: any) => void;
  index: number;
  contactTypes: ContactType[];
};

const CONTACT_FORM_KEYS = {
  FIRSTNAME: "firstName",
  LASTNAME: "lastName",
  CONTACT_TYPE_ID: "contactTypeId",
  EMAIL: "email",
  PHONE: "phone",
  IS_EMERGENCY: "isEmergency",
  IS_LEGAL_GUARDIAN: "isLegalGuardian",
  IS_ACCOMPANYING: "isAccompanying",
};

const ContactForm: React.FC<Props> = (props) => {
  const {
    formData = {},
    fieldErrors = [],
    onChange,
    index,
    contactTypes,
  } = props;
  const formattedContactTypes = contactTypes.map(({ name, typeId }) => ({
    key: typeId,
    value: name,
  }));

  const handleOnRoleChange = (values: string[]) => {
    onChange(index, CONTACT_FORM_KEYS.IS_EMERGENCY, values.includes("1"));
    onChange(index, CONTACT_FORM_KEYS.IS_LEGAL_GUARDIAN, values.includes("2"));
    onChange(index, CONTACT_FORM_KEYS.IS_ACCOMPANYING, values.includes("3"));
  };

  const checkedRoles = [
    formData.isEmergency ? "1" : undefined,
    formData.isLegalGuardian ? "2" : undefined,
    formData.isAccompanying ? "3" : undefined,
  ].filter((item) => item !== undefined) as string[];

  return (
    <>
      <InputRadioGroup
        label="Rôle(s) du contact"
        items={CONTACT_ROLES}
        keyOfCheckedItem={checkedRoles}
        onChange={handleOnRoleChange}
        error={undefined}
        disabled={false}
        required
        isMulti
      />

      <div className="form-flex-container">
        <div className="form-flex-item">
          <Select
            label="Type du Contact"
            items={formattedContactTypes}
            defaultValue={(formData as any)[CONTACT_FORM_KEYS.CONTACT_TYPE_ID]}
            onChange={(value) =>
              onChange(index, CONTACT_FORM_KEYS.CONTACT_TYPE_ID, value)
            }
            error={extractErrorByKey(
              index + CONTACT_FORM_KEYS.CONTACT_TYPE_ID,
              fieldErrors
            )}
            firstIsBlank
            required
          />
        </div>
      </div>

      <div className="form-flex-container">
        <div className="form-flex-item">
          <Input
            label="Nom"
            placeholder="Saisissez le nom"
            defaultValue={(formData as any)[CONTACT_FORM_KEYS.LASTNAME]}
            onChange={(value) =>
              onChange(index, CONTACT_FORM_KEYS.LASTNAME, value)
            }
            error={extractErrorByKey(
              index + CONTACT_FORM_KEYS.LASTNAME,
              fieldErrors
            )}
            required
          />
        </div>

        <div className="form-flex-item">
          <Input
            label="Prénom"
            placeholder="Saisissez le prénom"
            defaultValue={(formData as any)[CONTACT_FORM_KEYS.FIRSTNAME]}
            onChange={(value) =>
              onChange(index, CONTACT_FORM_KEYS.FIRSTNAME, value)
            }
            error={extractErrorByKey(
              index + CONTACT_FORM_KEYS.FIRSTNAME,
              fieldErrors
            )}
            required
          />
        </div>
      </div>

      <div className="form-flex-container">
        <div className="form-flex-item">
          <Input
            label="E-mail"
            placeholder="Saisissez l'e-mail"
            defaultValue={(formData as any)[CONTACT_FORM_KEYS.EMAIL]}
            onChange={(value) =>
              onChange(index, CONTACT_FORM_KEYS.EMAIL, value)
            }
            error={extractErrorByKey(
              index + CONTACT_FORM_KEYS.EMAIL,
              fieldErrors
            )}
            required
          />
        </div>

        <div className="form-flex-item">
          <PhoneInput
            label="Téléphone"
            onChange={(value) => {
              delayedCallback(() => {
                onChange(index, CONTACT_FORM_KEYS.PHONE, value);
              });
            }}
            defaultValue={(formData as any)[CONTACT_FORM_KEYS.PHONE]}
            error={extractErrorByKey(
              index + CONTACT_FORM_KEYS.PHONE,
              fieldErrors
            )}
            required
          />
        </div>
      </div>
    </>
  );
};

export default ContactForm;
