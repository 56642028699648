import { legacy_createStore as createStore } from "redux";
import mainReducer from "./reducer/main.reducer";

const store = createStore(
  mainReducer,
  process.env.REACT_APP_NODE_ENV !== "production" &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
