import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import "./styles.scss";

class SocialPictogram extends Component {
  render() {
    return (
      <ul className='social-list-pictogram'>
        <li className='social-list-item'>
          <a href='https://www.facebook.com/FederationFrancaiseBadminton/' target='_blank' rel='noopener noreferrer' title='Voir la page Facebook'>
            <FontAwesomeIcon icon={faFacebookF as IconProp} />
          </a>
        </li>
        <li className='social-list-item'>
          <a href='https://twitter.com/ffbad' target='_blank' rel='noopener noreferrer' title='Voir la page Twitter'>
            <FontAwesomeIcon icon={faTwitter as IconProp} />
          </a>
        </li>
        <li className='social-list-item'>
          <a href='https://www.instagram.com/ffbad/?hl=fr' target='_blank' rel='noopener noreferrer' title='Voir la page Instagram'>
            <FontAwesomeIcon icon={faInstagram as IconProp} />
          </a>
        </li>
        <li className='social-list-item'>
          <a href='https://www.dailymotion.com/FFBAdminton' target='_blank' rel='noopener noreferrer' title='Voir la page youtube'>
            <FontAwesomeIcon icon={faPlayCircle as IconProp} />
          </a>
        </li>
      </ul>
    );
  }
}

export default SocialPictogram;
