import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { IType } from "core/interfaces.config";

import "./styles.scss";

type Props = {
  children: any;
  onClick: (e: any) => void;
  type?: IType;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  title?: string;
};

const Button: React.FC<Props> = (props) => {
  const {
    children,
    onClick,
    type = "primary",
    loading = false,
    disabled = false,
    className,
    title,
  } = props;
  const canClick = !disabled && !loading;

  return (
    <button
      className={`${className || ""} button button-${
        disabled ? "disabled" : type
      }${loading ? " button-loading" : ""}`.trim()}
      onClick={(e) => canClick && onClick(e)}
      disabled={disabled}
      title={title}
    >
      {loading && <FontAwesomeIcon icon={faSpinner as any} spin />}
      {children}
    </button>
  );
};

export default Button;
