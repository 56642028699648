import { RenewalFullData } from "utils/renewal/default.data";
import {
  InformationData,
  CoordsData,
  RecapProof,
  Step04Data,
  Step02Data,
  Step03Data,
  CustomFieldAsArray,
  CustomField,
  CustomFieldItem,
} from "pages/LicenceRenewal/renewal.interfaces";

export enum PhoneTypes {
  PhoneMobile = 2,
  PhonePerso = 5,
  Responsable1 = 4,
  Responsable2 = 7,
}

export enum EmailTypes {
  Perso = 3,
  Responsable1 = 8,
  Responsable2 = 9,
}

export const cast = (data: RenewalFullData): ExpectedRenewalDataForPoona => {
  const { step1, step2, step3, step4, step5, step6 } = data as RenewalFullData;

  const output: ExpectedRenewalDataForPoona = {
    person: extractPerson(step1.informations),
    priceId: step5.recap.price && step5.recap.price.priceId,
    postal_addresses: extractPostalAddresses(step1.coords),
    contacts: extractContacts(step2),
    phones: [...extractPhones(step1.coords)],
    emails: [extractEmail(step1.coords)],
    schedulerId:
      (step6.modalites.modaliteId && +step6.modalites.modaliteId) || -1,
    options: extractOptionsOrDiscounts(step5.recap.selectedOptionsIds),
    discounts: extractOptionsOrDiscounts(step5.recap.selectedDiscountIds),
    consents: extractConsents(step4, step3),
    medicalFile: {
      file_key: step3.certificate.newCertificateFile,
      date: step3.certificate.certificateDate,
    },
    proofs: extractProofs(step5.recap.files),
    customFields: extractCustomFields(step1.customFields),
    customFieldPrices: extractCustomFields(step5.customFields),
    desiredPaymentMethodId: step6.payment_methods.paymentMethodId,
  };

  return output;
};

const extractPerson = (data: InformationData): ExpectedPerson => {
  return {
    licence: data.licence,
    lastName: data.lastName,
    firstName: data.firstName,
    maidenName: data.maidenName,
    sex: data.gender,
    birthday: cleanMaskedInputValue(data.birthDate),
    birthPlace: data.birthPlace,
    birthPlaceDepartment: cleanMaskedInputValue(
      data.birthPlaceDepartment || ""
    ),
    nativeCountryId: Number(data.nativeCountryId),
    nationalityId: Number(data.nationalityId),
    photoKey: data.newAvatarFile && data.newAvatarFile.poonaFilename,
    birthCityInsee: data.birthCityInsee,
  };
};

const extractPostalAddresses = (data: CoordsData): ExpectedAddress[] => {
  return [
    {
      floor: data.floor,
      building: data.building,
      address: data.address,
      locality: data.locality,
      postalCode: cleanMaskedInputValue(data.postalCode),
      city: data.city,
      cedex: undefined,
      countryId: Number(data.countryId),
      isContact: "1",
    },
  ];
};

const extractPhones = (data: CoordsData): ExpectedPhone[] => {
  const output: ExpectedPhone[] = [];

  if (data.mobilePhone) {
    output.push({
      value: cleanMaskedInputValue(data.mobilePhone),
      isHidden: 0,
      isContact: 1,
      typeId: PhoneTypes.PhoneMobile,
    });
  }

  if (data.fixPhone) {
    output.push({
      value: cleanMaskedInputValue(data.fixPhone),
      isHidden: 0,
      isContact: 0,
      typeId: PhoneTypes.PhonePerso,
    });
  }

  return output;
};

const extractEmail = (data: CoordsData): ExpectedEmail => ({
  value: data.email,
  isHidden: 0,
  isContact: 1,
  typeId: EmailTypes.Perso,
});

const extractOptionsOrDiscounts = (ids: string[] = []) => {
  return ids.map((id) => ({ id: +id }));
};

const extractProofs = (data: RecapProof[]): ExpectedProof[] => {
  return data.map((proof) => ({
    id: proof.proofId ? Number(proof.proofId) : -1,
    file_key: proof.file || "",
  }));
};

const extractConsents = (
  step04data: Step04Data,
  step03data: Step03Data
): any => {
  const step4Result = Object.keys(step04data.acceptation).map((id: string) => ({
    id: Number(id),
    checked: step04data.acceptation[id],
  }));

  const step3Result = Object.keys(step03data.assurance).map((id: string) => ({
    id: Number(id),
    checked: step03data.assurance[id],
  }));

  const step3PlayerResult = Object.keys(step03data.player).map(
    (id: string) => ({
      id: Number(id),
      checked: step03data.player[id],
    })
  );

  return [...step4Result, ...step3Result, ...step3PlayerResult];
};

const extractCustomFields = (
  data: Record<string, any> = {}
): ExpectedCustomFields[] => {
  return Object.keys(data).map((key) => ({
    key,
    value: data[key],
  }));
};

const extractContacts = (data: Step02Data): ExpectedContacts[] => {
  const output: ExpectedContacts[] = data.contacts.map((item) => ({
    contactTypeId: +(item.contactTypeId || "0"),
    contactAssoId: item.contactAssoId || "",
    isEmergency: item.isEmergency || false,
    isLegalGuardian: item.isLegalGuardian || false,
    isAccompanying: item.isAccompanying || false,
    contact: {
      firstname: item.firstName || "",
      lastname: item.lastName || "",
      email: item.email || "",
      phone: item.phone || "",
    },
    address: {},
  }));

  return output;
};

export type ExpectedRenewalDataForPoona = {
  personId?: number;
  instanceId?: number;
  licence?: string;
  person: ExpectedPerson;
  priceId?: number;
  postal_addresses: ExpectedAddress[];
  phones: ExpectedPhone[];
  emails: ExpectedEmail[];
  schedulerId: number;
  contacts: ExpectedContacts[];
  consents: {
    id: number;
    checked: boolean;
  }[];
  options: {
    id: number;
  }[];
  discounts: {
    id: number;
  }[];
  proofs: ExpectedProof[];
  medicalFile: {
    file_key?: string;
    date?: string;
  };
  customFields: ExpectedCustomFields[];
  customFieldPrices?: ExpectedCustomFields[];
  desiredPaymentMethodId: number;
};

type ExpectedPerson = {
  licence?: string;
  lastName?: string;
  firstName?: string;
  maidenName?: string;
  sex?: string;
  birthday?: string;
  birthPlace?: string;
  birthPlaceDepartment?: string;
  nativeCountryId?: number;
  nationalityId?: number;
  photoKey?: string;
  birthCityInsee?: string | null;
};

type ExpectedAddress = {
  floor?: string;
  building?: string;
  address?: string;
  locality?: string;
  postalCode?: string;
  city?: string;
  cedex?: string;
  countryId?: number;
  isContact?: string;
};

type ExpectedPhone = {
  value?: string;
  isHidden: number;
  isContact: number;
  typeId: number;
};

type ExpectedEmail = {
  value?: string;
  isHidden: number;
  isContact: number;
  typeId: EmailTypes;
};

type ExpectedProof = {
  id: number;
  file_key: string;
};

type ExpectedCustomFields = {
  key: string;
  value: any;
};

type ExpectedContacts = {
  contactTypeId: number;
  contactAssoId: string;
  isEmergency: boolean;
  isLegalGuardian: boolean;
  isAccompanying: boolean;
  contact: {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
  };
  address: {};
};

const cleanMaskedInputValue = (
  value: string | undefined
): string | undefined => {
  return value && value.toString().replace(/_/g, "");
};

export const getCustomFieldsAsArray = (
  rawCustomFields?: CustomField,
  priceId?: number
): CustomFieldAsArray | undefined => {
  if (!rawCustomFields) {
    return;
  }

  const keys = Object.keys(rawCustomFields.fields);
  let fields: CustomFieldItem[] = keys.map((key) => {
    const item = rawCustomFields.fields[key];

    return {
      key: item.key,
      label: item.label,
      description: item.description,
      type: item.type,
      required: item.required,
      selectItems: item.selectItems,
      visibility: item.visibility,
      fileMimeTypeAllowed: item.fileMimeTypeAllowed,
      fileSizeMaxAllowed: item.fileSizeMaxAllowed,
    };
  });

  if (priceId) {
    fields = fields.filter((field) =>
      field?.visibility?.price?.includes(priceId)
    );
  }

  return { title: rawCustomFields.title, fields };
};
