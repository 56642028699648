import { useEffect, useState } from "react";
import { useToggle } from "hooks-me";

import PoonaService from "services/poona/poona.service";
import { CustomSeasonLicence } from "services/poona/poona.interfaces";
import { PRESTATION_TYPES } from "pages/LicenceRenewal/renewal.interfaces";
import { useInstance } from "hooks";

const service = new PoonaService();

const useFetchPrestations = (): {
  loading: boolean;
  prestations: CustomSeasonLicence[];
  error: boolean;
} => {
  const instance = useInstance();
  const [loading, setLoading] = useToggle(true);
  const [error, setError] = useToggle(false);
  const [prestations, setPrestations] = useState<CustomSeasonLicence[]>([]);

  useEffect(() => {
    if (!instance) {
      return;
    }

    const { promise, cancel } = service.licenceTypes(instance.instanceId);

    promise
      .then((response) => {
        const licences = response.map((row) => {
          const output: CustomSeasonLicence = {
            id: (row.licenceType?.licenceTypeId ||
              row.prestation.prestationId) as PRESTATION_TYPES,
            label: row.licenceType?.label || row.prestation.label,
            description:
              row.licenceType?.description || row.prestation.description,
            type: row.licenceType ? "licence" : "prestation",
            seasonId: row.season.seasonId,
            isExternal:
              !row.licenceType?.licenceTypeId && !!row.prestation.prestationId,
            prestationId: row.prestation.prestationId,
          };
          return output;
        });

        setPrestations(licences);
        setLoading(false);
      })
      .catch(() => setError(true))
      .finally(() => {
        setLoading(false);
      });

    return () => cancel();
    // eslint-disable-next-line
  }, [instance]);

  return { loading, prestations, error };
};

export default useFetchPrestations;
