import { KeyValue } from "pages/LicenceRenewal/renewal.interfaces";

export const CONTACT_ROLES: KeyValue[] = [
  {
    key: "1",
    value: "Contact d'urgence",
  },
  {
    key: "2",
    value: "Responsable Légal",
  },
  {
    key: "3",
    value: "Accompagnant",
  },
];
