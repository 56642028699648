import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import { KeyCodes } from "core/enums/keycodes";
import { buildClassName } from "utils/dom/dom.utils";

import "./index.scss";

type Props = {
  title: string;
  subtitle?: string;
  collapsible?: boolean;
  isOpen?: boolean;
  onToggle?: (open: boolean) => void;
};

const CardHeader: React.FC<Props> = (props) => {
  const {
    title,
    subtitle,
    collapsible = false,
    isOpen = false,
    onToggle,
  } = props;
  const className = buildClassName("step-title", [
    [collapsible, "collapsible"],
  ]);

  const handleToggle = () => {
    if (!onToggle) {
      return;
    }
    onToggle(!isOpen);
  };

  return (
    <div
      className={className}
      onClick={handleToggle}
      title={collapsible ? "Cliquez pour afficher/masquer" : ""}
      role={collapsible ? "button" : undefined}
      tabIndex={collapsible ? 0 : undefined}
      onKeyDown={(e) => {
        if (e.keyCode === KeyCodes.ENTER || e.keyCode === KeyCodes.SPACE) {
          e.preventDefault();
          handleToggle();
        }
      }}
    >
      <h4>
        {title}
        {subtitle && <small>{subtitle}</small>}
      </h4>

      {collapsible && (
        <FontAwesomeIcon
          className="collapsible-icon"
          icon={(isOpen ? faChevronUp : faChevronDown) as any}
        />
      )}
    </div>
  );
};

export default CardHeader;
