const CryptoJS = require("crypto-js");

const { REACT_APP_TOKEN_SALT } = process.env;

class CryptoService {
  // constructor() {
  // TODO: debug only. console.log("Encrypted licence:", this.encrypt("07105102"));
  // }

  encrypt = (content: string | undefined): string | undefined => {
    const cypher = CryptoJS.AES.encrypt(
      content,
      REACT_APP_TOKEN_SALT
    ).toString();
    return this.urlEncode(cypher);
  };

  decrypt = (cypher: string | undefined): string => {
    if (!cypher) {
      return "";
    }

    cypher = this.urlDecode(cypher);
    const bytes = CryptoJS.AES.decrypt(cypher, REACT_APP_TOKEN_SALT);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  private urlDecode = (encrypted: string): string => {
    return encrypted.replace(/_sl_/g, "/");
  };

  private urlEncode = (encrypted: string): string => {
    return encrypted.replace(/([/]+)/g, "_sl_");
  };
}

export default CryptoService;
