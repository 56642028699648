import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { InputRadioGroup, Input, Select } from "components/form";
import {
  InformationData,
  KeyValue,
  StepComponent,
} from "pages/LicenceRenewal/renewal.interfaces";
import {
  CheckFieldError,
  extractErrorByKey,
} from "utils/renewal/renewal.utils";
import { delayedCallback, getPropsForInputDate } from "utils/dom/dom.utils";
import { CIVILITES, CIVILITE_INDEXES } from "core/enums/civilites";
import PoonaService from "services/poona/poona.service";
import { DISPLAY_ERROR } from "core/redux/reducer/main.reducer";
import useRegistrationKey from "hooks/useRegistrationKey";
import Avatar from "../Avatar";
import { Card, Autocomplete } from "components";
import { isMinor } from "utils/string/string.utils";
import {
  COUNTRY_FRANCE_ID,
  DEFAULT_DEPARTMENT,
  KEYS,
} from "./informations.config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

type Props = StepComponent & {
  defaultData?: InformationData;
  countries?: KeyValue[];
  nationalities?: KeyValue[];
  fieldErrors: CheckFieldError[];
};

const Informations: React.FC<Props> = (props) => {
  const {
    onChange,
    defaultData,
    countries = [],
    nationalities = [],
    fieldErrors = [],
  } = props;
  const dispatch = useDispatch();
  const poonaService = useMemo(() => new PoonaService(), []);
  const [formData, setFormData] = useState<InformationData>({});
  const isFemale = useMemo(() => formData.gender === CIVILITE_INDEXES.Female, [
    formData,
  ]);
  const { key } = useParams<{ key?: string }>();
  const registrationKey = useRegistrationKey();
  const isRenewal = !!key;
  const inputIsLocked = isRenewal;
  const defaultImageForAvatar =
    formData.newAvatarFile && formData.newAvatarFile.file
      ? URL.createObjectURL(formData.newAvatarFile.file)
      : formData.avatarUrl;

  useEffect(() => {
    if (!defaultData) {
      return;
    }
    setFormData(defaultData);
  }, [defaultData]);

  useEffect(() => {
    if (!countries || !countries.length) {
      return;
    }

    const country = countries.find(
      (aCountry) =>
        aCountry.key?.split("_")[0] === formData.nationalityId?.toString()
    );

    if (country) {
      handleOnChange(KEYS.NATIONALITY_ID, country.key);
    }
    // eslint-disable-next-line
  }, [countries]);

  const handleOnChange = (key: string, value: any) => {
    const newFormData: any = { ...formData };
    newFormData[key] = value;

    const isFemale = newFormData.gender === "2";

    // 3th bullet: https://ffbadminton.myjetbrains.com/youtrack/issue/MFFBD-57/Adh%C3%A9sion-Msg-derreur-et-Usecase-qui-marche-pas
    // If we update BIRTHDATE or MAIDENNAME and we are MINOR, then update LASTNAME=MAIDENNAME.
    if (key === KEYS.BIRTHDATE && isMinor(value)) {
      if (isFemale) {
        newFormData[KEYS.LASTNAME] = newFormData[KEYS.MAIDENNAME];
      }
    } else if (
      key === KEYS.MAIDENNAME &&
      isMinor(newFormData[KEYS.BIRTHDATE])
    ) {
      newFormData[KEYS.LASTNAME] = newFormData[KEYS.MAIDENNAME];
    }

    onChange(newFormData);
  };

  const handleOnChangeMultiple = useCallback(
    (data: { key: string; value: number | null }[]) => {
      const newFormData: any = { ...formData };
      for (const temp of data) {
        newFormData[temp.key] = temp.value;
      }

      onChange(newFormData);
    },
    [formData, onChange]
  );

  const handleOnAvatarChange = (file: any) => {
    poonaService
      .uploadTempFile(registrationKey, "avatar", file)
      .then((response) => {
        handleOnChange(KEYS.NEW_AVATAR_FILE, {
          file,
          poonaFilename: response.key,
        });
      })
      .catch(() => {
        dispatch({
          type: DISPLAY_ERROR,
          payload: {
            message: "Le téléchargement de votre avatar a échoué",
          },
        });
        handleOnChange(KEYS.NEW_AVATAR_FILE, undefined);
      });
  };

  const handleOnAutocompleteChange = useCallback(
    (town: any) => {
      delayedCallback(() => {
        const code = town.code.length === 5 ? town.code : `0${town.code}`;

        handleOnChangeMultiple([
          {
            key: KEYS.BIRTH_PLACE,
            value: `${town.nom} (${town.codeDepartement})`,
          },
          { key: KEYS.BIRTH_CITY_INSEE, value: code },
          { key: KEYS.NATIVE_COUNTRY_ID, value: COUNTRY_FRANCE_ID },
          { key: KEYS.BIRTH_DEPARTMENT, value: town.codeDepartement },
        ]);
      });
    },
    [handleOnChangeMultiple]
  );

  const maidenNameIsHidden = formData.gender !== CIVILITE_INDEXES.Female;
  const nameInputs = [
    {
      label: isFemale ? "Nom d'usage" : "Nom",
      key: KEYS.LASTNAME,
      hidden: isFemale && !maidenNameIsHidden && isMinor(formData.birthDate),
      placeholder: `Saisissez votre ${isFemale ? "nom d'usage" : "nom"}`,
      disabled: isFemale ? false : inputIsLocked,
    },
    {
      label: "Nom de naissance",
      key: KEYS.MAIDENNAME,
      hidden: maidenNameIsHidden,
      placeholder: "Saisissez votre nom de naissance",
      disabled: isFemale ? false : inputIsLocked,
    },
    {
      label: "Prénom",
      key: KEYS.FIRSTNAME,
      placeholder: "Saisissez votre prénom",
      disabled: inputIsLocked,
    },
  ];

  return (
    <Card
      title="Vos informations"
      subtitle="Vos informations de licencié"
      collapsible
      isOpen
    >
      <Avatar
        defaultImage={defaultImageForAvatar}
        onChange={handleOnAvatarChange}
        licence={formData.licence}
      />

      <div className="information-licence-container">
        <div className="information-licence-container__left">
          {!isRenewal && (
            <Input
              label="Votre numéro de licence (si vous avez déjà été licencié)"
              placeholder="Saisissez votre licence (facultatif)"
              defaultValue={(formData as any)[KEYS.LICENCE]}
              onChange={(value) =>
                delayedCallback(() => handleOnChange(KEYS.LICENCE, value))
              }
              error={extractErrorByKey(KEYS.LICENCE, fieldErrors)}
            />
          )}

          <InputRadioGroup
            label="Civilité"
            items={CIVILITES.map((civilite) => ({
              key: civilite.id,
              value: civilite.sexe,
            }))}
            keyOfCheckedItem={formData.gender && formData.gender}
            onChange={(gender) =>
              inputIsLocked ? () => {} : handleOnChange(KEYS.GENDER, gender[0])
            }
            error={
              inputIsLocked
                ? undefined
                : extractErrorByKey(KEYS.GENDER, fieldErrors)
            }
            disabled={inputIsLocked}
          />
        </div>
        {!isRenewal && (
          <div className="information-licence-container__right">
            <div className="information-licence-container__title">
              Le saviez-vous ?
            </div>
            Si vous étiez licencié la saison précédente, connectez-vous via
            votre&nbsp;
            <a href="http://www.myffbad.fr" rel="noopener noreferrer">
              Espace Licencié
            </a>
            , ce formulaire sera alors prérempli. Certains clubs ont une
            politique de fidélisation avec des tarifs avantageux.
          </div>
        )}
      </div>

      {/* Firstname & lastname > */}
      <div className="form-flex-container">
        {nameInputs
          .filter((item) => !item.hidden)
          .map((item: any) => (
            <div key={item.key} className="form-flex-item">
              <Input
                id={item.key}
                label={item.label}
                placeholder={item.placeholder}
                defaultValue={(formData as any)[item.key]}
                onChange={(value) => {
                  if (item.key !== KEYS.MAIDENNAME && inputIsLocked) {
                    return;
                  }

                  delayedCallback(() => handleOnChange(item.key, value));
                }}
                error={extractErrorByKey(item.key, fieldErrors)}
                disabled={item.disabled}
                required
              />
            </div>
          ))}
      </div>
      {/* < Firstname & lastname */}

      <hr />

      {/* Birth > */}
      <div className="form-flex-container">
        <div className="form-flex-item">
          <Input
            label="Né(e) le"
            placeholder="Saisissez votre date de naissance"
            defaultValue={(formData as any)[KEYS.BIRTHDATE]}
            onChange={(value) =>
              delayedCallback(() => handleOnChange(KEYS.BIRTHDATE, value))
            }
            error={extractErrorByKey(KEYS.BIRTHDATE, fieldErrors)}
            disabled={isFemale ? false : inputIsLocked}
            required
            {...getPropsForInputDate()}
          />
        </div>

        <div className="form-flex-item">
          <Select
            label="Nationalité"
            items={nationalities}
            defaultValue={formData?.nationalityId?.toString()}
            onChange={(nationalityId) => {
              handleOnChangeMultiple([
                { key: KEYS.NATIONALITY_ID, value: +nationalityId },
                { key: KEYS.BIRTH_CITY_INSEE, value: null },
                { key: KEYS.BIRTH_DEPARTMENT, value: DEFAULT_DEPARTMENT },
                { key: KEYS.BIRTH_PLACE, value: null },
              ]);
            }}
            error={extractErrorByKey(KEYS.NATIONALITY_ID, fieldErrors)}
            required
          />
        </div>

        <div className="form-flex-item">
          <Select
            label="Pays de naissance"
            items={countries}
            defaultValue={formData?.nativeCountryId?.toString()}
            onChange={(value) => {
              handleOnChange(KEYS.NATIVE_COUNTRY_ID, +value);
            }}
            error={extractErrorByKey(KEYS.NATIVE_COUNTRY_ID, fieldErrors)}
            required
          />
        </div>

        {formData.nativeCountryId !== COUNTRY_FRANCE_ID && (
          <div className="form-flex-item">
            <Input
              label="Lieu de naissance"
              placeholder="Saisissez votre lieu de naissance"
              defaultValue={(formData as any)[KEYS.BIRTH_PLACE]}
              onChange={(value) =>
                delayedCallback(() =>
                  handleOnChangeMultiple([
                    { key: KEYS.BIRTH_PLACE, value },
                    { key: KEYS.BIRTH_CITY_INSEE, value: null },
                    { key: KEYS.BIRTH_DEPARTMENT, value: DEFAULT_DEPARTMENT },
                  ])
                )
              }
              error={extractErrorByKey(KEYS.BIRTH_PLACE, fieldErrors)}
              required
            />
          </div>
        )}

        {formData.nativeCountryId === COUNTRY_FRANCE_ID && (
          <div className="form-flex-item birthplace-autocomplete">
            <Autocomplete
              key={defaultData?.birthPlace}
              label="Lieu de naissance"
              defaultValue={defaultData?.birthPlace}
              onChange={handleOnAutocompleteChange}
              error={extractErrorByKey(KEYS.BIRTH_PLACE, fieldErrors)}
              required
              birthday={formData.birthDate}
            />
            {defaultData?.birthPlace && (
              <FontAwesomeIcon
                icon={faTimes as any}
                title="Vider le lieu de naissance"
                onClick={() => {
                  handleOnChangeMultiple([
                    { key: KEYS.BIRTH_PLACE, value: null },
                    { key: KEYS.BIRTH_CITY_INSEE, value: null },
                    { key: KEYS.NATIVE_COUNTRY_ID, value: COUNTRY_FRANCE_ID },
                    { key: KEYS.BIRTH_DEPARTMENT, value: null },
                  ]);
                }}
                style={{ cursor: "pointer" }}
              />
            )}
          </div>
        )}
      </div>

      {/* < Birth */}
    </Card>
  );
};

export default Informations;
