export const formatOfFilenameMatches = (
  filename: string,
  formats: string[]
): boolean => {
  const extension = extractFileExtWithoutDot(filename);
  return formats.map(toLower).includes(toLower(extension));
};

const extractFileExtWithoutDot = (filename: string): string =>
  filename.split(".").reverse()[0];

const toLower = (str: string): string => str.toLocaleLowerCase();

export const dataURLtoFile = (
  dataurl: string,
  filename: string
): File | undefined => {
  const arr = dataurl.split(",");
  if (!arr || !arr.length) {
    return;
  }
  const mime = arr[0].match(/:(.*?);/);
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: (mime && mime[1]) || "" });
};

export const toBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
};
