import { MainReduxState } from "core/interfaces.config";

export const DISPLAY_ERROR = "display-error";
export const DISPLAY_NOTIFICATION = "display-notification";
export const TOGGLE_MOBILE_MENU = "toggle-mobileMenu";
export const SET_INSTANCE = "set-instance";
export const SET_REGISTRATION_KEY = "set-registration-key";
export const SET_LICENCE_SEASON = "set-licence-season";
export const SET_CURRENT_PERSONID = "set-current-personId";
export const SET_CONSENTS = "set-consents";
export const SET_FORM_DATA = "set-form-data";
export const SET_FORM_DATA_FOR_STEP = "set-form-data-for-step";

const initialState: MainReduxState = {
  error: null,
  notification: null,
  noConfiguration: undefined,
  mobileMenuIsOpen: false,
  instance: undefined,
  registrationKey: "",
  currentPersonId: undefined,
  consents: [],

  data: {
    step1: { informations: {}, coords: {} },
    step2: {},
  } as MainReduxState["data"],
};

const mainReducer = (
  state: MainReduxState = initialState,
  action: any
): MainReduxState => {
  switch (action.type) {
    case SET_FORM_DATA:
      return { ...state, data: action.payload };
    case SET_FORM_DATA_FOR_STEP:
      const { step, data } = action.payload;

      const newData = { ...state.data } as any;
      newData[`step${step}`] = data;

      return { ...state, data: newData };
    case DISPLAY_ERROR:
      return { ...state, error: action.payload };
    case DISPLAY_NOTIFICATION:
      return { ...state, notification: action.payload };
    case TOGGLE_MOBILE_MENU:
      return { ...state, mobileMenuIsOpen: !state.mobileMenuIsOpen };
    case SET_INSTANCE:
      return { ...state, instance: action.payload };
    case SET_REGISTRATION_KEY:
      return { ...state, registrationKey: action.payload };
    case SET_CURRENT_PERSONID:
      return { ...state, currentPersonId: action.payload };
    case SET_CONSENTS:
      return { ...state, consents: action.payload };
    default:
      return state;
  }
};

export default mainReducer;
