import { RouteArgs } from "core/interfaces.config";

import { ROUTE_KEYS, ROUTE_PATHS } from "utils/routes/routes.utils";

import LicenceRenewal from "pages/LicenceRenewal";
import Config from "pages/Config";
import Error from "pages/Error";

const routes: RouteArgs[] = [
  {
    key: ROUTE_KEYS.HOME,
    path: ROUTE_PATHS[ROUTE_KEYS.HOME],
    component: LicenceRenewal,
    exact: true,
  },
  {
    key: ROUTE_KEYS.LICENCE_ENDSCREEN,
    path: ROUTE_PATHS[ROUTE_KEYS.LICENCE_ENDSCREEN],
    component: LicenceRenewal,
    exact: true,
  },
  {
    key: ROUTE_KEYS.ERROR,
    path: ROUTE_PATHS[ROUTE_KEYS.ERROR],
    component: Error,
    exact: true,
  },
  // CONFIG
  {
    key: ROUTE_KEYS.CONFIG,
    path: ROUTE_PATHS[ROUTE_KEYS.CONFIG],
    component: Config,
    exact: true,
  },
];

export default routes;
