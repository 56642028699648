import React, { useState } from "react";

import { Pricing } from "services/poona/poona.interfaces";
import { calculateTotalPrice } from "utils/renewal/price.utils";
import {
  StepComponent,
  RecapProof,
} from "pages/LicenceRenewal/renewal.interfaces";
import { useInstance } from "hooks";
import { DisplayRenewalPricing } from "components";

import "./index.scss";
import useData from "hooks/useData";

type Props = StepComponent & {};

const Recap: React.FC<Props> = (props) => {
  const { onChange } = props;
  const [totalPrice, setTotalPrice] = useState(0);
  const instance = useInstance();
  const fullRenewalData = useData();

  const handleOnChange = (
    price: Pricing,
    selectedDiscountIds: string[],
    selectedOptionsIds: string[],
    files: RecapProof[]
  ) => {
    const tempTotalPrice = calculateTotalPrice(
      price,
      selectedDiscountIds,
      selectedOptionsIds
    );
    setTotalPrice(tempTotalPrice);
    onChange({
      price,
      selectedDiscountIds,
      selectedOptionsIds,
      totalPrice: tempTotalPrice,
      files,
    });
  };

  return (
    <>
      {instance && (
        <DisplayRenewalPricing
          ownerId={instance.instanceId}
          onChange={handleOnChange}
          fullRenewalData={fullRenewalData}
        />
      )}

      <p className="total-price">MONTANT : {totalPrice.toFixed(2)} €</p>
    </>
  );
};

export default Recap;
