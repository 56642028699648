import React from "react";
import { useDispatch } from "react-redux";

import {
  DISPLAY_ERROR,
  SET_FORM_DATA_FOR_STEP,
} from "core/redux/reducer/main.reducer";
import { useCustomFieldsPrice } from "hooks";
import { renewalStep05IsValid } from "utils/renewal/step05.utils";
import { Step05Data } from "pages/LicenceRenewal/renewal.interfaces";
import Recap from "./components/Recap";
import Footer from "../../Footer";
import CustomFieldsPrice from "./components/CustomFieldsPrice";

import "./index.scss";
import useDataForStep from "hooks/useDataForStep";

type Props = {
  onNext: () => void;
  onPrevious: () => void;
};

const KEYS = {
  RECAP: "recap",
  CUSTOM_FIELDS: "customFields",
};

const Step05: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { onNext, onPrevious } = props;
  const rawCustomFields = useCustomFieldsPrice();
  const defaultData = useDataForStep<Step05Data>(5);
  const selectedPriceId = defaultData?.recap?.price?.priceId || -1;

  const handleOnChange = (key: string, data: any) => {
    const newFormData = { ...defaultData } as any;
    newFormData[key] = data;

    dispatch({
      type: SET_FORM_DATA_FOR_STEP,
      payload: { step: 5, data: newFormData },
    });
  };

  const handleOnNext = () => {
    const validationErrors = renewalStep05IsValid(
      selectedPriceId,
      defaultData,
      rawCustomFields
    );

    if (validationErrors.length) {
      const [firstValidationError] = validationErrors;
      dispatch({
        type: DISPLAY_ERROR,
        payload: {
          message: `${firstValidationError.comment}`,
        },
      });
      return;
    }

    onNext();
  };

  return (
    <>
      <Recap onChange={(data: any) => handleOnChange(KEYS.RECAP, data)} />

      {defaultData.recap.price?.priceId && !!rawCustomFields && (
        <CustomFieldsPrice
          priceId={defaultData.recap.price.priceId}
          onChange={(data: any) => handleOnChange(KEYS.CUSTOM_FIELDS, data)}
          rawCustomFields={rawCustomFields}
        />
      )}

      <Footer onNext={handleOnNext} onPrevious={onPrevious} />
    </>
  );
};

export default Step05;
