import React from "react";

type Props = { className?: string; stock: number; quota: number };

const Quota: React.FC<Props> = (props) => {
  const { className, stock, quota } = props;
  const displayed = stock > 0 && quota > 0;
  const isBuyable = stock <= 0 && quota > 0;

  return (
    <div className={className}>
      {displayed && `Reste ${stock}/${quota}`}
      {isBuyable && "Plus disponible"}
    </div>
  );
};

export default Quota;
