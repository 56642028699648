export const POONA_PAYMENT_METHODS = {
  cb: 1,
  cheque: 2,
  virement: 3,
  club: 4,
  espece: 5,
  other: 6,
  chequeOwner: 7,
  virementOwner: 8,
};
