import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useEffectOnce } from "hooks-me";

import { delayedCallback } from "utils/dom/dom.utils";
import { KeyValue } from "pages/LicenceRenewal/renewal.interfaces";
import Select from "../Select";

import "./index.scss";

type Props = {
  label?: string;
  subLabel?: string;
  items: KeyValue[];
  keyOfCheckedItem?: string[];
  onChange: (key: string[]) => void;
  required?: boolean;
};

const EMPTY_DEFAULT_ITEM: KeyValue = { key: "", value: "" };

const MultiSelect: React.FC<Props> = (props) => {
  const {
    label,
    subLabel,
    items,
    onChange,
    required,
    keyOfCheckedItem = [],
  } = props;
  const [availableItems, setAvailableItems] = useState<KeyValue[]>([
    EMPTY_DEFAULT_ITEM,
    ...items,
  ]);
  const [values, setValues] = useState<KeyValue[]>([]);

  const handleOnChange = (value: string) => {
    const newItem = availableItems.find((item) => item.key === value);
    if (!newItem) {
      return;
    }

    const newValues = Array.from(new Set([...values, newItem]));
    setValues(newValues);

    const newAvailableItems = [...availableItems].filter(
      (item) => item.key !== value
    );
    setAvailableItems(newAvailableItems);
  };

  const handleOnRemove = (value: string) => {
    const newItem = items.find(({ key }) => key === value);
    if (!newItem) {
      return;
    }

    const newValues = [...values].filter(({ key }) => key !== value);
    setValues(newValues);
    const newAvailableItems = [...availableItems, newItem];
    setAvailableItems(newAvailableItems);
  };

  useEffect(() => {
    onChange(values.map((value) => value.key));
    // eslint-disable-next-line
  }, [values]);

  useEffectOnce(() => {
    const potentialDefaultValues = items.filter((item) =>
      keyOfCheckedItem.includes(item.key)
    );

    delayedCallback(() => {
      setValues(potentialDefaultValues);
      setAvailableItems((items) =>
        items.filter((item) => !keyOfCheckedItem.includes(item.key))
      );
    });
  });

  return (
    <div className="multiselect">
      <Select
        onChange={handleOnChange}
        items={availableItems}
        label={label}
        subLabel={subLabel}
        required={required}
      />

      <div className="multiselect__items">
        {values.map(
          (item) =>
            item.key !== "" &&
            item.value !== "" && (
              <span key={item.key} className="multiselect__item">
                <span>{item.value}</span>
                <FontAwesomeIcon
                  className="multiselect__item--icon"
                  title="Supprimer cet élément"
                  icon={faTimes as any}
                  onClick={() => handleOnRemove(item.key)}
                />
              </span>
            )
        )}
      </div>
    </div>
  );
};

export default MultiSelect;
