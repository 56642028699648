import { Provider } from "react-redux";

import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import store from "core/redux";
import Router from "core/router";

import "react-intl-tel-input/dist/main.css";
import "ffbad-components/build/assets/css/bundle.css";
import "assets/css/styles.scss";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement as HTMLElement);

root.render(
  <StrictMode>
    <Provider store={store}>
      <Router />
    </Provider>
  </StrictMode>
);
