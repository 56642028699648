export enum ErrorTypes {
  Unknown = "unknown",
  NotFound = "notfound",
  PageNotFound = "pagenotfound",
  NoWebsiteConfiguration = "nowebsiteconfiguratio",
}

export const ErrorTypeLabels: { [x: string]: string } = {
  [ErrorTypes.Unknown]: "Une erreur inconnue est survenue",
  [ErrorTypes.NotFound]: "La page que vous recherchez n'existe pas (1)",
  [ErrorTypes.PageNotFound]: "La page que vous recherchez n'existe pas (2)",
  [ErrorTypes.NoWebsiteConfiguration]:
    'Ce club n\'a pas activé la solution "Club"',
};
