import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faExclamationTriangle,
  IconDefinition,
  faUniversalAccess,
} from "@fortawesome/free-solid-svg-icons";
import { faSun } from "@fortawesome/free-regular-svg-icons";

import { DISPLAY_ERROR } from "core/redux/reducer/main.reducer";
import { KeyCodes } from "core/enums/keycodes";
import { useFetchPrestations } from "hooks";
import { CustomSeasonLicence } from "services/poona/poona.interfaces";
import { Card, Loading } from "components";
import {
  PRESTATION_TYPES,
  Step01Data,
  StepComponent,
} from "pages/LicenceRenewal/renewal.interfaces";
import useDataForStep from "hooks/useDataForStep";

import "./index.scss";

type Props = StepComponent & {
  licenceType?: PRESTATION_TYPES;
};

const ICONS_MAPPING: Record<PRESTATION_TYPES, IconDefinition> = {
  NEW_LICENCE: faUser,
  ALREADY_LICENCE: faUniversalAccess,
  NO_LICENCE: faUser,
  PRE_RENTREE: faUser,
  GLOBALE: faUser,
  ESTIVALE: faSun,
  JOUEUR: faUser,
  NON_JOUEUR: faUser,
};

const LicenceTypeChooser: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { licenceType, onChange } = props;
  const { loading, prestations, error } = useFetchPrestations();
  const step01Data = useDataForStep<Step01Data>(1);
  const selectedType = step01Data.licenceType;

  const handleOnClick = (licence: CustomSeasonLicence) => {
    onChange(licence);
  };

  useEffect(() => {
    if (licenceType) {
      const potentialSelectedLicence = prestations.find(
        (prestation) => prestation.id === licenceType
      );

      if (potentialSelectedLicence) {
        setTimeout(() => {
          handleOnClick(potentialSelectedLicence);
        }, 100);
        return;
      }
    }

    if (prestations.length) {
      setTimeout(() => {
        handleOnClick(prestations.reverse()[0]);
      }, 100);
    }
    // eslint-disable-next-line
  }, [licenceType, prestations]);

  useEffect(() => {
    if (loading || !error) {
      return;
    }

    dispatch({
      type: DISPLAY_ERROR,
      payload: {
        message: "Impossible de récupérer les types de licence pour ce club",
      },
    });
  }, [error, dispatch, loading]);

  return (
    <Card
      title="Votre licence"
      subtitle="Choisissez votre type de licence"
      collapsible
      isOpen
    >
      <div className="licence-chooser-container">
        <div className="items-container">
          {loading && <Loading />}

          {!loading && (!prestations || prestations.length === 0) && (
            <div>Il n'y a aucun type de licence disponible pour ce club.</div>
          )}

          {prestations.map((licence) => (
            <div
              key={licence.label}
              className="item"
              data-selected={selectedType && selectedType.id === licence.id}
              role="button"
              tabIndex={0}
              onClick={() => handleOnClick(licence)}
              onKeyDown={(e) => {
                if (
                  e.keyCode === KeyCodes.ENTER ||
                  e.keyCode === KeyCodes.SPACE
                ) {
                  e.preventDefault();
                  handleOnClick(licence);
                }
              }}
            >
              <FontAwesomeIcon
                className="icon"
                icon={ICONS_MAPPING[licence.id] as any}
              />
              <span>{licence.label}</span>
            </div>
          ))}
        </div>

        {selectedType && (
          <p>
            <FontAwesomeIcon icon={faExclamationTriangle as any} />
            &nbsp;
            {selectedType.description}
          </p>
        )}
      </div>
    </Card>
  );
};

export default LicenceTypeChooser;
