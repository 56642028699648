import React from "react";

import { KeyCodes } from "core/enums/keycodes";
import { Button } from "components";

import "./index.scss";

type Props = {
  isFirstStep?: boolean;
  onNext: () => void;
  onPrevious?: () => void;

  nextLabel?: string;
  previousLabel?: string;
};

const DEFAULT_NEXT_LABEL = "SUIVANT";
const DEFAULT_PREVIOUS_LABEL = "précédent";

const Footer: React.FC<Props> = (props) => {
  const {
    isFirstStep = false,
    onNext,
    onPrevious,
    nextLabel = DEFAULT_NEXT_LABEL,
    previousLabel = DEFAULT_PREVIOUS_LABEL,
  } = props;

  const handleOnCancel = () => {
    const result = window.confirm(
      "Toutes votre progression va être perdue si vous continuez. Voulez-vous réellement annuler ?"
    );

    if (!result) {
      return;
    }

    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };

  return (
    <div className="licence-renewal-footer">
      <span
        role="link"
        tabIndex={0}
        className="link"
        onClick={handleOnCancel}
        onKeyDown={(e) => {
          if (e.keyCode === KeyCodes.ENTER) {
            e.preventDefault();
            handleOnCancel();
          }
        }}
      >
        Annuler
      </span>

      <span>
        {!isFirstStep && onPrevious && (
          <>
            <Button onClick={onPrevious} type="secondary">
              {previousLabel}
            </Button>
            &nbsp;
          </>
        )}

        <Button onClick={onNext}>{nextLabel}</Button>
      </span>
    </div>
  );
};

export default Footer;
