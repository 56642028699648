import {
  Step01Data,
  InformationData,
  CoordsData,
  CustomFieldTypes,
  CustomFieldItem,
  CustomFieldAsArray,
} from "pages/LicenceRenewal/renewal.interfaces";
import {
  checkFields,
  FieldDefinition,
  CheckFieldError,
  Reasons,
} from "./renewal.utils";
import { CIVILITE_INDEXES } from "core/enums/civilites";
import { isMinor } from "utils/string/string.utils";
import { COUNTRY_FRANCE_ID } from "pages/LicenceRenewal/components/Steps/Step01/components/Informations/informations.config";

export const AFNOR_MAX_LENGTH = 38;

/*
 * Step 01.
 */
export const renewalStep01IsValid = (
  data?: Step01Data,
  isRenewal: boolean = false,
  customFields?: CustomFieldAsArray
): CheckFieldError[] => {
  const errors = [
    ...informationsIsValid(data ? data.informations : {}, isRenewal),
    ...coordsIsValid(data ? data.coords : {}),
    ...customFieldsIsValid(customFields, data && data.customFields),
  ];

  if (data && !data.licenceType) {
    errors.push({
      key: "licenceType",
      label: "Type de licence",
      reason: Reasons.MissingRequiredField,
      comment: `Le champ est obligatoire`,
    });
  }

  return errors;
};

const informationsIsValid = (
  data: InformationData,
  isRenewal: boolean = false
): CheckFieldError[] => {
  const isFemale = data.gender === CIVILITE_INDEXES.Female;
  const isMinorOrNot = isMinor(data.birthDate);

  const fieldsDefinitions: FieldDefinition[] = [
    {
      key: "gender",
      label: "Civilité",
      type: "string",
      isRequired: true,
      minLength: 1,
    },
    {
      key: "lastName",
      label: isFemale ? "Nom d'usage" : "Nom",
      type: "string",
      isRequired: true,
      minLength: 1,
    },

    {
      key: "firstName",
      label: "Prénom",
      type: "string",
      isRequired: true,
      minLength: 1,
    },
    {
      key: "birthDate",
      label: "Date de naissance",
      type: "birthDate",
      isRequired: !isRenewal, // We can't edit birthdate if renewal case.
    },
    {
      key: "birthPlace",
      label: "Lieu de naissance",
      type: "string",
      isRequired: true,
    },
    {
      key: "birthCityInsee",
      label: "Lieu de naissance",
      type: "string",
      minLength: 5,
      isRequired: data.nativeCountryId === 83, // = France
    },
  ];

  if (isFemale && !isMinorOrNot) {
    fieldsDefinitions.push({
      key: "maidenName",
      label: "Nom de naissance",
      type: "string",
      isRequired: isFemale && !isMinorOrNot,
      minLength: 1,
    });
  }

  return checkFields(data, fieldsDefinitions);
};

const coordsIsValid = (data: CoordsData): CheckFieldError[] => {
  const isFrance = data.countryId && +data.countryId === COUNTRY_FRANCE_ID;
  const postalCodeDefinition: FieldDefinition = {
    key: "postalCode",
    label: "Code postal",
    type: isFrance ? "postalCode" : "postalCodeNotFrance",
    isRequired: true,
    length: isFrance ? 5 : undefined,
    maxLength: isFrance ? 5 : 10,
  };

  const fieldsDefinitions: FieldDefinition[] = [
    {
      key: "address",
      label: "Adresse",
      type: "string",
      isRequired: true,
      maxLength: AFNOR_MAX_LENGTH,
    },
    {
      key: "floor",
      label: "Appartement - Etage",
      type: "string",
      isRequired: false,
      maxLength: AFNOR_MAX_LENGTH,
    },
    {
      key: "building",
      label: "Bâtiment - Résidence",
      type: "string",
      isRequired: false,
      maxLength: AFNOR_MAX_LENGTH,
    },
    {
      key: "locality",
      label: "Lieu-dit ou Boîte Postale",
      type: "string",
      isRequired: false,
      maxLength: AFNOR_MAX_LENGTH,
    },
    postalCodeDefinition,
    {
      key: "city",
      label: "Ville",
      type: "string",
      isRequired: true,
    },
    {
      key: "fixPhone",
      label: "Téléphone fix",
      type: "tel",
      isRequired: false,
    },
    {
      key: "mobilePhone",
      label: "Téléphone portable",
      type: "tel",
      isRequired: true,
    },
    {
      key: "email",
      label: "Adresse e-mail",
      type: "email",
      isRequired: true,
    },
    {
      key: "emailConfirmation",
      label: "Confirmation de l'adresse e-mail",
      type: "email",
      isRequired: true,
    },
  ];

  const errors = checkFields(data, fieldsDefinitions);

  if (data.email !== data.emailConfirmation) {
    errors.push({
      key: "emailConfirmation",
      reason: Reasons.MissingRequiredField,
      comment: "Le champ est incorrect",
      label: "Confirmation de l'e-mail",
    });
  }
  return errors;
};

const customFieldsIsValid = (
  customFieldsFromPoona: CustomFieldAsArray | undefined,
  data: Record<string, any> = []
): CheckFieldError[] => {
  if (
    !customFieldsFromPoona ||
    !customFieldsFromPoona.fields ||
    !customFieldsFromPoona.fields.length
  ) {
    return [];
  }

  const error: CheckFieldError[] = customFieldsFromPoona.fields
    .map((aField) => {
      const { key, label, type, required = false } = aField;
      const value = data[key as any];

      // Don't type !value, because boolean values can be false. Thanks.
      if (required && (value === null || value === undefined)) {
        return {
          key,
          label,
          reason: Reasons.MissingRequiredField,
          comment: `Le champ est obligatoire`,
        };
      }

      if (!required && !value) {
        return {
          key,
          reason: Reasons.None,
        };
      }

      return checkCustomFieldTypes(type, aField, value);
    })
    .filter((p) => p.reason !== Reasons.None);

  return error;
};

const checkCustomFieldTypes = (
  type: CustomFieldTypes,
  field: CustomFieldItem,
  value: any
): CheckFieldError => {
  const { key, label } = field;

  if (!value) {
    return {
      key,
      reason: Reasons.None,
    };
  }

  switch (type) {
    case "boolean":
      if (typeof value !== "boolean") {
        return {
          key,
          label,
          reason: Reasons.TypeMistmatch,
          comment: `Le champ est invalide`,
        };
      }
      break;
    case "checkbox":
      // TODO: Add check if needed.
      break;
    case "date":
      if (isNaN(new Date(value).getTime())) {
        return {
          key,
          label,
          reason: Reasons.TypeMistmatch,
          comment: `Le champ est invalide`,
        };
      }
      break;
    case "multiSelect":
      // TODO: Add check if needed.
      break;
    case "text":
      // TODO: Add check if needed.
      break;
    case "select":
      // TODO: Add check if needed.
      break;
    case "number":
      if (isNaN(Number(value))) {
        return {
          key,
          label,
          reason: Reasons.TypeMistmatch,
          comment: `Le champ est invalide`,
        };
      }
      break;
    case "radio":
      // TODO: Add check if needed.
      break;
  }

  return {
    key,
    reason: Reasons.None,
  };
};
