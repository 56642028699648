import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { DISPLAY_ERROR } from "core/redux/reducer/main.reducer";
import { KeyCodes } from "core/enums/keycodes";
import {
  CertificateData,
  StepComponent,
} from "pages/LicenceRenewal/renewal.interfaces";
import {
  CheckFieldError,
  extractErrorByKey,
} from "utils/renewal/renewal.utils";
import { scrollToTop, getPropsForInputDate } from "utils/dom/dom.utils";
import PoonaService from "services/poona/poona.service";
import useRegistrationKey from "hooks/useRegistrationKey";
import { Card, Button, Dropzone } from "components";
import { Input } from "components/form";

import "./index.scss";

type Props = StepComponent & {
  defaultData?: CertificateData;
  fieldErrors: CheckFieldError[];
  isMinor: boolean;
};

const KEY = "certificate";
const KEYS = {
  CERTIFICATE_FILE: "newCertificateFile",
  CERTIFICATE_DATE: "certificateDate",
};
const AVAILABLE_FILE_FORMATS = ["jpg", "jpeg", "png", "pdf"];
const service = new PoonaService();

const Certificate: React.FC<Props> = (props) => {
  const [formData, setFormData] = useState<CertificateData>({});
  const [error, setError] = useState<CheckFieldError>();
  const { onChange, defaultData, fieldErrors = [] } = props;
  const registrationKey = useRegistrationKey();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!defaultData) {
      return;
    }
    setFormData(defaultData);
  }, [defaultData]);

  useEffect(() => {
    const anError = fieldErrors.find((fieldError) => fieldError.key === KEY);
    if (!anError) {
      return;
    }

    setError(anError);
    scrollToTop(KEY);
  }, [fieldErrors]);

  const handleOnChange = (key: string, value: any) => {
    const newFormData: any = { ...formData, [key]: undefined };

    if (value) {
      if (typeof value == "string") {
        newFormData[key] = value;
      } else {
        service
          .uploadTempFile(registrationKey, "medical_certificate", value as File)
          .then((response) => {
            newFormData[key] = response.key;
            onChange(newFormData);
          })
          .catch(() => {
            dispatch({
              type: DISPLAY_ERROR,
              payload: {
                message: "Impossible de télécharger votre certificat médical",
              },
            });
          });

        return;
      }
    }
    onChange(newFormData);
  };

  const handleOnOpenCertificateUrl = () => {
    window.open(process.env.REACT_APP_MEDICAL_CERTIFICATE_URL, "_blank");
  };

  return (
    <Card
      id={KEY}
      title="Certificat médical"
    >
      {error && <p className="text-error">{error.comment}</p>}
      <div className="certificate-container">
        <div className="left-side">
          <img
            src="/assets/images/certificate_preview.png"
            alt="lien vers le certificat médical officiel"
            onClick={handleOnOpenCertificateUrl}
            role="button"
            tabIndex={0}
            title="Télécharger le certificat médical"
            onKeyDown={(e) => {
              if (
                e.keyCode === KeyCodes.ENTER ||
                e.keyCode === KeyCodes.SPACE
              ) {
                e.preventDefault();
                handleOnOpenCertificateUrl();
              }
            }}
          />
          <div>
            <p>
              Vous devrez faire compléter et signer ce certificat auprès de
              votre médecin traitant.
            </p>
            <Button
              type="secondary"
              onClick={handleOnOpenCertificateUrl}
              title="Télécharger le certificat médical"
            >
              Télécharger le certificat médical
            </Button>
          </div>
        </div>

        <div className="right-side">
          <h5>
            Mon certificat médical FFBaD est complété et signé par mon médecin,
            je l’importe :
          </h5>

          <Dropzone
            onUpload={(file) => handleOnChange(KEYS.CERTIFICATE_FILE, file)}
            availableFileFormats={AVAILABLE_FILE_FORMATS}
            defaultFile={defaultData && defaultData.newCertificateFile}
          />

          <Input
            label="Date du certificat médical"
            placeholder="Saisissez la date du certificat"
            defaultValue={(formData as any)[KEYS.CERTIFICATE_DATE]}
            onChange={(value) => handleOnChange(KEYS.CERTIFICATE_DATE, value)}
            error={extractErrorByKey(KEYS.CERTIFICATE_DATE, fieldErrors)}
            required
            {...getPropsForInputDate()}
          />
          <p>Votre certificat médical doit dater de moins de 6 mois.</p>
          <p className="small-text">
            * La saison sportive commence au 1 er septembre et se termine au 31
            août.
          </p>
        </div>
      </div>
    </Card>
  );
};

export default Certificate;
