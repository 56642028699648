import { useDispatch } from "react-redux";
import { useEffectOnce, useToggle } from "hooks-me";

import { SET_REGISTRATION_KEY } from "core/redux/reducer/main.reducer";
import { randomId } from "utils/string/string.utils";

const useRegistrationKeyGeneration = (): boolean => {
  const [loading, setLoading] = useToggle(true);
  const dispatch = useDispatch();

  useEffectOnce(() => {
    dispatch({ type: SET_REGISTRATION_KEY, payload: randomId("xxxxxxxxxx") });
    setLoading(false);
  });

  return loading;
};

export default useRegistrationKeyGeneration;
