import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useArray, useEffectOnce, useToggle } from "hooks-me";

import { DISPLAY_ERROR } from "core/redux/reducer/main.reducer";
import { DEFAULT_MODALITE } from "core/enums/modalites";
import PoonaService from "services/poona/poona.service";
import { Scheduler } from "services/poona/poona.interfaces";
import { useInstance } from "hooks";
import { StepComponent } from "pages/LicenceRenewal/renewal.interfaces";
import { Card, Loading } from "components";
import { InputRadioGroup } from "components/form";

import "./index.scss";

type Props = StepComponent & {
  defaultData?: any;
  price: number;
  birthday: string;
  selectedPriceId: number;
};

const ERROR_MESSAGE = "Impossible de récupérer les modalités de paiement...";

const Modalite: React.FC<Props> = (props) => {
  const {
    onChange,
    defaultData: { modaliteId = DEFAULT_MODALITE } = {},
    price = 0,
    birthday,
    selectedPriceId,
  } = props;
  const [selectedId, setSelectedId] = useState<string>();
  const { value: items, set: setItems } = useArray<Scheduler>([]);
  const [loading, setLoading] = useToggle(false);
  const service = useRef(new PoonaService());
  const dispatch = useDispatch();
  const instance = useInstance();

  const handleOnChange = (ids: string[]) => {
    const [firstSelectedId] = ids;
    if (firstSelectedId === selectedId) {
      return;
    }

    setSelectedId(firstSelectedId);
    onChange({ modaliteId: firstSelectedId });
  };

  useEffect(() => setSelectedId(modaliteId), [modaliteId]);

  useEffectOnce(() => {
    onChange({ modaliteId: DEFAULT_MODALITE });
  });

  useEffect(() => {
    if (!instance || !instance.instanceId || price <= 0) {
      return;
    }

    setLoading(true);
    setItems([]);
    handleOnChange([]);

    const { promise, cancel } = service.current.scheduler(instance.instanceId, {
      amount: price,
      birthday: moment(birthday).format("YYYY-MM-DD"),
      priceId: selectedPriceId,
    });

    promise
      .then((response) => {
        setItems(response);
        setLoading(false);

        const [first] = response;
        if (first) {
          handleOnChange([first.schedulerId.toString()]);
        }
      })
      .catch(() => {
        dispatch({
          type: DISPLAY_ERROR,
          payload: {
            message: ERROR_MESSAGE,
          },
        });
      });
    return () => cancel();
    // eslint-disable-next-line
  }, [dispatch, price, birthday, selectedPriceId, instance]);

  if (!loading && items && items.length <= 1) {
    return null;
  }

  return (
    <Card title="Modalité de paiement">
      <div className="modalites-container">
        {loading && <Loading />}

        {!loading && items.length === 0 && (
          <p>Il n'y a pas de modalité de paiement associée à votre tarif.</p>
        )}

        {!loading && items.length > 0 && (
          <>
            <div>
              <InputRadioGroup
                items={items.map((item) => ({
                  key: item.schedulerId.toString(),
                  value: item.name,
                }))}
                keyOfCheckedItem={modaliteId}
                onChange={handleOnChange}
              />
            </div>
            <div className="motalites-versements">
              {selectedId &&
                items
                  .find((item) => item.schedulerId === +selectedId)
                  ?.scheduler.map((amount, index) => (
                    <div key={index}>
                      <b>Mensualité {index + 1}</b> de {amount}€
                    </div>
                  ))}
            </div>
          </>
        )}
      </div>
    </Card>
  );
};

export default Modalite;
