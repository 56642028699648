import React from "react";

import { ConsentData } from "services/poona/poona.interfaces";
import DisplayFile from "../DisplayFile";

type Props = {
  aConsent: ConsentData;
};

const SimpleText: React.FC<Props> = (props) => {
  const { aConsent } = props;

  return (
    <>
      <div>
        <b>{aConsent.label}</b>
      </div>
      <span
        key={aConsent.consentId}
        dangerouslySetInnerHTML={{
          __html: aConsent.content,
        }}
      />

      {aConsent.url && (
        <>
          <DisplayFile url={aConsent.url} />
          <br />
        </>
      )}
    </>
  );
};

export default SimpleText;
