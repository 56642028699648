import React, { useState } from "react";
import { Label } from "components/form";

import "./index.scss";

type Props = {
  label?: string;
  subLabel?: string;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  on?: boolean;
  required?: boolean;
};

const Switch: React.FC<Props> = (props) => {
  const {
    label,
    subLabel,
    onChange,
    disabled,
    on = false,
    required = false,
  } = props;
  const [localOn, setLocalOn] = useState(on);

  const handleOnClick = () => {
    if (disabled) {
      return;
    }

    setLocalOn(!localOn);
    onChange(!localOn);
  };

  return (
    <>
      {label && (
        <Label subLabel={subLabel} required={required}>
          {label}
        </Label>
      )}
      <div
        className="switch"
        onClick={handleOnClick}
        data-on={localOn}
        data-disabled={disabled}
      >
        <div className="switch--dot" />
      </div>
    </>
  );
};

export default Switch;
