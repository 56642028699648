import type {
  Step02Data,
  ContactsData,
} from "pages/LicenceRenewal/renewal.interfaces";
import {
  CheckFieldError,
  FieldDefinition,
  checkFields,
  Reasons,
} from "./renewal.utils";

export const renewalStep02IsValid = (
  data?: Step02Data
): CheckFieldError[][] => {
  const errors = [...contactsAreValid(data ? data.contacts : [])];
  return errors;
};
const contactsAreValid = (data: ContactsData[] = []): CheckFieldError[][] => {
  const fieldsDefinitions: FieldDefinition[] = [
    {
      key: "firstName",
      label: "Nom",
      type: "string",
      isRequired: true,
      minLength: 2,
    },
    {
      key: "lastName",
      label: "Prénom",
      type: "string",
      isRequired: true,
      minLength: 2,
    },
    {
      key: "email",
      label: "Adresse e-mail",
      type: "email",
      isRequired: true,
    },
    {
      key: "contactTypeId",
      label: "Type du Contact",
      type: "string",
      isRequired: true,
    },
    {
      key: "phone",
      label: "Téléphone",
      type: "tel",
      isRequired: true,
    },
  ];

  if (!data || !data.length) {
    return [
      formatError(
        [
          {
            key: "all",
            reason: Reasons.NotProvided,
            comment: "Merci de saisir au moins un contact",
            label: "obligatoire",
          },
          ...fieldsDefinitions.map((definition) => ({
            key: definition.key,
            reason: Reasons.MissingRequiredField,
            comment: `Le champ est obligatoire`,
            label: definition.label,
          })),
        ],
        0
      ),
    ];
  }

  const contactWithNoRole = data.filter(
    (aContact) =>
      !aContact.isEmergency &&
      !aContact.isAccompanying &&
      !aContact.isLegalGuardian
  );

  if (contactWithNoRole.length) {
    const index = data.indexOf(contactWithNoRole[0]);

    return [
      formatError(
        [
          {
            key: "all",
            reason: Reasons.NotProvided,
            comment: `Merci de spécifier un rôle pour le contact n°${index +
              1}`,
            label: "obligatoire",
          },
        ],
        0
      ),
    ];
  }

  const hasNoRequiredContact = data.every(
    (aContact) => !aContact.isEmergency && !aContact.isAccompanying && !aContact.isLegalGuardian
  );

  if (hasNoRequiredContact) {
    return [
      formatError(
        [
          {
            key: "all",
            reason: Reasons.NotProvided,
            comment: "Merci de spécifier au moins un contact d'urgence",
            label: "obligatoire",
          },
        ],
        0
      ),
    ];
  }

  const contactErrors = data.map((c, i) => {
    return formatError(checkFields(c, fieldsDefinitions), i);
  });

  return contactErrors.filter((p) => p.length);
};

const formatError = (
  errors: CheckFieldError[],
  index: number
): CheckFieldError[] => {
  return errors.map((anError) => ({
    ...anError,
    key: index + anError.key,
  }));
};
