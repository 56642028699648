import { useMemo } from "react";
import { useParams } from "react-router-dom";

import CryptoService from "services/crypto/crypto.service";

const useRenewalLicence = (): string | undefined => {
  const { key } = useParams<{ key?: string }>();
  const cryptoService = useMemo(() => new CryptoService(), []);

  return key && cryptoService.decrypt(key);
};

export default useRenewalLicence;
