import moment from "moment";

import { Adherent } from "core/interfaces.config";
import store from "core/redux";
import MasterService from "services/master.service";
import {
  Instance,
  Country,
  Pricing,
  SeasonLicence,
  Consent,
  Town,
  Scheduler,
  Member,
  CustomSeasonLicence,
  HyperCubeInstance,
  ContactTypePayload,
} from "./poona.interfaces";
import { toBase64 } from "utils/file/file.utils";
import { PRESTATION_TYPES } from "pages/LicenceRenewal/renewal.interfaces";

class PoonaService {
  private master: MasterService;

  constructor() {
    this.master = new MasterService();
  }

  get defaultHeaders(): RequestInit {
    const { currentPersonId, data } = store.getState();

    return {
      headers: {
        apiSeasonId: data?.step1?.licenceType?.seasonId,
        currentPersonId,
        "Content-Type": "application/json",
      },
    } as RequestInit;
  }

  instance = (
    instanceId: string | number
  ): { promise: Promise<Instance>; cancel: any } => {
    return this.master.get<Instance>(
      `poona/instance/${instanceId}/full`,
      this.defaultHeaders
    );
  };

  instanceBySigle = (
    sigle: string
  ): { promise: Promise<Instance>; cancel: any } => {
    return this.master.get<Instance>(
      `poona/instanceBySigle/${sigle}`,
      this.defaultHeaders
    );
  };

  countries = (): {
    promise: Promise<Country[]>;
    cancel: () => void;
  } => {
    return this.master.get<Country[]>("poona/countries", this.defaultHeaders);
  };

  customFields = (
    instanceId: string | number,
    category: "price" | "all" | "no-filter" = "no-filter"
  ): {
    promise: Promise<any>;
    cancel: () => void;
  } => {
    return this.master.get<any>(
      `poona/registration/customFields/${instanceId}?category=${category}`,
      this.defaultHeaders
    );
  };

  customFieldsForPrices = (
    instanceId: string | number
  ): {
    promise: Promise<any>;
    cancel: () => void;
  } => {
    return this.master.get<any>(
      `poona/registration/customFields/${instanceId}?category=price`,
      this.defaultHeaders
    );
  };

  qsSportQuestions = (
    birthday?: string | number
  ): {
    promise: Promise<any[]>;
    cancel: () => void;
  } => {
    return this.master.get<any[]>(
      `poona/registration/qssport/${birthday}`,
      this.defaultHeaders
    );
  };

  consents = (
    instanceId: string | number
  ): {
    promise: Promise<Consent[]>;
    cancel: () => void;
  } => {
    return this.master.get<Consent[]>(
      `poona/registration/consents/${instanceId}`,
      this.defaultHeaders
    );
  };

  billingModes = (
    instanceId: string | number
  ): {
    promise: Promise<any[]>;
    cancel: () => void;
  } => {
    return this.master.get<any[]>(
      `poona/registration/billing-modes/${instanceId}`,
      this.defaultHeaders
    );
  };

  prices = (
    instanceId: string | number,
    args: {
      licence?: string;
      sex?: "1" | "2";
      birthday?: string;
      customSeasonLicence?: CustomSeasonLicence;
    }
  ): {
    promise: Promise<Pricing[]>;
    cancel: () => void;
  } => {
    const { customSeasonLicence, ...queryParams } = args;
    let query = Object.keys(queryParams)
      .map((key) => `${key}=${(args as any)[key]}`)
      .join("&");

    if (customSeasonLicence) {
      const key =
        customSeasonLicence.type === "licence"
          ? "licenceTypeId"
          : "prestationId";

      query += `&${key}=${customSeasonLicence.id}`;
    }

    return this.master.get<Pricing[]>(
      `poona/registration/prices/${instanceId}?${query}`,
      this.defaultHeaders
    );
  };

  licenceTypes = (
    instanceId: string | number
  ): {
    promise: Promise<SeasonLicence[]>;
    cancel: () => void;
  } => {
    return this.master.get<SeasonLicence[]>(
      `poona/registration/seasons-types/${instanceId}`,
      this.defaultHeaders
    );
  };

  personByLicence = (
    licence: string
  ): {
    promise: Promise<Adherent>;
    cancel: () => void;
  } => {
    return this.master.get<Adherent>(
      `poona/person/licence/${licence}`,
      this.defaultHeaders
    );
  };

  members = (
    personId: string
  ): {
    promise: Promise<Member[]>;
    cancel: () => void;
  } => {
    return this.master.get<Member[]>(
      `poona/person/${personId}/members`,
      this.defaultHeaders
    );
  };

  isNeededCertificate = (args?: {
    personId?: string;
    birthday: string;
    prestationId: string;
    seasonId: string;
  }): {
    promise: Promise<{ result: boolean }>;
    cancel: () => void;
  } => {
    // https://ffbadminton.myjetbrains.com/youtrack/issue/MFFBD-95/Adhesion-en-ligne-Licence-non-joueur-Certificat
    if (args?.prestationId === PRESTATION_TYPES.NON_PLAYER) {
      return {
        promise: Promise.resolve({ result: false }),
        cancel: () => {},
      };
    }

    const query = args
      ? "?" +
        Object.keys(args)
          .map((key) => `${key}=${(args as any)[key]}`)
          .join("&")
      : "";

    return this.master.get<{ result: boolean }>(
      `poona/registration/needed-cm${query}`,
      this.defaultHeaders
    );
  };

  scheduler = (
    instanceId: string,
    args: {
      // Don't change naming of following fields because they are used and defined by Poona.
      amount: number;
      birthday: string;
      priceId: number;
    }
  ): {
    promise: Promise<Scheduler[]>;
    cancel: () => void;
  } => {
    const query = Object.keys(args)
      .map((key) => `${key}=${(args as any)[key]}`)
      .join("&");

    return this.master.get<Scheduler[]>(
      `poona/registration/scheduler/${instanceId}?${query}`,
      this.defaultHeaders
    );
  };

  uploadTempFile = (
    key: string,
    fileKey: string,
    file: File
  ): Promise<{ key: string }> => {
    return new Promise((resolve, reject) => {
      toBase64(file)
        .then((content) => {
          const body = { key, fileKey, content };

          this.master
            .post<{ key: string }>(`poona/registration/upload`, body, {
              ...this.defaultHeaders,
              headers: {
                "Content-Type": "application/json",
              },
            })
            .promise.then(({ key }) => resolve({ key }))
            .catch(reject);
        })
        .catch(reject);
    });
  };

  towns = (
    postalCode: string
  ): {
    promise: Promise<Town[]>;
    cancel: () => void;
  } => {
    return this.master.get<Town[]>(
      `other/fetch-town/${postalCode}`,
      this.defaultHeaders
    );
  };

  /**
   *
   * @param name name of the town/city
   * @param birthday format: YYYY-MM-DD (1967-01-01)
   * @returns
   */
  townsByName = (
    name: string,
    birthday?: string
  ): {
    promise: Promise<Town[]>;
    cancel: () => void;
  } => {
    const date = moment(birthday).format("YYYY-MM-DD");

    return this.master.get<Town[]>(
      `other/fetch-town/name/${name}/${date}`,
      this.defaultHeaders
    );
  };

  findInstanceByDomain = (
    sigle: string
  ): {
    promise: Promise<HyperCubeInstance>;
    cancel: () => void;
  } => {
    return this.master.get<HyperCubeInstance>(
      `poona/instance/clubByDomain/${sigle}`,
      this.defaultHeaders
    );
  };

  auth = (
    body: any = {}
  ): {
    promise: Promise<{
      accessToken: string;
    }>;
    cancel: () => void;
  } => {
    return this.master.post("auth/poona", body, this.defaultHeaders);
  };

  getContactTypes = (): {
    promise: Promise<ContactTypePayload>;
    cancel: () => void;
  } => {
    return this.master.get("config/contact/type", this.defaultHeaders);
  };

  getContacts = (
    personId: string
  ): {
    promise: Promise<{
      items: {
        contactAssoId: string;
        contactType: string;
        contactTypeId: string;
        isAccompanying: boolean;
        isEmergency: boolean;
        isLegalGuardian: boolean;
        contact: {
          contactId: string;
          email: string;
          firstname: string;
          lastname: string;
          phone: string;
          address: any[];
        };
      }[];
    }>;
    cancel: () => void;
  } => {
    return this.master.get(
      `poona/persons/${personId}/contacts`,
      this.defaultHeaders
    );
  };
}

export default PoonaService;
