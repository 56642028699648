import React, { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useArray, useToggle } from "hooks-me";

import {
  DISPLAY_ERROR,
  SET_FORM_DATA_FOR_STEP,
} from "core/redux/reducer/main.reducer";
import { ConsentTypes } from "core/enums/consentTypes";
import { CheckFieldError } from "utils/renewal/renewal.utils";
import {
  Step03Data,
  CertificateData,
  QSSportData,
  Step01Data,
  PRESTATION_TYPES,
} from "pages/LicenceRenewal/renewal.interfaces";
import {
  renewalStep03IsValid,
  calculateAge,
  shouldShowQSCM,
} from "utils/renewal/step03.utils";

import Footer from "../../Footer";
import Certificate from "./components/Certificate";
import QSSport from "./components/QSSport";
import { useMainState } from "hooks";
import PoonaService from "services/poona/poona.service";
import { Loading } from "components";
import Acceptation from "../Step04/components/Acceptation";
import useDataForStep from "hooks/useDataForStep";

type Props = {
  onNext: () => void;
  onPrevious: () => void;
};

const KEYS = {
  CERTIFICATE: "certificate",
  QS_SPORT: "qssport",
  ASSURANCE: "assurance",
  PLAYER: "player",
};

const service = new PoonaService();

const Step03: React.FC<Props> = (props) => {
  const { onNext, onPrevious } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useToggle(true);
  const [cmIsNeeded, setCmIsNeeded] = useToggle(true);
  const [cmHasBeenFecthed, setCmHasBeenFecthed] = useToggle(false);
  const [isMinor, setIsMinor] = useToggle(false);
  const { value: fieldErrors, set: setFieldErrors } = useArray<CheckFieldError>(
    []
  );
  const step1Data = useDataForStep<Step01Data>(1);
  const step3Data = useDataForStep<Step03Data>(3);

  const birthDate = step1Data.informations.birthDate || "";
  const licenceType = step1Data.licenceType;
  const {
    currentPersonId,
    data: { step1 },
  } = useMainState();
  const isNonPlayer = step1.licenceType?.id === PRESTATION_TYPES.NON_PLAYER;

  // [FFBAD-404] Fonctional rule: If the medical certificate is provided, no need to display the QS-Sport form.
  const showQSCM = shouldShowQSCM({
    cmIsNeededFromPoona: cmIsNeeded,
    choosenLicenceType: step1Data.licenceType?.id,
    qsSportData: step3Data.qssport,
  });

  const handleOnChange = (key: string, data: any) => {
    const newFormData = { ...step3Data } as any;
    newFormData[key] = data;

    dispatch({
      type: SET_FORM_DATA_FOR_STEP,
      payload: { step: 3, data: newFormData },
    });
  };

  const handleOnNext = () => {
    setFieldErrors([]);
    const validationErrors = renewalStep03IsValid({
      data: step3Data,
      displayCertificate: showQSCM.cm,
      displayQSSport: showQSCM.qs,
      birthDate,
      seasonId: +licenceType.seasonId,
      isNonPlayer,
    });

    if (validationErrors.length) {
      setFieldErrors(validationErrors);
      const [firstValidationError] = validationErrors;
      dispatch({
        type: DISPLAY_ERROR,
        payload: {
          message: firstValidationError.comment,
        },
      });
      return;
    }

    onNext();
  };

  const handleOnPrevious = useCallback(() => {
    onPrevious();
  }, [onPrevious]);

  useEffect(() => {
    if (cmHasBeenFecthed) {
      return;
    }

    if (isNonPlayer) {
      setCmIsNeeded(false);
      setLoading(false);
      setCmHasBeenFecthed(true);
      return;
    }

    if (calculateAge(birthDate) < 18) {
      setIsMinor(true);
    }

    // FIX!
    if (!birthDate || !licenceType.prestationId || !licenceType.seasonId) {
      console.error(
        "WHAT?! Missing data in order to fetch CM is needed request.",
        {
          personId: currentPersonId,
          birthday: birthDate,
          prestationId: licenceType?.prestationId,
          seasonId: licenceType?.seasonId,
        }
      );
      onPrevious();
      return;
    }

    const { promise, cancel } = service.isNeededCertificate({
      personId: currentPersonId,
      birthday: birthDate,
      prestationId: licenceType.prestationId,
      seasonId: licenceType.seasonId,
    });

    promise
      .then((response) => setCmIsNeeded(response.result))
      .catch(() => {
        handleOnPrevious();
        dispatch({
          type: DISPLAY_ERROR,
          payload: {
            message: "Le serveur n'a pas répondu",
          },
        });
      })
      .finally(() => {
        setLoading(false);
        setCmHasBeenFecthed(true);
      });
    return () => cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPersonId,
    birthDate,
    licenceType,
    dispatch,
    handleOnPrevious,
    setIsMinor,
    setCmIsNeeded,
    setLoading,
    setCmHasBeenFecthed,
    cmHasBeenFecthed,
    onPrevious,
  ]);

  useEffect(() => {
    if (licenceType?.isExternal) {
      onNext();
    }
  }, [licenceType, onNext]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {!isNonPlayer && showQSCM.qs && (
        <QSSport
          birthDate={birthDate}
          defaultData={step3Data && step3Data.qssport}
          fieldErrors={fieldErrors}
          isMinor={isMinor}
          onChange={(data: QSSportData) => handleOnChange(KEYS.QS_SPORT, data)}
        />
      )}

      {!isNonPlayer && showQSCM.cm && (
        <Certificate
          defaultData={step3Data && step3Data.certificate}
          fieldErrors={fieldErrors}
          isMinor={!cmIsNeeded}
          onChange={(data: CertificateData) =>
            handleOnChange(KEYS.CERTIFICATE, data)
          }
        />
      )}

      {!isNonPlayer && (
        <Acceptation
          fieldErrors={[]}
          onChange={(data: any) => handleOnChange(KEYS.PLAYER, data)}
          defaultData={step3Data.player}
          type={ConsentTypes.PLAYER}
        />
      )}

      <Acceptation
        fieldErrors={[]}
        onChange={(data: any) => handleOnChange(KEYS.ASSURANCE, data)}
        defaultData={step3Data.assurance}
        type={ConsentTypes.ASSURANCE}
      />

      <Footer onNext={handleOnNext} onPrevious={handleOnPrevious} />
    </>
  );
};

export default Step03;
