import React, { useEffect, useState } from "react";
import { useToggle } from "hooks-me";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCreditCard,
  faLock,
  faMoneyCheck,
  faQuestion,
  faMoneyCheckAlt,
  faCoins,
} from "@fortawesome/free-solid-svg-icons";

import { KeyCodes } from "core/enums/keycodes";
import { POONA_PAYMENT_METHODS } from "core/enums/paymentMethods";
import PoonaService from "services/poona/poona.service";
import {
  StepComponent,
  PaymentMethod,
} from "pages/LicenceRenewal/renewal.interfaces";
import { useInstance } from "hooks";
import { Card, Loading } from "components";

import "./index.scss";
import { DISPLAY_ERROR } from "core/redux/reducer/main.reducer";
import { useDispatch } from "react-redux";

const service = new PoonaService();
const PAIEMENT_METHOD_ICONS: any = {
  1: [faCreditCard],
  3: [faMoneyCheck],
  6: [faMoneyCheckAlt, faCoins, faQuestion],
};

type Props = StepComponent & {
  defaultData?: PaymentMethod;
  price?: number;
};

const PaymentMethods: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { onChange, defaultData, price } = props;
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [isLoading, setIsLoading] = useToggle(true);
  const selectedPaymentMethod = paymentMethods.find(
    (p) => p.paymentMethodId === selectedId
  );
  const instance = useInstance();

  useEffect(() => {
    if (paymentMethods.length || !instance) {
      return;
    }

    const { promise, cancel } = service.billingModes(instance.instanceId);
    promise
      .then((response: any[]) => {
        if (price === 0) {
          response = response.filter((method) => method.paymentMethodId === 6);
        }
        setPaymentMethods(response);
        setIsLoading(false);
        if (response && response.length) {
          handleOnChange(response[0].paymentMethodId);
        }
      })
      .catch(() => {
        dispatch({
          type: DISPLAY_ERROR,
          payload: {
            message: "Impossible de récupérer les modes de paiement",
          },
        });
      });
    return () => cancel();
    // eslint-disable-next-line
  }, [defaultData, instance]);

  useEffect(() => {
    if (defaultData?.paymentMethodId === -1 && paymentMethods.length) {
      const id = paymentMethods[0].paymentMethodId;
      setSelectedId(id);
      handleOnChange(id);
    }
    // eslint-disable-next-line
  }, [defaultData, paymentMethods]);

  const handleOnChange = (id: number) => {
    setSelectedId(id);
    onChange({ paymentMethodId: id });
  };

  return (
    <>
      <Card
        title="Choix du paiement"
        subtitle="Sélectionnez votre mode de paiement"
      >
        {isLoading && <Loading />}

        {!isLoading && (
          <>
            {(!paymentMethods || paymentMethods.length === 0) && (
              <div>Il n'y a pas de méthode de paiement pour ce club.</div>
            )}

            <div className="methods-container">
              {paymentMethods.map((method: any) => (
                <div
                  role="button"
                  tabIndex={0}
                  key={method.paymentMethodId}
                  className={`method${
                    selectedId === method.paymentMethodId ? " selected" : ""
                  }`}
                  onClick={() => handleOnChange(method.paymentMethodId)}
                  aria-label={method.name}
                  onKeyDown={(e) => {
                    if (
                      e.keyCode === KeyCodes.ENTER ||
                      e.keyCode === KeyCodes.SPACE
                    ) {
                      e.preventDefault();
                      handleOnChange(method.paymentMethodId);
                    }
                  }}
                >
                  <span>
                    {PAIEMENT_METHOD_ICONS[method.paymentMethodId].map(
                      (icon: any, index: number) => (
                        <span key={index}>
                          <FontAwesomeIcon icon={icon} size="2x" />
                          &nbsp;
                        </span>
                      )
                    )}
                  </span>
                  <span>{method.name}</span>
                </div>
              ))}
            </div>

            {selectedPaymentMethod && (
              <span
                dangerouslySetInnerHTML={{
                  __html: selectedPaymentMethod.description,
                }}
              ></span>
            )}

            {selectedId === POONA_PAYMENT_METHODS.cb && (
              <div className="secure-text">
                <FontAwesomeIcon icon={faLock as any} />
                &nbsp;
                <span>Plateforme de paiement 100% sécurisée</span>
              </div>
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default PaymentMethods;
