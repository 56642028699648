import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useToggle } from "hooks-me";

import { ConsentTypes } from "core/enums/consentTypes";
import {
  DISPLAY_ERROR,
  SET_FORM_DATA_FOR_STEP,
} from "core/redux/reducer/main.reducer";
import PoonaService from "services/poona/poona.service";
import { CheckFieldError } from "utils/renewal/renewal.utils";
import { renewalStep04IsValid } from "utils/renewal/step04.utils";
import { isMinor } from "utils/string/string.utils";
import { useMainState } from "hooks";

import {
  Step04Data,
  Step01Data,
  Step02Data,
} from "pages/LicenceRenewal/renewal.interfaces";
import Acceptation from "./components/Acceptation";
import Attestation from "./components/Attestation";
import Footer from "../../Footer";
import useDataForStep from "hooks/useDataForStep";

type Props = {
  onNext: () => void;
  onPrevious: () => void;
};

const INTERNAL_CONSENT_ID = 10;
const EXTERNAL_CONSENT_ID = 11;
const KEYS = {
  ACCEPTATION: "acceptation",
  ATTESTATION: "attestation",
  SIGNATURE: "signature",
};

const AVAILABLE_CONSENT_TYPES = [
  ConsentTypes.RGPD,
  ConsentTypes.ABONNEMENT,
  ConsentTypes.DROIT_IMAGE_FFBAD,
  ConsentTypes.DROIT_IMAGE_ASSOCIATION,
  ConsentTypes.CLUB,
];

const AVAILABLE_CONSENT_TYPES_EXTERNAL = [
  ConsentTypes.DROIT_IMAGE_ASSOCIATION,
  ConsentTypes.CLUB,
];

const poonaService = new PoonaService();

const Step04: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const step01Data = useDataForStep<Step01Data>(1);
  const step02Data = useDataForStep<Step02Data>(2);

  const mainResponsableData =
    step02Data &&
    step02Data.contacts &&
    step02Data.contacts.length &&
    (step02Data.contacts[0] as any);
  const personData = step01Data.informations;
  const licenceType = step01Data.licenceType;
  const choosenSeasonId = step01Data.licenceType.seasonId;

  const [fieldErrors, setFieldErrors] = useState<CheckFieldError[]>([]);
  const { onNext, onPrevious } = props;
  const defaultData = useDataForStep<Step04Data>(4);
  const shouldDisplayRepresentativeBlock = isMinor(personData.birthDate);
  const { consents, currentPersonId } = useMainState();

  const [shouldCheckMemberValues, setShouldCheckMemberValues] = useToggle(true);

  const availableConsents = licenceType?.isExternal
    ? AVAILABLE_CONSENT_TYPES_EXTERNAL
    : AVAILABLE_CONSENT_TYPES;

  const handleOnChange = (key: string, data: any) => {
    const newFormData = { ...defaultData } as any;
    if (key === KEYS.ACCEPTATION) {
      newFormData[key] = {
        ...(defaultData as any)[key],
        ...data,
      };
    } else {
      newFormData[key] = data;
    }

    dispatch({
      type: SET_FORM_DATA_FOR_STEP,
      payload: { step: 4, data: newFormData },
    });
  };

  const handleOnNext = () => {
    setFieldErrors([]);
    const validationErrors = renewalStep04IsValid(
      defaultData,
      shouldDisplayRepresentativeBlock,
      consents,
      availableConsents
    );

    if (validationErrors.length) {
      setFieldErrors(validationErrors);
      const [firstValidationError] = validationErrors;
      dispatch({
        type: DISPLAY_ERROR,
        payload: {
          message: `${firstValidationError.comment}`,
        },
      });
      return;
    }

    onNext();
  };

  const handleOnPrevious = () => {
    onPrevious();
  };

  useEffect(() => {
    if (!shouldCheckMemberValues || !currentPersonId || !choosenSeasonId) {
      return;
    }

    const { promise, cancel } = poonaService.members(currentPersonId);
    promise
      .then((response) => {
        if (!response || !response.length) {
          return;
        }
        const [member] = response;
        const {
          isImageRightInstanceExternal,
          isImageRightInstanceInternal,
        } = member;

        const newAcceptationData = {
          ...(defaultData as any)[KEYS.ACCEPTATION],
          [EXTERNAL_CONSENT_ID]: isImageRightInstanceExternal === "1",
          [INTERNAL_CONSENT_ID]: isImageRightInstanceInternal === "1",
        };

        handleOnChange(KEYS.ACCEPTATION, newAcceptationData);
      })
      .catch(() => {
        // Nothing to do. Simply not throw an unwanted exception.
      })
      .finally(() => setShouldCheckMemberValues(false));
    return () => cancel();
    // eslint-disable-next-line
  }, [currentPersonId, choosenSeasonId, defaultData]);

  return (
    <>
      {availableConsents.map((type) => (
        <Acceptation
          key={type}
          fieldErrors={fieldErrors}
          onChange={(data: any) => handleOnChange(KEYS.ACCEPTATION, data)}
          type={type}
          defaultData={defaultData.acceptation}
        />
      ))}

      {shouldDisplayRepresentativeBlock && (
        <Attestation
          defaultData={{}}
          fieldErrors={fieldErrors}
          onChange={(data: any) => handleOnChange(KEYS.ATTESTATION, data)}
          mainResponsable={mainResponsableData}
          personData={personData}
        />
      )}

      <Footer onNext={handleOnNext} onPrevious={handleOnPrevious} />
    </>
  );
};

export default Step04;
