import React from "react";

import { useInstance } from "hooks";

import "./styles.scss";

type Props = {
  isRenewal: boolean;
};

const Header: React.FC<Props> = (props) => {
  const { isRenewal = false } = props;
  const title = isRenewal
    ? "Renouvellement de cotisation"
    : "Nouvelle adhésion";
  const instance = useInstance();

  return (
    <>
      <header className="licence-renewal-header" >
        <div className="club-logo-container">
          <img src="/assets/images/myffbad-logo.png" alt="logo" width="100" className="ffbad-logo" />
          <img src={instance?.logo || "/assets/images/ffbad-club-logo.jpg"} alt="logo" width="80" className="club-logo" />
        </div>
        <span className="title">{instance && instance.name}</span>
      </header>

      <div className="fullscreen-image">{title}</div>
    </>
  );
};

export default Header;
