import { MainReduxState } from "core/interfaces.config";
import { useSelector } from "react-redux";

const useDataForStep = <T>(step: number) => {
  return useSelector(
    (reducers: MainReduxState) => (reducers.data as any)[`step${step}`]
  ) as T;
};

export default useDataForStep;
