import { useSelector } from "react-redux";

import { MainReduxState } from "core/interfaces.config";
import { Consent } from "services/poona/poona.interfaces";
import { ConsentTypes } from "core/enums/consentTypes";

const useConsent = (consentType: ConsentTypes): Consent | undefined => {
  const allConsents = useSelector(
    (reducers: MainReduxState) => reducers.consents
  );

  return allConsents.find((consent) => consent.categoryId === consentType);
};

export default useConsent;
