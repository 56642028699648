import React, { Fragment, useEffect, useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

import { CheckFieldError } from "utils/renewal/renewal.utils";
import { randomId } from "utils/string/string.utils";
import {
  ContactsData,
  StepComponent,
} from "pages/LicenceRenewal/renewal.interfaces";
import { Card, Button, Loading } from "components";
import ContactForm from "../ContactForm";
import { useMainState } from "hooks";
import { KeyCodes } from "core/enums/keycodes";
import PoonaService from "services/poona/poona.service";
import { ContactType } from "services/poona/poona.interfaces";

import "./index.scss";

type Props = StepComponent & {
  defaultData?: ContactsData[];
  fieldErrors: CheckFieldError[][];
};

const MAIN_RESPONSABLE_INDEX = 0;
const MAX_RESPONSABLES_COUNT = 5;

const Contacts: React.FC<Props> = (props) => {
  const {
    onChange,
    defaultData: formData = [],
    fieldErrors = [],
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [types, setTypes] = useState<ContactType[]>([]);
  const service = useRef(() => new PoonaService());
  const [mainResponsable = {}, ...otherResponsables] = formData;
  const { currentPersonId } = useMainState();

  const handleOnChange = (index: number, key: string, value: any) => {
    const newFormData: any = [...formData];

    if (!newFormData[index]) {
      newFormData[index] = {};
    }

    if (!newFormData[index][key]) {
      newFormData[index][key] = {};
    }

    newFormData[index][key] = value;
    onChange(newFormData);
  };

  const handleOnAddNewResponsable = () => {
    const newFormData: any = [...formData];
    const newIndex = otherResponsables.length + 1;
    newFormData[newIndex] = {
      localUniqueKey: randomId(),
      firstName: "",
      lastName: "",
      type: "",
      email: "",
      phone: "",
    };

    onChange(newFormData);
  };

  const handleRemoveResponsable = (index: number) => (): void => {
    onChange(formData.filter((_, i) => i !== index));
  };

  useEffect(() => {
    setIsLoading(true);

    const { promise, cancel } = service.current().getContactTypes();

    promise
      .then((types) => {
        setTypes(types.items);
      })
      .finally(() => {
        if (!currentPersonId) {
          setIsLoading(false);
          return;
        }

        const { promise: promiseContact } = service
          .current()
          .getContacts(currentPersonId as string);

        promiseContact
          .then((contacts) => {
            const newFormData = [...formData];

            contacts.items.forEach(({ contact, ...rest }, index) => {
              newFormData[index] = {
                firstName: contact.firstname,
                lastName: contact.lastname,
                email: contact.email,
                phone: contact.phone,
                isAccompanying: rest.isAccompanying,
                isLegalGuardian: rest.isLegalGuardian,
                isEmergency: rest.isEmergency,
                contactTypeId: rest.contactTypeId,
                contactAssoId: rest.contactAssoId,
              };
            });

            onChange(newFormData);
          })
          .finally(() => {
            setIsLoading(false);
          });
        //
      });

    return () => {
      cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Card title="Qui contacter ?">
      <h3 className="no-margin">Contact principal</h3>

      {/* Main Contact > */}
      <ContactForm
        fieldErrors={fieldErrors[MAIN_RESPONSABLE_INDEX]}
        formData={mainResponsable}
        onChange={handleOnChange}
        index={MAIN_RESPONSABLE_INDEX}
        contactTypes={types}
      />
      {/* < Main Contact */}

      <>
        {otherResponsables.length !== 0 && (
          <>
            <hr />
            <h3>Contact(s) secondaire(s)</h3>
          </>
        )}

        {/* Others Contacts > */}
        {otherResponsables.map((responsable, index) => (
          <Fragment key={responsable.localUniqueKey}>
            <ContactForm
              fieldErrors={fieldErrors[index + 1]}
              formData={responsable}
              onChange={handleOnChange}
              index={index + 1}
              contactTypes={types}
            />

            <Button
              className="delete-responsable-button"
              onClick={handleRemoveResponsable(index)}
            >
              Supprimer le contact
            </Button>

            <hr />
          </Fragment>
        ))}
        {/* < Others responsables */}

        {otherResponsables.length < MAX_RESPONSABLES_COUNT - 1 && (
          <div className="add-responsable-container">
            <span
              role="button"
              tabIndex={0}
              title="Ajouter un contact secondaire"
              onClick={handleOnAddNewResponsable}
              onKeyDown={(e) => {
                if (
                  e.keyCode === KeyCodes.ENTER ||
                  e.keyCode === KeyCodes.SPACE
                ) {
                  e.preventDefault();
                  handleOnAddNewResponsable();
                }
              }}
            >
              <FontAwesomeIcon icon={faPlusCircle as any} />
              Ajouter un contact secondaire
            </span>
          </div>
        )}
      </>

    </Card>
  );
};

export default Contacts;
