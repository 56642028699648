import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useRef,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";

import PoonaService from "services/poona/poona.service";
import {
  QSSportData,
  StepComponent,
  QUESTION_STATES,
} from "pages/LicenceRenewal/renewal.interfaces";
import { CheckFieldError } from "utils/renewal/renewal.utils";
import { questionDataHasOneOfState } from "utils/renewal/step03.utils";
import { scrollToTop } from "utils/dom/dom.utils";
import { Card } from "components";
import { Checkbox } from "components/form";

import "./index.scss";
import { useDispatch } from "react-redux";
import { DISPLAY_ERROR } from "core/redux/reducer/main.reducer";

type Props = StepComponent & {
  defaultData?: QSSportData;
  fieldErrors: CheckFieldError[];
  isMinor: boolean;
  birthDate: string;
};

const KEY = "qssport";

const buildDefaultAnswers = (categories: any[]) => {
  const questionsAsArrayOfArray: any = categories.map((p) => p.questions);
  const questionsAsFlatArray = [].concat.apply([], questionsAsArrayOfArray);

  return questionsAsFlatArray.map((p: any) => ({
    key: p.healthFormId,
    value: QUESTION_STATES.UNDEFINED,
  }));
};

const extractQuestionValue = (
  formData: QSSportData,
  key: string
): QUESTION_STATES => {
  if (!formData || !formData.questionData) {
    return QUESTION_STATES.UNDEFINED;
  }

  const item = formData.questionData.find((anItem) => anItem.key === key);
  const output = item ? item.value : QUESTION_STATES.UNDEFINED;
  return output;
};

const QSSport: React.FC<Props> = (props) => {
  const service = useRef(new PoonaService());
  const dispatch = useDispatch();
  const { onChange, defaultData, fieldErrors = [], isMinor, birthDate } = props;
  const [formData, setFormData] = useState<QSSportData>({});
  const [error, setError] = useState<CheckFieldError>();
  const [questionCategories, setQuestionCategories] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const certificateIsRequired = questionDataHasOneOfState(defaultData, [
    QUESTION_STATES.YES,
  ]);

  useEffect(() => {
    const { promise, cancel } = service.current.qsSportQuestions(birthDate);

    promise
      .then((categories) => {
        setQuestionCategories(categories);
        setIsLoading(false);
      })
      .catch(() => {
        dispatch({
          type: DISPLAY_ERROR,
          payload: {
            message: "Impossible de récupérer le questionnaire de santé",
          },
        });
      });
    return () => {
      cancel();
    };
  }, [birthDate, dispatch]);

  useEffect(() => {
    if (!defaultData || isLoading) {
      return;
    }

    const newData = { ...defaultData };
    if (!newData.questionData || !newData.questionData.length) {
      newData.questionData = buildDefaultAnswers(questionCategories);
    }

    setFormData(newData);
  }, [isLoading, defaultData, questionCategories]);

  useEffect(() => {
    const anError = fieldErrors.find((err) => err.key === KEY);
    if (!anError) {
      return;
    }

    setError(anError);
    scrollToTop(KEY);
  }, [fieldErrors]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOnChange = (key: string, state: QUESTION_STATES) => {
    const newFormData: QSSportData = { ...formData };
    if (!newFormData.questionData) {
      newFormData.questionData = buildDefaultAnswers(questionCategories);
    }

    let item = newFormData.questionData.find((p) => p.key === key);
    if (!item) {
      item = { key, value: state };
      newFormData.questionData.push(item);
    }

    item.value = state;
    onChange(newFormData);
  };

  const handleOnCheckAll = useCallback(() => {
    questionCategories.map((qc) =>
      qc.questions.map((q: any) =>
        handleOnChange(q.healthFormId, QUESTION_STATES.NO)
      )
    );
  }, [questionCategories, handleOnChange]);

  if (isLoading) {
    return null;
  }

  return (
    <Card
      id={KEY}
      title="Questionnaire de santé QS-Sport"
      subtitle="Il est obligatoire pour toute demande de licence fédérale."
    >
      {error && <p className="text-error">{error.comment}</p>}

      <div className="check-all" onClick={handleOnCheckAll}>
        <FontAwesomeIcon icon={faLightbulb as any} />
        &nbsp; Cliquez pour tout cocher à "non"
      </div>

      <table className="qssport-table">
        <thead>
          <tr>
            <th>
              <h4>Repondez aux questions suivantes par OUI ou par NON*</h4>
            </th>
            <th>OUI</th>
            <th>NON</th>
          </tr>
        </thead>
        <tbody>
          {questionCategories.map((category) => (
            <Fragment key={category.category}>
              <tr>
                <td colSpan={3}>{category.category}</td>
              </tr>

              {category.questions.map((question: any) => {
                const questionValue = extractQuestionValue(
                  formData,
                  question.healthFormId
                );
                const yesIsChecked = questionValue === QUESTION_STATES.YES;
                const noIsChecked = questionValue === QUESTION_STATES.NO;
                const questionId = `question_${question.healthFormId}`;

                return (
                  <tr key={question.healthFormId}>
                    <td id={questionId}>{question.label}</td>
                    <td className="checkbox">
                      <Checkbox
                        checked={yesIsChecked}
                        onChange={(checked) =>
                          handleOnChange(
                            question.healthFormId,
                            checked
                              ? QUESTION_STATES.YES
                              : QUESTION_STATES.UNDEFINED
                          )
                        }
                        ariaLabelledby={questionId}
                      />
                    </td>
                    <td className="checkbox">
                      <Checkbox
                        checked={noIsChecked}
                        onChange={(checked) =>
                          handleOnChange(
                            question.healthFormId,
                            checked
                              ? QUESTION_STATES.NO
                              : QUESTION_STATES.UNDEFINED
                          )
                        }
                        ariaLabelledby={questionId}
                      />
                    </td>
                  </tr>
                );
              })}
            </Fragment>
          ))}

          <tr>
            <td className="background-transparent" colSpan={3}>
              <b>*NB</b> : Les réponses formulées relèvent de la seule
              responsabilité du licencié
            </td>
          </tr>
        </tbody>
      </table>

      {certificateIsRequired && (
        <p className="text-error">
          Vous avez répondu &laquo;&nbsp;<b>oui</b>&nbsp;&raquo; à une ou plusieurs questions, un certificat médical est à fournir.<br />
          Consultez un médecin et présentez-lui&nbsp;
          <a
            href={
              isMinor
                ? process.env.REACT_APP_QSSPORT_URL_MINOR
                : process.env.REACT_APP_QSSPORT_URL_ADULT
            }
            target="_blank"
            rel="noopener noreferrer"
            title="Questionnaire QS-Sport"
          >
            ce questionnaire renseigné
          </a>
          .
          <br />
          <br />

        </p>
      )}

      <div className="check-all" onClick={handleOnCheckAll}>
        <FontAwesomeIcon icon={faLightbulb as any} />
        &nbsp; Cliquez pour tout cocher à "non"
      </div>
    </Card>
  );
};

export default QSSport;
