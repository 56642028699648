import {
  Pricing,
  PricingDiscount,
  PricingOption,
} from "services/poona/poona.interfaces";

export const calculateTotalPrice = (
  selectedPrice?: Pricing,
  discountIds: string[] = [],
  optionIds: string[] = []
): number => {
  if (!selectedPrice) {
    return 0;
  }

  let total = 0;

  total += +selectedPrice.price;

  const { discounts = [], options = [] } = selectedPrice;
  total = calculateOptions(total, options, optionIds);
  total = calculateDiscounts(total, discounts, discountIds);

  return total;
};

const calculateDiscounts = (
  total: number,
  discounts: PricingDiscount[],
  discountIds: string[]
): number => {
  const wantedDiscounts = discountIds
    .map((id) => discounts.find((d) => d.discountId === id))
    .filter((p) => p) as PricingDiscount[];

  total = wantedDiscounts
    .filter((discount) => discount.discountAmount !== null)
    .map((discount) => Number(discount.discountAmount))
    .reduce((prev, curr) => prev - curr, total);

  total *=
    (100 -
      wantedDiscounts
        .filter((discount) => discount.discountPercent !== null)
        .map((discount) => Number(discount.discountPercent))
        .reduce((prev, curr) => prev + curr, 0)) /
    100;

  if (total < 0) return 0;

  return total;
};

const calculateOptions = (
  total: number,
  options: PricingOption[],
  optionIds: string[]
): number => {
  const wantedOptions = optionIds
    .map((id) => options.find((d) => d.optionId === id))
    .filter((p) => p) as PricingOption[];

  return wantedOptions.reduce((prev, curr) => prev + +curr.price, total);
};
