import React, { Fragment } from "react";

import { CustomFieldItem } from "pages/LicenceRenewal/renewal.interfaces";
import { Input, Select, InputRadioGroup, MultiSelect } from "components/form";

const generateComponentByType = (
  field: CustomFieldItem,
  callback: (key: string, value: any) => void,
  handleOnUploadFile: (key: string, value: FileList) => void,
  defaultValue?: any
) => {
  const definitions: any = {
    date: {
      component: Input,
      props: {
        label: field.label,
        subLabel: field.description,
        type: "date",
        hasHtml: true,
        onChange: (value: string) => callback(field.key, value),
        required: field.required,
        defaultValue,
      },
    },
    select: {
      component: Select,
      props: {
        label: field.label,
        subLabel: field.description,
        items: field.selectItems,
        onChange: (value: string) => callback(field.key, value),
        required: field.required,
        defaultValue,
        firstIsBlank: true,
      },
    },
    number: {
      component: Input,
      props: {
        label: field.label,
        subLabel: field.description,
        type: "number",
        onChange: (value: string) => callback(field.key, value),
        required: field.required,
        defaultValue,
      },
    },
    text: {
      component: Input,
      props: {
        label: field.label,
        subLabel: field.description,
        onChange: (value: string) => callback(field.key, value),
        required: field.required,
        defaultValue,
      },
    },
    radio: {
      component: InputRadioGroup,
      props: {
        label: field.label,
        subLabel: field.description,
        items: field.selectItems,
        onChange: (values: string[] = []) => callback(field.key, values[0]),
        required: field.required,
        keyOfCheckedItem: defaultValue,
      },
    },
    boolean: {
      component: InputRadioGroup,
      props: {
        label: field.label,
        subLabel: field.description,
        items: [
          { key: "true", value: "Oui" },
          { key: "false", value: "Non" },
        ],
        onChange: (values: string[]) => {
          callback(field.key, values.length && values[0] === "true");
        },
        required: field.required,
        keyOfCheckedItem: defaultValue,
      },
    },
    checkbox: {
      component: InputRadioGroup,
      props: {
        label: field.label,
        subLabel: field.description,
        items: field.selectItems,
        isMulti: true,
        onChange: (value: string) => callback(field.key, value),
        required: field.required,
        keyOfCheckedItem: defaultValue,
      },
    },
    multiSelect: {
      component: MultiSelect,
      props: {
        label: field.label,
        subLabel: field.description,
        items: field.selectItems,
        required: field.required,
        onChange: (values: string[]) => callback(field.key, values),
        keyOfCheckedItem: defaultValue,
      },
    },
    file: {
      component: Input,
      props: {
        label: field.label,
        subLabel: field.description,
        type: "file",
        required: field.required,
        fileMimeTypeAllowed: field.fileMimeTypeAllowed || [],
        fileSizeMaxAllowed: field.fileSizeMaxAllowed,
        onChange: (value: FileList) => handleOnUploadFile(field.key, value),
        defaultValue,
      },
    },
  };

  const aDefinition = definitions[field.type];
  if (!aDefinition) {
    console.log("Missing custom field type:", field.type);
    return <Fragment key={field.key} />;
  }

  const Component = aDefinition.component;
  return <Component key={field.key} {...aDefinition.props} />;
};

export default generateComponentByType;
