export const ROUTE_KEYS = {
  HOME: "home",
  LICENCE_ENDSCREEN: "licence_end",
  CONFIG: "config",
  ERROR: "error",
};

export const ROUTE_PATHS = {
  [ROUTE_KEYS.HOME]: "/:sigle?/:key?",
  [ROUTE_KEYS.LICENCE_ENDSCREEN]: "/:sigle/fin-formulaire/:completedState",
  [ROUTE_KEYS.CONFIG]: "/private-config-checker",
  [ROUTE_KEYS.ERROR]: "/erreur/:type?",
};

/**
 * Build a complete route with argument(s).
 * @param routeKey Unique key to identify the route (from ROUTE_KEYS constant).
 * @param args All arguments that'll replace arguments from route path.
 * @returns A fully fonctionnal route path.
 */
export const buildRoute = (routeKey: string, args: any = {}): string => {
  let routePath = ROUTE_PATHS[routeKey] || "";

  const keys = Object.keys(args);
  for (const key of keys) {
    const value: string = args[key];
    routePath = routePath.replace(`:${key}?`, value).replace(`:${key}`, value);
  }

  return routePath;
};

export const flattenMenuItems = (items: any[]) => {
  return items.reduce((prev: any[], curr: any) => {
    const temp: any[] = [...prev, curr];
    if (curr.menu && curr.menu.length) {
      curr.menu.forEach((item: any) => temp.push(item));
    }
    return temp;
  }, []);
};
